// src/components/predictedCaVsSales/PredictedCaVsSalesChart.tsx

import { useMemo, useState } from "react"
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts"
import { ChartConfig, ChartContainer } from "@/components/ui/chart"
import ChartTooltipContent from "./ChartTooltipContent"
import CustomLegend from "./CustomLegend"

const chartConfig = {
  predicted_ca: {
    label: "Prédictions VS Ventes",
    color: "var(--color-predicted_ca)",
  },
  sales_actual: {
    label: "Ventes Réelles",
    color: "var(--color-sales_actual)",
  },
} satisfies ChartConfig

interface PredictedCaVsSalesChartProps {
  data: {
    date: string
    predicted_ca: number
    sales_actual?: number | null
  }[]
}

export function PredictedCaVsSalesChart({
  data,
}: PredictedCaVsSalesChartProps) {
  const [activeKeys] = useState<string[]>(["predicted_ca", "sales_actual"])

  const processedData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      sales_actual: item.sales_actual === 0 ? undefined : item.sales_actual,
    }))
  }, [data])

  const filteredtotalData = useMemo(() => {
    return data.filter(
      (item) =>
        typeof item.sales_actual === "number" &&
        item.sales_actual > 0 &&
        typeof item.predicted_ca === "number",
    )
  }, [data])

  const totalPredicted = useMemo(() => {
    return filteredtotalData.reduce(
      (acc, item) => acc + (item.predicted_ca ?? 0),
      0,
    )
  }, [filteredtotalData])

  const totalActual = useMemo(() => {
    return filteredtotalData.reduce(
      (acc, item) => acc + (item.sales_actual ?? 0),
      0,
    )
  }, [filteredtotalData])

  const totalAbsoluteError = useMemo(() => {
    return filteredtotalData.reduce(
      (acc, item) =>
        acc + (Math.abs(item.predicted_ca - (item.sales_actual ?? 0)) ?? 0),
      0,
    )
  }, [filteredtotalData])

  const accuracyPercentage = useMemo(() => {
    if (totalActual === 0) return null
    let accuracy = 100 - (totalAbsoluteError / totalActual) * 100
    accuracy = Math.max(0, Math.min(accuracy, 100))
    return accuracy.toFixed(2)
  }, [totalAbsoluteError, totalActual])

  const getAccuracyColor = () => {
    if (accuracyPercentage === null) return "text-gray-500"
    const accuracy = Number(accuracyPercentage)
    if (accuracy >= 85) return "text-green-600"
    if (accuracy >= 60) return "text-amber-600"
    return "text-red-600"
  }

  return (
    <ChartContainer config={chartConfig}>
      <div className="shadow-lg rounded-lg bg-white p-6 overflow-visible">
        {accuracyPercentage !== null ? (
          <div className="mb-4 text-center">
            {Number(accuracyPercentage) > 90 && (
              <>
                <span
                  className={`text-lg sm:text-xl font-semibold ${getAccuracyColor()}`}
                >
                  Précision sur la période : {accuracyPercentage}%
                </span>
                <br />
                <span className="text-sm text-gray-600">
                  Prédit :{" "}
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(totalPredicted)}{" "}
                  vs Ventes Réelles :{" "}
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(totalActual)}
                </span>
              </>
            )}
          </div>
        ) : (
          <div className="mb-4 text-center">
            <span className="text-lg font-semibold text-gray-500">
              Précision non disponible
            </span>
          </div>
        )}
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={processedData}
            margin={{
              top: 60,
              right: 20,
              bottom: 20,
              left: 80,
            }}
          >
            <defs>
              <linearGradient id="colorPredictedCa" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1ABC9C" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#1ABC9C" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorSalesActual" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="#3498DB" // Tailwind's sales_actual
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="#3498DB" // Tailwind's sales_actual
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>

            <CartesianGrid
              vertical={true}
              stroke="#ECF0F1"
              strokeDasharray="3 3"
            />

            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={16}
              tickFormatter={(value) =>
                new Intl.DateTimeFormat("fr-FR", {
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(value))
              }
              className="text-sm font-medium text-gray-700"
            />

            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={16}
              tickCount={5}
              tickFormatter={(value) =>
                new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                  notation: "compact",
                  compactDisplay: "short",
                }).format(value)
              }
              className="text-sm font-medium text-gray-700"
              width={80}
              tick={{
                textAnchor: "end",
              }}
            />

            <Tooltip
              content={<ChartTooltipContent />}
              cursor={{ stroke: "#1ABC9C", strokeWidth: 2 }}
            />

            <Legend content={<CustomLegend activeKeys={activeKeys} />} />

            {activeKeys.includes("predicted_ca") && (
              <Area
                type="monotone"
                dataKey="predicted_ca"
                name="Prédictions"
                stroke="#1ABC9C"
                fill="url(#colorPredictedCa)"
                strokeWidth={2}
                activeDot={{ r: 6, stroke: "#fff", strokeWidth: 2 }}
                animationDuration={1500}
                animationEasing="ease-in-out"
              />
            )}

            {activeKeys.includes("sales_actual") && (
              <Area
                type="monotone"
                dataKey="sales_actual"
                name="Ventes Réelles"
                stroke="#3498DB"
                fill="url(#colorSalesActual)"
                strokeWidth={2}
                activeDot={{ r: 6, stroke: "#fff", strokeWidth: 2 }}
                animationDuration={1500}
                animationEasing="ease-in-out"
                connectNulls={false}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </ChartContainer>
  )
}
