"use client"

import { useOutletContext, Outlet } from "react-router-dom"
import { PerformancesPageContext } from "../PerformancesPage"

export function RuptureGlobalPerformancesPage() {
  // If you need the context for display or passing props down, you can still grab it
  const parentContext = useOutletContext<PerformancesPageContext>()

  return <Outlet context={parentContext} />
}
