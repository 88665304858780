// CheckinPage.tsx

import { DispatchActionType, StateType } from "@/types"
import { useDispatch, useSelector } from "react-redux"
import { Stepper } from "@/components/stepper"
import { useCallback, useEffect, useMemo, useState } from "react"
import { filterSteps } from "@/pages/checkinPage/stepperData"
import PotentiallyRuptures from "@/pages/checkinPage/components/potentiallyRuptures"

import {
  filteredMercurialeReducerSelector,
  selectedMercurialeInfosSelector,
  // We also import potentialRupturesSelector, though we might just do it inside PotentiallyRuptures
  potentialRupturesSelector,
} from "@/selectors/mercurialeSelectors"

import {
  useBatchUpdateMutation,
  useGetLastWeeksPerformancesQuery,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { handleKeyboard } from "@/utils/handleKeyboard"

import { ValidationFooter } from "@/pages/checkinPage/components/validationFooter"
import { PriceChanges } from "@/pages/checkinPage/components/priceChanges"
import { hasFeatureFlag } from "@/components/inventory/modals/CheckInModal/checkInFeaturesFlags"
import { getVariation } from "@/utils/getVariation"
import { removeDuplicatesValues } from "@/utils/removeDuplicates"
import { LastPerformances } from "@/pages/checkinPage/lastPerformances"
import MinimalLogo from "@/assets/MinimalLogo"
import { useWindowSize } from "@/hooks/useWindowSize"
import { useInventoryContext } from "@/contexts/InventoryContext"
import { HandleLinear } from "@/pages/checkinPage/components/handleLinear"

export const CheckinPage = ({
  setIsCheckinPage,
}: {
  setIsCheckinPage?: (value: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const dispatch = useDispatch<DispatchActionType>()

  const selectedDimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const { companyName, storeFranchise, storeId, storeSettings, companyId } =
    useSelector((state: StateType) => state.storeReducer)

  const [batchUpdateMutation] = useBatchUpdateMutation()

  const mercurialAndStoreInventories = useSelector(
    (state: StateType) => state.mercurialReducer.mercurialAndStoreInventories,
  )

  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )

  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const { updatedReferences } = useSelector(filteredMercurialeReducerSelector)
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  // For certain handleKeyboard calls
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  // All references (with updated fields merged in)
  const mercurialeInfos = useSelector(selectedMercurialeInfosSelector)

  // Potential ruptures from the selector
  const potentialRuptures = useSelector(potentialRupturesSelector)
  // Deduplicate them
  const deduplicatedPotentialRuptures = useMemo(() => {
    return removeDuplicatesValues(potentialRuptures, "sale_id")
  }, [potentialRuptures])

  /**
   * Update inventory callback that calls handleKeyboard
   */
  const updateInventory = useCallback(
    async (
      value: string,
      _selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
    ): Promise<void> => {
      await handleKeyboard({
        value,
        selectedInventory: _selectedInventory ?? selectedInventory,
        mercurialeInfos: mercurialAndStoreInventories,
        updatedReferences,
        storeSettings,
        dispatch,
        batchUpdateMutation,
        online,
        isTestMode,
        storeId,
        dimOrderRequestId,
        logsModificationUpdate,
      })
    },
    [
      batchUpdateMutation,
      dimOrderRequestId,
      dispatch,
      isTestMode,
      mercurialAndStoreInventories,
      online,
      selectedInventory,
      storeId,
      storeSettings,
      updatedReferences,
      logsModificationUpdate,
    ],
  )

  /**
   * Example: bestSellers
   */
  const bestSellers = useMemo(() => {
    return [...mercurialAndStoreInventories]
      .filter((item) => item.last_weeks_sales)
      .sort((a, b) => {
        if (a.last_weeks_sales === b.last_weeks_sales) return 0
        return (a.last_weeks_sales ?? 0) > (b.last_weeks_sales ?? 0) ? -1 : 1
      })
      .slice(0, 19)
  }, [mercurialAndStoreInventories])

  /**
   * Shelf floor size over/under exposure
   */
  const shelfFloorSizeOverexposure = useMemo(() => {
    if (
      !hasFeatureFlag(companyName, storeFranchise, "shelfFloorSizeOverexposure")
    ) {
      return []
    }
    return mercurialeInfos
      .filter(
        (mercurialeInfo) =>
          mercurialeInfo.shelf_floor_size_flag === "overexposure",
      )
      .slice(0, 3)
  }, [companyName, mercurialeInfos, storeFranchise])

  const shelfFloorSizeUnderExposure = useMemo(() => {
    if (
      !hasFeatureFlag(
        companyName,
        storeFranchise,
        "shelfFloorSizeUnderExposure",
      )
    ) {
      return []
    }
    return mercurialeInfos
      .filter(
        (mercurialeInfo) =>
          mercurialeInfo.shelf_floor_size_flag === "underexposure",
      )
      .slice(0, 3)
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedShelfFloorSizeOverexposure = useMemo(() => {
    return removeDuplicatesValues(shelfFloorSizeOverexposure, "sale_id")
  }, [shelfFloorSizeOverexposure])

  const deduplicatedShelfFloorSizeUnderExposure = useMemo(() => {
    return removeDuplicatesValues(shelfFloorSizeUnderExposure, "sale_id")
  }, [shelfFloorSizeUnderExposure])

  /**
   * usedToBePromoReferences
   */
  const usedToBePromoReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "usedToBePromo")) {
      return []
    }
    return mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.used_to_be_promo === true,
    )
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedUsedToBePromoReferences = useMemo(() => {
    return removeDuplicatesValues(usedToBePromoReferences, "sale_id")
  }, [usedToBePromoReferences])

  /**
   * top5HighestBreakage
   */
  const top5HighestBreakage = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "top5HighestBreakage")) {
      return []
    }
    return mercurialeInfos
      .filter((mercurialeInfo) => {
        const breakage = mercurialeInfo.breakage_percentage
        const breakageAmount = Math.round(mercurialeInfo.breakage_amount ?? 0)
        return (
          typeof breakage === "number" &&
          breakage > 10 &&
          breakage < 100 &&
          breakageAmount > 0
        )
      })
      .sort(
        (a, b) => (b.breakage_percentage ?? 0) - (a.breakage_percentage ?? 0),
      )
      .slice(0, 5)
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedTop5HighestBreakage = useMemo(() => {
    return removeDuplicatesValues(top5HighestBreakage, "sale_id")
  }, [top5HighestBreakage])

  /**
   * Price changes (paChangedReferences)
   */
  const paChangedReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "paChanged")) {
      return []
    }
    const filteredMercurialeInfos = mercurialeInfos.filter((mercurialeInfo) => {
      const pa = mercurialeInfo.pa ?? 0
      const lastPa = mercurialeInfo.last_mercu_pa ?? 0
      if (pa === 0 || lastPa < 0.5) return false

      const variation = getVariation(pa, lastPa)
      const isPaDecreased = variation < 0
      const isEngagementPromo = mercurialeInfo.engagement_promo_flag ?? false

      return isEngagementPromo === false && isPaDecreased
    })

    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
      .sort((a, b) => {
        const aPa = a.pa ?? 0
        const aLastPa = a.last_mercu_pa ?? 0
        const bPa = b.pa ?? 0
        const bLastPa = b.last_mercu_pa ?? 0

        const aVariation = getVariation(aPa, aLastPa)
        const bVariation = getVariation(bPa, bLastPa)

        // smaller variation => bigger discount => put it first
        return aVariation - bVariation
      })
      .splice(0, 4)
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedPaChangedReferences = useMemo(() => {
    return removeDuplicatesValues(paChangedReferences, "sale_id")
  }, [paChangedReferences])

  const isRuptures = deduplicatedPotentialRuptures.length > 0

  const isUnderOverexposure =
    deduplicatedShelfFloorSizeOverexposure.length > 0 ||
    deduplicatedShelfFloorSizeUnderExposure.length > 0 ||
    deduplicatedUsedToBePromoReferences.length > 0

  const stepsToDisplay = filterSteps(storeSettings, {
    isBreakage: deduplicatedTop5HighestBreakage.length > 0,
    isLinearCapacity: isUnderOverexposure,
    isPaChanged: deduplicatedPaChangedReferences.length > 0,
    isRuptures: isRuptures,
  })

  // Query for lastWeeksPerformances
  const {
    data: lastWeeksPerformancesData,
    loading: lastWeeksPerformancesLoading,
  } = useGetLastWeeksPerformancesQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
  })

  const closeCheckinModal = () => {
    setIsCheckinPage?.(false)
    dispatch({
      type: "closeCheckInModal",
      payload: {
        dimMercurialeId: selectedDimMercurialeId!,
        date: new Date(),
      },
    })
  }

  const gotToNextStep = () => {
    if (currentStep < stepsToDisplay.length) {
      setCurrentStep(currentStep + 1)
    } else {
      closeCheckinModal()
    }
  }

  const isMobile = useWindowSize()

  useEffect(() => {
    // Only mark as loaded if we have data
    setIsLoading(mercurialeInfos.length === 0)
  }, [mercurialeInfos])

  const isLastPerformanceLoading = lastWeeksPerformancesLoading || isLoading

  const { lastInputSelected } = useInventoryContext()
  useEffect(() => {
    if (lastInputSelected.mercurialeId) {
      const element = document.getElementById(lastInputSelected.mercurialeId)
      if (element) {
        const elementTop = element.getBoundingClientRect().top
        const offsetPosition = window.scrollY + elementTop - 100
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
  }, [lastInputSelected])

  return (
    <div className="flex flex-col">
      <div className="px-2 py-4 md:px-4 flex items-center justify-between w-full ">
        <MinimalLogo
          companyId={companyId}
          testMode={isTestMode}
          franchise={storeFranchise}
          className="w-6 lg:w-8"
        />
      </div>

      <div className="flex flex-col gap-4 h-full">
        <div className="w-full px-4 z-10">
          <Stepper
            title="Vos vérifications quotidiennes"
            steps={stepsToDisplay}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            direction="row"
            loading={isLoading}
          />
        </div>
        <div className="w-full md:px-4 h-full mb-[250px] md:mb-10">
          {/* Step 1: Last performances */}
          {stepsToDisplay[currentStep - 1]?.key === "last_performances" && (
            <LastPerformances
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
              lastWeeksPerformancesData={lastWeeksPerformancesData}
              lastWeeksPerformancesLoading={isLastPerformanceLoading}
              top5HighestBreakage={deduplicatedTop5HighestBreakage}
              updateInventory={updateInventory}
            />
          )}

          {/* Step 2: Potentially Ruptures */}
          {stepsToDisplay[currentStep - 1]?.key === "potentially_ruptures" && (
            <PotentiallyRuptures
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
              updateInventory={updateInventory}
              potentialRupturesData={deduplicatedPotentialRuptures}
              allReferences={mercurialeInfos}
            />
          )}

          {stepsToDisplay[currentStep - 1]?.key === "linear_capacity" && (
            <HandleLinear
              usedToBePromoReferences={deduplicatedUsedToBePromoReferences}
              shelfFloorSizeOverexposure={
                deduplicatedShelfFloorSizeOverexposure
              }
              shelfFloorSizeUnderExposure={
                deduplicatedShelfFloorSizeUnderExposure
              }
              updateInventory={updateInventory}
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
            />
          )}

          {stepsToDisplay[currentStep - 1]?.key === "price_modifications" && (
            <PriceChanges
              paChangedReferences={deduplicatedPaChangedReferences}
              bestSellers={bestSellers}
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
              updateInventory={updateInventory}
            />
          )}
          {isMobile && (
            <div className="md:hidden">
              <ValidationFooter
                onClick={gotToNextStep}
                currentStep={currentStep}
                totalSteps={stepsToDisplay.length}
                closeCheckinModal={closeCheckinModal}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckinPage
