import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Switch } from "@/components/ui/switch"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { CalendarDays, ChevronLeft, ChevronRight } from "lucide-react"
import { useMemo, useState } from "react"
import { DateRange, WeekNumberProps } from "react-day-picker"

type SelectorState =
  | {
      mode: "single"
      selected: Date | undefined
    }
  | {
      mode: "range"
      selected: DateRange | undefined
    }

interface DateSelectorProps {
  fromDate: Date
  toDate: Date
  dateRange: DateRange | undefined
  setDateRange: (range: DateRange | undefined) => void
  franchiseParam: string | undefined
  setSearchParams: (params: Record<string, string>) => void
  disabled?: boolean
}

export function DateSelector({
  fromDate,
  toDate,
  dateRange,
  setDateRange,
  franchiseParam,
  setSearchParams,
  disabled = false,
}: DateSelectorProps) {
  const [isRangeSelection, setIsRangeSelection] = useState(true)
  const todayDate = new Date()

  function handleSelect(dateOrRange: Date | DateRange | undefined) {
    const newRange: Exclude<typeof dateOrRange, Date> = Object.assign(
      {},
      dateOrRange !== undefined && "from" in dateOrRange
        ? dateOrRange
        : { from: dateOrRange },
    )

    if (newRange?.from !== undefined && newRange?.from > todayDate) return
    if (newRange?.to !== undefined && newRange.to > todayDate) {
      newRange.to = todayDate
    }

    setDateRange(newRange)

    const previousParams: Record<string, string> =
      typeof franchiseParam === "string" ? { franchise: franchiseParam } : {}

    if (newRange?.from === undefined) {
      setSearchParams({
        ...previousParams,
      })
      return
    }

    const toParams: Record<string, string> =
      newRange.to !== undefined ? { to: formatDateToYYYYMMDD(newRange.to) } : {}

    setSearchParams({
      ...previousParams,
      from: formatDateToYYYYMMDD(newRange.from),
      ...toParams,
    })
  }

  function WeekNumber({ number, dates }: WeekNumberProps) {
    const dateRange: DateRange = { from: dates[0], to: dates[dates.length - 1] }
    const isDisabled =
      dateRange.from === undefined || dateRange.from > todayDate

    return (
      <button
        onClick={() => {
          setIsRangeSelection(true)
          handleSelect(dateRange)
        }}
        className="h-full w-full flex justify-center items-center rounded hover:bg-accent disabled:opacity-50 disabled:cursor-default disabled:hover:bg-inherit"
        disabled={isDisabled}
      >
        {number}
      </button>
    )
  }

  const calendarState = useMemo<SelectorState>(() => {
    if (isRangeSelection) {
      return {
        mode: "range",
        selected: dateRange,
      }
    }

    return {
      mode: "single",
      selected: dateRange?.from,
    }
  }, [dateRange, isRangeSelection])

  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <Button variant="outline">
          Du{" "}
          {Intl.DateTimeFormat("fr-FR", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }).format(fromDate)}{" "}
          au{" "}
          {Intl.DateTimeFormat("fr-FR", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }).format(toDate)}
          <CalendarDays />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-fit">
        <div className="flex justify-between items-center p-4">
          <p>Sélection multiple</p>
          <Switch
            checked={isRangeSelection}
            onCheckedChange={setIsRangeSelection}
          />
        </div>
        <Calendar
          {...calendarState}
          showWeekNumber
          components={{
            WeekNumber,
            IconLeft: () => <ChevronLeft />,
            IconRight: () => <ChevronRight />,
          }}
          onSelect={handleSelect}
          toDate={todayDate}
        />
      </PopoverContent>
    </Popover>
  )
}
