import { RingColor } from "@/ui/InventoryInputs/utils"
import { InventoryType } from "@/utils/__generated__/graphql"
import { BackInventoryExtraElement } from "./BackInventoryExtraElement"
import { ShelfFloorSizeExtraElement } from "./ShelfFloorSizeExtraElement"
import { OrderExtraElement } from "./OrderExtraElement"

interface ExtraElementProps {
  inventoryType: InventoryType
  value: number
  ringColor?: RingColor
  unit?: string
  floorInventoryQuantity?: number
  isToVerify?: boolean
  isTooHigh?: boolean
  isTooLow?: boolean
  isLineaireUpdatedTo0?: boolean
  stockAecouler?: boolean
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
  isPrecommande?: boolean
}

export function ExtraElement({
  inventoryType,
  value,
  ringColor,
  unit,
  floorInventoryQuantity = 0,
  isToVerify = false,
  isTooHigh = false,
  isTooLow = false,
  isLineaireUpdatedTo0 = false,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
  isPrecommande = false,
  stockAecouler = false,
}: ExtraElementProps) {
  switch (inventoryType) {
    // At bottom of back inventory input we add badges and total stock
    case InventoryType.Back: {
      return (
        <BackInventoryExtraElement
          value={value}
          stockAecouler={stockAecouler}
          floorInventoryQuantity={floorInventoryQuantity}
          isToVerify={isToVerify}
          isTooHigh={isTooHigh}
          isTooLow={isTooLow}
          unit={unit}
        />
      )
    }
    // At bottom of shelf floor size input we add badges
    case InventoryType.Shelf: {
      return <ShelfFloorSizeExtraElement value={value} ringColor={ringColor} />
    }
    // At bottom of order input we add incoming shipments quantity
    case InventoryType.Order: {
      return (
        <OrderExtraElement
          isPrecommande={isPrecommande}
          waitedQuantityOrdered={waitedQuantityOrdered}
          promoWaitedQuantityOrdered={promoWaitedQuantityOrdered}
          unit={unit}
          isLineaireUpdatedTo0={isLineaireUpdatedTo0}
        />
      )
    }
  }

  return <></>
}
