"use client"

import { useState, useEffect, useMemo } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useGetTopUnorderedReferencesQuery } from "@/utils/__generated__/graphql"

import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { add } from "date-fns"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import TopUnorderedReferencesChartCard from "./TopUnorderedReferencesChartCard"
import { PerformancesPageContext } from "../../PerformancesPage"

type ViewMode = "recurrence" | "amount" | "both"

export function TopUnorderedReferencesChart() {
  const { selectedStoreId, stores, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId

  // We'll remove separate numeric states and keep a single [start, end] range
  const [rangeValues, setRangeValues] = useState<[number, number]>([1, 10])
  const [viewMode, setViewMode] = useState<ViewMode>("both")

  // For display purposes
  const storeName = useMemo(() => {
    const store = stores?.find((store) => store.id === selectedStoreId)
    return store?.store_name ?? "Aucun magasin sélectionné"
  }, [selectedStoreId, stores])

  const defaultStartDate = useMemo(() => add(new Date(), { days: -7 }), [])
  const defaultEndDate = useMemo(() => new Date(), [])

  const totalDays = useMemo(() => {
    const start = fromDate ?? defaultStartDate
    const end = toDate ?? defaultEndDate
    return Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1
  }, [fromDate, toDate, defaultStartDate, defaultEndDate])

  // GraphQL Query
  const { data, loading, error, refetch } = useGetTopUnorderedReferencesQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise_name: franchiseParam,
        store_id: selectedStoreId!,
        start_date: formatDateToYYYYMMDD(fromDate ?? defaultStartDate),
        end_date: formatDateToYYYYMMDD(toDate ?? defaultEndDate),
        start_rank: rangeValues[0],
        end_rank: rangeValues[1],
      },
    },
    skip: !companyId || !selectedStoreId || !fromDate || !toDate,
    fetchPolicy: "cache-and-network",
  })

  // Refetch whenever range or dates change
  useEffect(() => {
    if (selectedStoreId && companyId && fromDate && toDate) {
      refetch({
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: selectedStoreId,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          start_rank: rangeValues[0],
          end_rank: rangeValues[1],
        },
      })
    }
  }, [
    selectedStoreId,
    companyId,
    franchiseParam,
    fromDate,
    toDate,
    rangeValues,
    refetch,
  ])

  // Transform fetched data for the chart
  const references = useMemo(() => {
    if (!data?.getTopUnorderedReferences.references) return []
    return data.getTopUnorderedReferences.references.map((ref) => ({
      saleName: ref.sale_name,
      TotalSales: ref.total_sales,
      OrderDays: ref.order_days,
      NotOrderedDays: totalDays - ref.order_days,
      TotalOrderedQuantity: ref.total_ordered_quantity ?? 0,
    }))
  }, [data, totalDays])

  const handleViewModeChange = (mode: string) => {
    setViewMode(mode as ViewMode)
  }

  return (
    <div className="h-full flex-1 p-1 flex flex-col overflow-y-auto">
      {selectedStoreId ? (
        <TopUnorderedReferencesChartCard
          storeName={storeName ?? "Aucun magasin sélectionné"}
          references={references}
          viewMode={viewMode}
          rangeValues={rangeValues}
          setRangeValues={setRangeValues}
          handleViewModeChange={handleViewModeChange}
          loading={loading}
          error={!!error}
        />
      ) : (
        <Card className="w-full max-w-6xl mx-auto my-8">
          <CardHeader>
            <CardTitle>{storeName}</CardTitle>
          </CardHeader>

          <CardContent>
            <div>
              Veuillez sélectionner un magasin pour afficher les références non
              commandées.
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
