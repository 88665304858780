import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { Clock, AlertCircle, CheckCircle2, Loader2 } from "lucide-react"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useMemo, useState } from "react"
import { useFetchOrderStatus } from "./useFetchOrderStatus"

interface TimeSlot {
  date: string
  available: boolean
  dimMercurialeId: string
}

interface MultipleMercurialesModalProps {
  isOpen: boolean
  onClose: () => void
  onSelect: (dimMercurialeId: string) => void
  dimMercuriales: MercurialReducerState["dimMercuriales"]
}

export function MultipleMercurialesModal({
  isOpen,
  onClose,
  onSelect,
  dimMercuriales = [],
}: MultipleMercurialesModalProps) {
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const { companyName } = useSelector((state: StateType) => state.storeReducer)
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { statuses, loading } = useFetchOrderStatus({
    isOpen,
    dimMercuriales,
    storeId: storeId!,
  })

  const timeSlots = useMemo<TimeSlot[]>(() => {
    if (!isOpen || dimMercuriales.length === 0) {
      return []
    }

    return dimMercuriales.map((dimMercuriale) => {
      const { expectedReceptionDate, orderPickupTime } =
        statuses[dimMercuriale.dimMercurialeId] || {}

      let displayLabel: string
      if (companyName?.toLowerCase() === "auchan" && expectedReceptionDate) {
        const formattedDate = Intl.DateTimeFormat("fr-FR", {
          weekday: "long",
          day: "numeric",
          month: "short",
        }).format(new Date(expectedReceptionDate))
        displayLabel = `Livraison ${formattedDate}`
      } else {
        const pickupTime = orderPickupTime ?? "N/A"
        displayLabel =
          pickupTime !== "N/A"
            ? pickupTime.slice(0, -3).replace(":", "h")
            : "N/A"
      }
      return {
        date: displayLabel,
        available: true,
        dimMercurialeId: dimMercuriale.dimMercurialeId,
      }
    })
  }, [companyName, dimMercuriales, isOpen, statuses])

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
    }
  }

  const handleSelect = (time: string) => {
    setSelectedTime(time)
    const selectedSlot = timeSlots.find((slot) => slot.date === time)
    if (selectedSlot) {
      onSelect(selectedSlot.dimMercurialeId)
    }
    handleOpenChange(false)
  }

  const translateStatus = (status: string, colis?: number) => {
    switch (status.toLowerCase()) {
      case "ongoing":
        return {
          label: "En cours",
          icon: <Clock className="h-6 w-6 text-gray-400" />,
        }
      case "completed":
        return {
          label:
            colis && colis > 0 ? (
              <span className="text-green-500">Terminé - {colis} colis</span>
            ) : (
              <span className="text-green-500">Terminé</span>
            ),
          icon: <CheckCircle2 className="h-6 w-6 text-green-500" />,
        }
      default:
        return {
          label: "En cours",
          icon: <Clock className="h-6 w-6 text-gray-400" />,
        }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <div className="flex items-center justify-center space-x-2">
            <AlertCircle className="h-6 w-6" />
            <DialogTitle className="text-xl font-semibold">
              {dimMercuriales.length} commande
              {dimMercuriales.length > 1 ? "s" : ""} à passer aujourd&apos;hui
            </DialogTitle>
          </div>
          <DialogDescription className="text-center">
            {dimMercuriales.length > 0
              ? "Sélectionnez-en une ci-dessous"
              : "Aucune commande disponible"}
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-1 gap-4 p-4">
          {timeSlots.length > 0 ? (
            timeSlots.map((slot) => {
              const { status, colis } = statuses[slot.dimMercurialeId] || {}
              const isLoading = loading || !status
              const statusInfo = status ? translateStatus(status, colis) : null

              return (
                <TooltipProvider key={slot.dimMercurialeId}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        className={cn(
                          "flex items-center justify-between w-full p-4 h-auto hover:border-primary hover:bg-primary/5",
                          selectedTime === slot.date &&
                            "border-primary bg-primary/5",
                        )}
                        onClick={() => handleSelect(slot.date)}
                        disabled={!slot.available || isLoading}
                      >
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100">
                            {isLoading ? (
                              <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                            ) : (
                              statusInfo?.icon
                            )}
                          </div>
                          <div className="text-left">
                            <div className="text-lg font-semibold">
                              {isLoading ? <Skeleton width={80} /> : slot.date}
                            </div>
                            <div className="text-sm text-muted-foreground">
                              {isLoading ? (
                                <Skeleton width={60} />
                              ) : (
                                statusInfo?.label
                              )}
                            </div>
                          </div>
                        </div>
                      </Button>
                    </TooltipTrigger>
                    {!isLoading && statusInfo !== null && (
                      <TooltipContent>
                        <p>Statut : {statusInfo.label}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              )
            })
          ) : (
            <p className="text-center text-muted-foreground">
              Aucune commande disponible.
            </p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
