import { PaChangedCard } from "@/components/inventory/modals/CheckInModal/PaChangedCard"

import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { CheckinSideBar } from "./checkinSideBar"

export function PriceChanges({
  paChangedReferences,
  bestSellers,
  gotToNextStep,
  currentStep,
  totalSteps,
  closeCheckinModal,
  updateInventory,
}: {
  paChangedReferences: AllMercurialInfo[]
  bestSellers: AllMercurialInfo[]
  gotToNextStep: () => void
  currentStep?: number
  totalSteps?: number
  closeCheckinModal: () => void
  updateInventory: (value: string) => Promise<void>
}) {
  return (
    <div className="flex h-full gap-2">
      <div className="flex flex-col gap-2 w-full">
        <PaChangedCard
          references={paChangedReferences}
          updateInventory={updateInventory}
          bestSellers={bestSellers}
        />
      </div>
      <CheckinSideBar
        gotToNextStep={gotToNextStep}
        updateInventory={updateInventory}
        currentStep={currentStep}
        totalSteps={totalSteps}
        mercurialeInfos={paChangedReferences}
        closeCheckinModal={closeCheckinModal}
      />
    </div>
  )
}
