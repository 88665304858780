import { ArrowDown, ArrowUp } from "lucide-react"
import { twJoin } from "tailwind-merge"

interface LastWeeksPerformancesProps {
  title: string
  description: string
  oneWeekAgo: number
  twoWeeksAgo: number
  oneYearAgo: number
  currency: string
  onCheckedChange?: (value: boolean) => void
  invertColors?: boolean
  icon?: React.ReactNode
}

function formatCurrency(
  amount: number | null | undefined,
  currency: string | null | undefined,
) {
  return new Intl.NumberFormat("fr-FR", {
    notation: "compact",
    style: "currency",
    currency: currency ?? "EUR",
    maximumFractionDigits: (amount ?? 0) > 1000 ? 2 : 0,
  }).format(amount ?? 0)
}

export const LastPerformance = ({
  title,
  description,
  oneWeekAgo,
  twoWeeksAgo,
  oneYearAgo,
  currency,
  icon,
  invertColors,
}: LastWeeksPerformancesProps) => {
  const performancesDelta = oneWeekAgo - twoWeeksAgo
  return (
    <div className="bg-white rounded flex flex-col gap-1">
      <div className="flex items-center gap-2">
        {icon}
        <p className="font-bold">{title}</p>
      </div>
      <p className="font-medium">
        {description} est en{" "}
        <span
          className={twJoin(
            invertColors
              ? performancesDelta < 0
                ? "text-red-700"
                : "text-green-700"
              : performancesDelta < 0
                ? "text-green-700"
                : "text-red-700",
          )}
        >
          {performancesDelta < 0 ? "baisse" : "hausse"}
        </span>
        .
      </p>
      <div className="w-full flex items-center">
        <div className="flex-1 flex flex-col md:flex-row">
          <div className="flex-1 flex flex-col items-center justify-center p-2 text-stone-500  rounded-tl rounded-tr md:rounded-tr-none md:rounded-l bg-gray-100">
            <div className="flex flex-row items-center justify-center gap-1">
              <p className="text-xl font-bold text-center">
                {formatCurrency(twoWeeksAgo, currency)}
              </p>
            </div>
            <p className="text-center text-sm">il y a deux semaines</p>
          </div>
          <div
            className={
              "flex-1 flex flex-col items-center justify-center p-2 rounded-bl rounded-br md:rounded-bl-none md:rounded-r border border-grey border-solid"
            }
          >
            <div className="flex justify-center items-center gap-1">
              <p className="text-xl font-bold text-center">
                {formatCurrency(oneWeekAgo, currency)}
              </p>
              <div
                className={twJoin(
                  "flex items-center gap-1",
                  invertColors
                    ? performancesDelta < 0
                      ? "text-[#FF4755]"
                      : "text-green-900"
                    : performancesDelta < 0
                      ? "text-green-900"
                      : "text-[#FF4755]",
                )}
              >
                {performancesDelta < 0 ? (
                  <ArrowDown className="size-6" />
                ) : (
                  <ArrowUp className="size-6" />
                )}
                <p className="text-sm">
                  {performancesDelta >= 0 && "+"}
                  {formatCurrency(performancesDelta, currency)}
                </p>
              </div>
            </div>
            <p className="text-center text-sm">il y a une semaine</p>
          </div>
        </div>
      </div>
      {(oneYearAgo ?? 0) > 0 && (
        <p>
          <span className="font-bold">
            {formatCurrency(oneYearAgo, currency)}
          </span>{" "}
          la même semaine, l&apos;an dernier.
        </p>
      )}
    </div>
  )
}
