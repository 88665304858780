import { Dispatch, SetStateAction } from "react"
import { LoadingRefreshModal } from "./LoadingRefreshModal"
import { MissingPlanningsModal } from "./MissingPlanningsModal"
import { OfflineDataModal } from "./OfflineDataModal"
import { OutdatedDataModal } from "./OutdatedDataModal"
import { RetrySaveModal, SaveDataModalState } from "./RetrySaveModal"

import { MessagesModal } from "./MessagesModal"
import { useInventoryModalsLogic } from "@/hooks/useInventoryModalLogic"
import { MultipleMercurialesModal } from "../MultipleMercurialesModal"
import { useDispatch } from "react-redux"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import { SortOption } from "@/reducers/userInterfaceReducer"

interface InventoryModalsProps {
  isLoading: boolean
  saveDataModalState: SaveDataModalState
  isOfflineRefreshModalOpen: boolean
  isDataSynchronizeModalOpen: boolean
  isRefreshModalOpen: boolean
  isOrderReceptionDateModalOpen: boolean
  setSaveDataModalState: Dispatch<SetStateAction<SaveDataModalState>>
  setIsOfflineRefreshModalOpen: Dispatch<SetStateAction<boolean>>
  setIsDataSynchronizeModalOpen: Dispatch<SetStateAction<boolean>>
  setIsRefreshModalOpen: Dispatch<SetStateAction<boolean>>
  setIsOrderReceptionDateModalOpen: Dispatch<SetStateAction<boolean>>
  updateInventory: (value: string) => Promise<void>
  hasToCheckin: boolean
  dimMercuriales: MercurialReducerState["dimMercuriales"]
  oldState: React.MutableRefObject<{
    sortList?: string[]
    sortOption?: SortOption
    selectedDimMercurialeId?: string
  } | null>
  setIsMercurialeModalOpen: Dispatch<SetStateAction<boolean>>
  isMercurialeModalOpen: boolean
}

export function InventoryModals({
  isLoading,
  saveDataModalState,
  isOfflineRefreshModalOpen,
  isDataSynchronizeModalOpen,
  isRefreshModalOpen,
  setSaveDataModalState,
  setIsOfflineRefreshModalOpen,
  setIsDataSynchronizeModalOpen,
  setIsRefreshModalOpen,
  isOrderReceptionDateModalOpen,
  setIsOrderReceptionDateModalOpen,
  hasToCheckin,
  dimMercuriales,
  oldState,
  setIsMercurialeModalOpen,
  isMercurialeModalOpen,
}: InventoryModalsProps) {
  const dispatch = useDispatch()
  const { isValidOrderDate, refreshApplication } = useInventoryModalsLogic({
    isLoading,
    isOfflineRefreshModalOpen,
    setIsDataSynchronizeModalOpen,
    setIsRefreshModalOpen,
  })

  return (
    <>
      <RetrySaveModal
        saveDataModalState={saveDataModalState}
        setSaveDataModalState={setSaveDataModalState}
      />
      <LoadingRefreshModal isOpen={isDataSynchronizeModalOpen} />
      <OfflineDataModal
        isOpen={isOfflineRefreshModalOpen}
        setIsOpen={setIsOfflineRefreshModalOpen}
        isValidOrderDate={isValidOrderDate}
      />
      <OutdatedDataModal
        isOpen={isRefreshModalOpen}
        setIsOpen={setIsRefreshModalOpen}
        setIsOfflineRefreshModalOpen={setIsOfflineRefreshModalOpen}
        refreshApplication={refreshApplication}
      />
      <MissingPlanningsModal
        isOpen={isOrderReceptionDateModalOpen}
        setIsOpen={setIsOrderReceptionDateModalOpen}
        refreshApplication={refreshApplication}
      />
      {!hasToCheckin && (
        <>
          <MessagesModal />
          <MultipleMercurialesModal
            isOpen={isMercurialeModalOpen}
            onClose={() => setIsMercurialeModalOpen(false)}
            onSelect={(dimMercurialeId) => {
              oldState.current = null
              dispatch({
                type: "setSelectedDimMercurialeId",
                payload: dimMercurialeId,
              })
              setIsMercurialeModalOpen(false)
            }}
            dimMercuriales={dimMercuriales}
          />
        </>
      )}
    </>
  )
}

interface CheckinModalsProps {
  isOrderReceptionDateModalOpen: boolean
  isOfflineRefreshModalOpen: boolean
  setIsOrderReceptionDateModalOpen: Dispatch<SetStateAction<boolean>>
  setIsRefreshModalOpen: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  isMercurialeModalOpen: boolean
  setIsMercurialeModalOpen: Dispatch<SetStateAction<boolean>>
  dimMercuriales: MercurialReducerState["dimMercuriales"]
  oldState: React.MutableRefObject<{
    sortList?: string[]
    sortOption?: SortOption
    selectedDimMercurialeId?: string
  } | null>
}

export const CheckinModals = ({
  isOrderReceptionDateModalOpen,
  isOfflineRefreshModalOpen,
  setIsOrderReceptionDateModalOpen,
  setIsRefreshModalOpen,
  isMercurialeModalOpen,
  setIsMercurialeModalOpen,
  dimMercuriales,
  oldState,
  isLoading,
}: CheckinModalsProps) => {
  const dispatch = useDispatch()
  const { refreshApplication } = useInventoryModalsLogic({
    isLoading,
    isOfflineRefreshModalOpen,
    setIsOrderReceptionDateModalOpen,
    setIsRefreshModalOpen,
  })

  return (
    <>
      <MessagesModal />
      <MissingPlanningsModal
        isOpen={isOrderReceptionDateModalOpen}
        setIsOpen={setIsOrderReceptionDateModalOpen}
        refreshApplication={refreshApplication}
      />
      <MultipleMercurialesModal
        isOpen={isMercurialeModalOpen}
        onClose={() => setIsMercurialeModalOpen(false)}
        onSelect={(dimMercurialeId) => {
          oldState.current = null
          dispatch({
            type: "setSelectedDimMercurialeId",
            payload: dimMercurialeId,
          })
          setIsMercurialeModalOpen(false)
        }}
        dimMercuriales={dimMercuriales}
      />
    </>
  )
}
