import { useWindowSize } from "@/hooks/useWindowSize"
import OutstandingOrders from "../../OustandingOrders"

const renderBadge = (isPrecommande = false, isLineaireUpdatedTo0 = false) => {
  if (isPrecommande) {
    return (
      <p className="text-cyan-700 md:text-white md:bg-cyan-700 md:px-1 md:py-1 md:rounded-sm">
        Preco
      </p>
    )
  }
  if (isLineaireUpdatedTo0) {
    return (
      <p className="text-red-700 md:text-white md:bg-red-700 md:px-1 md:py-1 md:rounded-sm">
        Linéaire à 0
      </p>
    )
  }
  return <></>
}

interface OrderExtraElementProps {
  isPrecommande: boolean
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
  unit?: string
  isLineaireUpdatedTo0?: boolean
}

export function OrderExtraElement({
  isPrecommande,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
  unit,
  isLineaireUpdatedTo0,
}: OrderExtraElementProps) {
  const { isMD } = useWindowSize()

  return (
    <div className="w-full flex items-center flex-col gap-2">
      <div className="h-[10px] md:h-[20px] text-xxs font-bold text-center">
        {renderBadge(isPrecommande, isLineaireUpdatedTo0)}
      </div>
      {!isMD && (waitedQuantityOrdered ?? 0) > 0 && (
        <div className="w-fit bg-gray-100 border px-2 py-1 rounded flex justify-around">
          {(waitedQuantityOrdered ?? 0) > 0 && (
            <OutstandingOrders
              isPromo={false}
              quantity={waitedQuantityOrdered}
              displayedUnit={unit}
            />
          )}
          {(promoWaitedQuantityOrdered ?? 0) > 0 && (
            <OutstandingOrders
              isPromo={true}
              quantity={promoWaitedQuantityOrdered}
              displayedUnit={unit}
            />
          )}
        </div>
      )}
    </div>
  )
}
