"use client"

import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { useMemo, useState } from "react"
import { add } from "date-fns"
import { useSelector } from "react-redux"
import { useGetWorstReferencesGlobalQuery } from "@/utils/__generated__/graphql"
import { GlobalReferencePredictionsChart } from "../components/predictions/GlobalReferencePredictionsChart"

import { PerformancesPageContext } from "../PerformancesPage"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { StateType } from "@/types"

export function PredictionPerformancesPage() {
  const { fromDate, toDate } = useOutletContext<PerformancesPageContext>()
  const params = useParams()
  const [searchParams] = useSearchParams()

  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const [selectedReference, setSelectedReference] = useState<string>("")

  const { data, loading } = useGetWorstReferencesGlobalQuery({
    variables: {
      input: {
        company_id: companyId ?? "",
        store_id: storeId!,
        franchise_name: franchiseParam,
        start_date: formatDateToYYYYMMDD(fromDate ?? defaultStartDate),
        end_date: formatDateToYYYYMMDD(toDate ?? defaultEndDate),
        start_rank: 1,
        end_rank: 1000,
      },
    },
    skip: !companyId || !fromDate || !toDate,
  })

  const allChartData = useMemo(() => {
    if (!data?.getWorstReferencesGlobal?.global_performances) return []
    return data.getWorstReferencesGlobal.global_performances.map((perf) => ({
      reference: perf.sale_name,
      tauxDeModif: perf.updated,
      predictedAmount: Math.round(perf.predicted_amount * 100) / 100,
      orderedAmount: Math.round(perf.ordered_amount * 100) / 100,
      salesAmount: Math.round(perf.sales_amount * 100) / 100,
      distance: perf.amount_difference,
    }))
  }, [data])

  const filteredChartData = useMemo(() => {
    if (!allChartData.length) return []
    if (!selectedReference) return allChartData
    return allChartData.filter((c) => c.reference === selectedReference)
  }, [allChartData, selectedReference])

  const referenceOptions = useMemo(() => {
    const uniqueRefs = new Set(allChartData.map((c) => c.reference))
    return Array.from(uniqueRefs)
  }, [allChartData])

  return (
    <div className="h-full flex flex-col p-4 space-y-4">
      <GlobalReferencePredictionsChart
        data={filteredChartData}
        loading={loading}
        selectedReference={selectedReference}
        setSelectedReference={setSelectedReference}
        referenceOptions={referenceOptions}
      />
    </div>
  )
}
