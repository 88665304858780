"use client"

import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  ReferenceWithRegroupingOrdersIdaRecords,
  useCreateRegroupingOrdersIdaMutation,
} from "@/utils/__generated__/graphql"
import { toast } from "sonner"
import { Spinner } from "@/ui/Spinner"
import { TrendingUp } from "lucide-react"
import { Virtuoso } from "react-virtuoso"

interface BatchMatchOrdersReferenceDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedReferences: ReferenceWithRegroupingOrdersIdaRecords[]
  refetch: () => void
  allReferences: ReferenceWithRegroupingOrdersIdaRecords[]
  storeId: string
  companyId: string
  franchiseParam: string | undefined
  setReferences: Dispatch<
    SetStateAction<ReferenceWithRegroupingOrdersIdaRecords[]>
  >
}

const BatchMatchOrdersReferenceDialog: React.FC<
  BatchMatchOrdersReferenceDialogProps
> = ({
  isOpen,
  onClose,
  selectedReferences,
  refetch,
  allReferences,
  storeId,
  companyId,
  franchiseParam,
  setReferences,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredOptions, setFilteredOptions] = useState<
    ReferenceWithRegroupingOrdersIdaRecords[]
  >([])
  const [selectedTarget, setSelectedTarget] =
    useState<ReferenceWithRegroupingOrdersIdaRecords | null>(null)

  const [createRegroupingOrdersIdaMutation, { loading: mutationLoading }] =
    useCreateRegroupingOrdersIdaMutation()

  useEffect(() => {
    if (isOpen) {
      setSearchTerm("")
      setSelectedTarget(null)
      // Initially, no search done = no filtered options displayed.
      setFilteredOptions([])
    }
  }, [isOpen, selectedReferences, allReferences])

  const handleSearch = (term: string) => {
    setSearchTerm(term)
    if (term.trim() === "") {
      setFilteredOptions([])
      return
    }

    const lowerTerm = term.toLowerCase()

    // Filter references similar to single match logic:
    // - Exclude any reference that is matched or already selected in selectedReferences
    // - Match by libelle_base or sale_name_ida_base
    const filtered = allReferences.filter(
      (ref) =>
        !selectedReferences.some(
          (selected) => selected.sale_name_ida_base === ref.sale_name_ida_base,
        ) &&
        !ref.is_matched &&
        (ref.libelle_base.toLowerCase().includes(lowerTerm) ||
          ref.sale_name_ida_base.toLowerCase().includes(lowerTerm)),
    )

    // Ensure uniqueness by sale_name_ida_base
    const uniqueReferences = new Map<
      string,
      ReferenceWithRegroupingOrdersIdaRecords
    >()
    filtered.forEach((ref) => {
      if (!uniqueReferences.has(ref.sale_name_ida_base)) {
        uniqueReferences.set(ref.sale_name_ida_base, ref)
      }
    })

    setFilteredOptions(Array.from(uniqueReferences.values()))
  }

  const handleBatchMatch = async () => {
    if (!selectedTarget) {
      toast.error("Veuillez sélectionner une référence cible.")
      return
    }

    const promises = selectedReferences.map((baseReference) => {
      const mutationInput = {
        sale_name_ida_base: baseReference.sale_name_ida_base,
        sale_name_ida_cible: selectedTarget.sale_name_ida_base,
        libelle_base: baseReference.libelle_base,
        libelle_cible: selectedTarget.libelle_base,
        store_id: storeId,
        company_id: companyId,
        franchise_name: franchiseParam,
        order_code: baseReference.order_code,
        order_name: baseReference.order_name,
        unit: baseReference.unit,
        colisage: baseReference.colisage,
        supplier_name: baseReference.supplier_name,
      }

      return createRegroupingOrdersIdaMutation({
        variables: {
          input: mutationInput,
        },
      })
    })

    try {
      const results = await Promise.all(promises)
      const successCount = results.filter(
        (res) => res.data?.createRegroupingOrdersIda?.records,
      ).length

      if (successCount === selectedReferences.length) {
        setReferences((prev) => {
          return prev.map((r) => {
            const target = selectedReferences.find(
              (ref, i) =>
                r.sale_name_ida_base === ref.sale_name_ida_base &&
                r.order_code === ref.order_code &&
                r.supplier_name === ref.supplier_name &&
                results[i].data?.createRegroupingOrdersIda?.error === null,
            )
            return target !== undefined
              ? {
                  ...r,
                  libelle_cible: selectedTarget.libelle_base,
                  sale_name_ida_cible: selectedTarget.sale_name_ida_base,
                  is_matched: true,
                }
              : r
          })
        })
        toast.success("Toutes les références ont été liées avec succès.")
      } else {
        toast.error(
          `${successCount} sur ${selectedReferences.length} références ont été liées avec succès.`,
        )
      }

      refetch()
      onClose()
    } catch (error) {
      console.error("Erreur lors de la liaison en lot:", error)
      toast.error("Une erreur est survenue lors de la liaison en lot.")
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Lier les Références Sélectionnées</DialogTitle>
          <DialogDescription>
            Sélectionnez une référence cible pour lier les{" "}
            {selectedReferences.length} références sélectionnées.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground">
              <TrendingUp className="h-4 w-4" />
            </span>
            <Input
              placeholder="Rechercher une référence..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="pl-10 mb-4"
            />
          </div>

          {mutationLoading && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}

          <div className="h-60">
            {searchTerm.trim() === "" ? (
              <div className="text-gray-500">
                Veuillez saisir un terme de recherche pour afficher les
                références.
              </div>
            ) : filteredOptions.length === 0 && !mutationLoading ? (
              <div className="text-gray-500">Aucune référence trouvée.</div>
            ) : (
              <Virtuoso
                className="h-60"
                data={filteredOptions}
                itemContent={(_, filteredOption) => (
                  <Button
                    key={filteredOption.sale_name_ida_base}
                    variant={
                      selectedTarget?.sale_name_ida_base ===
                      filteredOption.sale_name_ida_base
                        ? "default"
                        : "outline"
                    }
                    onClick={() => setSelectedTarget(filteredOption)}
                    className="w-full text-left mb-2"
                  >
                    {filteredOption.libelle_base} (
                    {filteredOption.sale_name_ida_base})
                  </Button>
                )}
              />
            )}
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Annuler
            </Button>
            <Button
              onClick={handleBatchMatch}
              disabled={mutationLoading || !selectedTarget}
            >
              {mutationLoading ? <Spinner /> : "Lier"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default BatchMatchOrdersReferenceDialog
