import { useRef } from "react"
import { Card } from "@/components/ui/card"
import { Checker } from "./components/checker"
import { StepperProgressBar } from "./components/progressBar"
import { Skeleton } from "../ui/skeleton"

interface StepperProps {
  title: string
  description?: string
  steps: {
    title: string
    descriptions?: string[]
  }[]
  currentStep: number
  setCurrentStep: (step: number) => void
  direction?: "row" | "column"
  loading?: boolean
}

export const Stepper = ({
  title,
  description,
  steps,
  currentStep,
  setCurrentStep,
  direction = "column",
  loading,
}: StepperProps) => {
  const stepRefs = useRef<(HTMLDivElement | null)[]>([])

  return (
    <div
      className={` w-full flex flex-col gap-2 ${
        direction === "column" ? "md:w-[500px]" : ""
      }`}
    >
      <div>
        <p className="hidden md:block text-2xl font-bold">{title}</p>
        {description && <p className="text-lg">{description}</p>}
      </div>
      <StepperProgressBar currentStep={currentStep} steps={steps} />
      <div
        className={`flex ${
          direction === "row" ? "flex-row" : "flex-col"
        } gap-4 overflow-x-auto whitespace-nowrap -mx-4 px-4`}
      >
        {loading ? (
          <div className="flex flex-row gap-2">
            <Skeleton className="h-14 w-[300px] bg-slate-300" />
            <Skeleton className="h-14 w-[300px] bg-slate-300" />
            <Skeleton className="h-14 w-[300px] bg-slate-300" />
          </div>
        ) : (
          steps.map((step, index) => {
            const isCurrentStep = currentStep === index + 1
            const isPreviousStep = currentStep > index + 1
            return (
              <Card
                key={step.title}
                className="cursor-pointer"
                onClick={() => setCurrentStep(index + 1)}
                ref={(el) => (stepRefs.current[index] = el)}
              >
                <div className="flex flex-col gap-1 p-4">
                  <div className="flex flex-row gap-4 items-center">
                    <Checker
                      isCurrentStep={isCurrentStep}
                      isPreviousStep={isPreviousStep}
                      index={index + 1}
                    />
                    <p
                      className={`text-sm font-bold ${
                        isCurrentStep || isPreviousStep
                          ? "text-black"
                          : "text-gray-500"
                      }`}
                    >
                      {step.title}
                    </p>
                  </div>
                  {step.descriptions && (
                    <div
                      className={`overflow-hidden transition-all duration-300 ml-[56px]`}
                      style={{
                        maxHeight: isCurrentStep
                          ? `${step.descriptions.length * 24 + 40}px`
                          : "0px",
                      }}
                    >
                      {step.descriptions.map((description) => (
                        <p key={description} className="text-sm">
                          • {description}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </Card>
            )
          })
        )}
      </div>
    </div>
  )
}
