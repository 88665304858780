import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { StateType } from "@/types"
import { useGetCompaniesQuery } from "@/utils/__generated__/graphql"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

export function CompanySelector() {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const companyId = params.companyId
  const companyFranchise = searchParams.get("franchise") ?? undefined

  const { storeId } = useSelector((state: StateType) => state.storeReducer)

  const { data } = useGetCompaniesQuery({
    variables: { input: { store_id: storeId! } },
  })

  function computeValue(
    companyId: string | undefined,
    companyFranchise: string | null | undefined,
  ) {
    return `${companyId ?? ""}/${companyFranchise ?? ""}`
  }

  const companies = useMemo(() => {
    return data?.companies ?? []
  }, [data?.companies])

  const companiesItems = useMemo(() => {
    return companies.map((company, i) => {
      const companyValue = computeValue(company.company_id, company.franchise)
      const link =
        typeof company.franchise === "string"
          ? `https://storage.googleapis.com/companies_assets/${company.company_id}/${company.franchise}/logo`
          : `https://storage.googleapis.com/companies_assets/${company.company_id}/logo`

      return (
        <SelectItem key={i} value={companyValue}>
          <div className="capitalize flex items-center gap-2">
            <img src={link} alt={company.company_name} className="size-6" />
            <p>{company.franchise ?? company.company_name}</p>
          </div>
        </SelectItem>
      )
    })
  }, [companies])

  function handleValueChange(value: string) {
    const [companyId, companyFranchise] = value.split("/")
    navigate(
      `/admin/companies/${companyId}${
        companyFranchise !== "" ? `?franchise=${companyFranchise}` : ""
      }`,
    )
  }

  const value = computeValue(companyId, companyFranchise)

  const displayedValue = useMemo(() => {
    const company = companies.find(
      (company) =>
        value === computeValue(company.company_id, company.franchise),
    )
    return company?.franchise ?? company?.company_name
  }, [companies, value])

  return (
    <Select value={value} onValueChange={handleValueChange}>
      <SelectTrigger className="w-[180px] capitalize">
        <SelectValue placeholder="Compagnie">{displayedValue}</SelectValue>
      </SelectTrigger>
      <SelectContent>{companiesItems}</SelectContent>
    </Select>
  )
}
