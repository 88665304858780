"use client"

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { RankRangeSelector } from "../RankRangeSelector" // Updated slider
import { Spinner } from "@/ui/Spinner"
import { toast } from "sonner"

interface TopUnorderedReferencesChartCardProps {
  storeName: string
  references: {
    saleName: string
    TotalSales: number
    OrderDays: number
    NotOrderedDays: number
    TotalOrderedQuantity: number
  }[]
  viewMode: string
  rangeValues: [number, number]
  setRangeValues: (values: [number, number]) => void
  handleViewModeChange: (mode: string) => void
  loading?: boolean
  error?: boolean
}

const TopUnorderedReferencesChartCard = ({
  storeName,
  references,
  viewMode,
  rangeValues,
  setRangeValues,
  handleViewModeChange,
  loading = false,
  error = false,
}: TopUnorderedReferencesChartCardProps) => {
  if (error) {
    const errorMessage =
      "Une erreur est survenue lors du chargement des données."
    toast.error(errorMessage)
  }

  return (
    <Card className="w-full max-w-6xl mx-auto my-4">
      <CardHeader>
        <CardTitle>{storeName}</CardTitle>
        <div>
          <RankRangeSelector
            min={1}
            max={20}
            step={1}
            values={rangeValues}
            onChange={(vals) => setRangeValues(vals)}
            label="Nombre de références:"
          />
        </div>
        <div className="mt-4 flex flex-col gap-2">
          <div className="inline-flex rounded-md" role="group">
            <button
              type="button"
              onClick={() => handleViewModeChange("Nombre de commandes")}
              className={`px-4 py-2 text-sm font-medium border border-gray-200
                ${
                  viewMode === "Nombre de commandes"
                    ? "bg-black text-white"
                    : "bg-white text-gray-700 hover:bg-gray-50"
                }
                first:rounded-l-md last:rounded-r-md
              `}
            >
              Jours de commandes
            </button>

            <button
              type="button"
              onClick={() => handleViewModeChange("amount")}
              className={`px-4 py-2 text-sm font-medium border border-gray-200
                ${
                  viewMode === "amount"
                    ? "bg-black text-white"
                    : "bg-white text-gray-700 hover:bg-gray-50"
                }
                first:rounded-l-md last:rounded-r-md
              `}
            >
              Quantités
            </button>

            <button
              type="button"
              onClick={() => handleViewModeChange("both")}
              className={`px-4 py-2 text-sm font-medium border border-gray-200
                ${
                  viewMode === "both"
                    ? "bg-black text-white"
                    : "bg-white text-gray-700 hover:bg-gray-50"
                }
                first:rounded-l-md last:rounded-r-md
              `}
            >
              Tout
            </button>
          </div>
        </div>
      </CardHeader>

      <CardContent>
        {error ? (
          <div className="text-red-500">
            Une erreur est survenue lors du chargement des données.
          </div>
        ) : loading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : references.length === 0 ? (
          <div className="text-gray-500">
            Aucune donnée disponible à afficher.
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={references}
              margin={{ top: 20, right: 30, left: 20, bottom: 150 }}
              aria-label={`Top Références Non Commandées pour ${storeName}`}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="saleName"
                angle={-45}
                textAnchor="end"
                interval={0}
                height={150}
                tick={{ fontSize: 12 }}
              />
              {/* Primary Y-Axis */}
              <YAxis
                yAxisId="left"
                label={{
                  value: "Nombre de Jours",
                  angle: -90,
                  position: "insideLeft",
                  offset: -10,
                  dy: 40,
                  fill: "black",
                }}
                allowDecimals={false}
              />
              {/* Secondary Y-Axis */}
              {(viewMode === "amount" || viewMode === "both") && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  allowDecimals={false}
                />
              )}
              <Tooltip />
              <Legend verticalAlign="top" height={36} />

              {/* Bars for Récurrence */}
              {(viewMode === "Nombre de commandes" || viewMode === "both") && (
                <>
                  <Bar
                    yAxisId="left"
                    dataKey="OrderDays"
                    stackId="a"
                    fill="#82ca9d"
                    name="Jours Commandés"
                  />
                  <Bar
                    yAxisId="left"
                    dataKey="NotOrderedDays"
                    stackId="a"
                    fill="#ff4d4f"
                    name="Jours Non Commandés"
                  />
                </>
              )}

              {/* Bars for Amount */}
              {(viewMode === "amount" || viewMode === "both") && (
                <>
                  <Bar
                    yAxisId="right"
                    dataKey="TotalOrderedQuantity"
                    fill="#8884d8"
                    name="Quantités commandées (u)"
                  />
                  <Bar
                    yAxisId="right"
                    dataKey="TotalSales"
                    fill="#ffc658"
                    name="Ventes (€)"
                  />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  )
}

export default TopUnorderedReferencesChartCard
