// src/components/ui/tooltip.tsx

"use client"

import { FC, ReactNode, useState } from "react"

interface TooltipProps {
  content: string
  children: ReactNode
}

const Tooltip: FC<TooltipProps> = ({ content, children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-700 text-white text-xs rounded shadow-lg z-50">
          {content}
        </div>
      )}
    </div>
  )
}

export default Tooltip
