import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"

import { useSelector } from "react-redux"
import { UsedToBePromote } from "./usedToBePromote"
import { OverAndUnderExposureCard } from "./overAndUnderExposure"

import { CheckinSideBar } from "../checkinSideBar"
import { useState } from "react"

export const HandleLinear = ({
  usedToBePromoReferences,
  shelfFloorSizeOverexposure,
  shelfFloorSizeUnderExposure,
  updateInventory,
  gotToNextStep,
  currentStep,
  totalSteps,
  closeCheckinModal,
}: {
  usedToBePromoReferences: AllMercurialInfo[]
  shelfFloorSizeOverexposure: AllMercurialInfo[]
  shelfFloorSizeUnderExposure: AllMercurialInfo[]
  updateInventory: (value: string) => Promise<void>
  gotToNextStep: () => void
  currentStep?: number
  totalSteps?: number
  closeCheckinModal: () => void
}) => {
  const [currentSelectedSection, setCurrentSelectedSection] = useState<
    | "usedToBePromo"
    | "shelfFloorSizeOverexposure"
    | "shelfFloorSizeUnderExposure"
  >("usedToBePromo")

  const { companyName, storeFranchise, companyId } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const setCurrentSelectedSectionToUsedToBePromo = () => {
    setCurrentSelectedSection("usedToBePromo")
  }

  const setCurrentSelectedSectionToShelfFloorSizeOverexposure = () => {
    setCurrentSelectedSection("shelfFloorSizeOverexposure")
  }

  const setCurrentSelectedSectionToShelfFloorSizeUnderExposure = () => {
    setCurrentSelectedSection("shelfFloorSizeUnderExposure")
  }

  return (
    <div className="flex h-full gap-2">
      <div className="flex flex-col gap-2 w-full z-0">
        {usedToBePromoReferences.length > 0 && (
          <UsedToBePromote
            references={usedToBePromoReferences}
            setCurrentSelectedSection={setCurrentSelectedSectionToUsedToBePromo}
            updateInventory={updateInventory}
          />
        )}
        <div className="flex flex-col lg:flex-row gap-2 h-full z-0">
          {shelfFloorSizeOverexposure.length > 0 && (
            <OverAndUnderExposureCard
              references={shelfFloorSizeOverexposure}
              companyId={companyId}
              companyName={companyName}
              franchise={storeFranchise}
              updateInventory={updateInventory}
              flag="overexposure"
              setCurrentSelectedSection={
                setCurrentSelectedSectionToShelfFloorSizeOverexposure
              }
            />
          )}
          {shelfFloorSizeUnderExposure.length > 0 && (
            <OverAndUnderExposureCard
              references={shelfFloorSizeUnderExposure}
              companyId={companyId}
              companyName={companyName}
              franchise={storeFranchise}
              updateInventory={updateInventory}
              flag="underexposure"
              setCurrentSelectedSection={
                setCurrentSelectedSectionToShelfFloorSizeUnderExposure
              }
            />
          )}
        </div>
      </div>
      <CheckinSideBar
        gotToNextStep={gotToNextStep}
        updateInventory={updateInventory}
        currentStep={currentStep}
        totalSteps={totalSteps}
        mercurialeInfos={
          currentSelectedSection === "shelfFloorSizeOverexposure"
            ? shelfFloorSizeOverexposure
            : currentSelectedSection === "shelfFloorSizeUnderExposure"
              ? shelfFloorSizeUnderExposure
              : usedToBePromoReferences
        }
        closeCheckinModal={closeCheckinModal}
      />
    </div>
  )
}
