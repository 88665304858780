// src/components/ModificationsChartByStore.tsx

"use client"

import { parseISO, format } from "date-fns"
import { useEffect, useMemo } from "react"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { useGetStoreInventoryModificationsEvolutionQuery } from "@/utils/__generated__/graphql"
import { CardContent, CardTitle } from "@/components/ui/card"
import { PerformancesPageContext } from "../../PerformancesPage"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

export default function ModificationsChartByStore() {
  const { selectedStoreId, stores, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  // If no store is selected, treat it as “Tous les magasins”
  const isAllStores = !selectedStoreId

  // We'll attempt to get the store's name from our list of stores
  const StoreName = useMemo(() => {
    if (isAllStores) return "Tous les magasins"
    const store = stores?.find((store) => store.id === selectedStoreId)
    return store?.store_name ?? "Aucun magasin sélectionné"
  }, [selectedStoreId, stores, isAllStores])

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId

  // 1) Call the query
  //    If `selectedStoreId` is null/undefined => the resolver can interpret "no store filter => all stores"
  //    So we do not skip the query in that case. We pass "selected_store_id: undefined"
  const { data, loading, error, refetch } =
    useGetStoreInventoryModificationsEvolutionQuery({
      variables: {
        input: {
          store_id: storeId!, // The user's store or main store
          selected_store_id: selectedStoreId ?? undefined, // If null => no filter
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          company_id: companyId!,
          franchise_name: franchiseParam,
        },
      },
      fetchPolicy: "cache-and-network",
      // No skip => always fetch, so we can get data for "all stores" if no store is selected
    })

  // 2) Refetch on changes
  useEffect(() => {
    refetch({
      input: {
        store_id: storeId!,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        company_id: companyId!,
        franchise_name: franchiseParam,
      },
    })
  }, [
    selectedStoreId,
    fromDate,
    toDate,
    refetch,
    companyId,
    franchiseParam,
    storeId,
  ])

  // 3) Build chart data
  const chartData = useMemo(() => {
    return (
      data?.getStoreInventoryModificationsEvolution?.modifications_evolution
        ?.map((item) => {
          try {
            return {
              date: format(parseISO(item.modification_date), "yyyy-MM-dd"),
              modification_count: item.modification_count,
              increase_count: item.increase_count,
              decrease_count: item.decrease_count,
              total_increase: Math.round(item.total_increase * 100) / 100,
              total_decrease: Math.round(item.total_decrease * 100) / 100,
            }
          } catch (error) {
            console.error("Invalid date format:", item.modification_date)
            return null
          }
        })
        .filter(Boolean) ?? []
    )
  }, [data])

  // 4) Render
  return (
    <div className="h-full flex-1 p-4 flex flex-col gap-4 overflow-y-auto">
      <CardTitle>
        {/* If no store => show "Tous les magasins", else store name */}
        Nombres de modifications de stock pour {StoreName}
      </CardTitle>

      <CardContent>
        {loading ? (
          // Skeleton while loading
          <div className="animate-pulse">
            <div className="h-64 bg-gray-200 rounded"></div>
          </div>
        ) : error ? (
          <div className="text-red-500">
            Erreur lors du chargement des données.
          </div>
        ) : chartData.length === 0 ? (
          <div>Aucune donnée disponible pour ce magasin ou période.</div>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              key={selectedStoreId ?? "ALL"}
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="modification_count"
                stroke="#8884d8"
                name="Nombre de modifications"
              />
              <Line
                type="monotone"
                dataKey="increase_count"
                stroke="#82ca9d"
                name="Nombre d'augmentations"
              />
              <Line
                type="monotone"
                dataKey="decrease_count"
                stroke="#FF0000"
                name="Nombre de diminutions"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </div>
  )
}
