import { FilterFn } from "@tanstack/react-table"
import { ReferenceWithRegroupingOrdersIdaRecords } from "@/utils/__generated__/graphql"

/**
 * A simple case-insensitive "contains" filter.
 * Matches if rowValue (string) includes the user’s filterValue (substring).
 */
export const containsFilter: FilterFn<
  ReferenceWithRegroupingOrdersIdaRecords
> = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId)
  // Guard for string type
  if (typeof rowValue !== "string") return false
  if (typeof filterValue !== "string") return false

  return rowValue.toLowerCase().includes(filterValue.toLowerCase())
}

// Optional: define when TanStack should auto-remove the filter
containsFilter.autoRemove = (val) => !val
