import { Button } from "@/components/ui/button"
import { usePermissions } from "@/hooks/usePermissions"
import { ArrowRight } from "lucide-react"

export const ValidationFooter = ({
  onClick,
  currentStep,
  totalSteps,
  closeCheckinModal,
}: {
  onClick: () => void
  currentStep?: number
  totalSteps?: number
  closeCheckinModal: () => void
}) => {
  const { isIdaAdmin } = usePermissions()

  const isLastStep = currentStep === totalSteps
  return (
    <div
      className={`flex flex-col fixed ${
        isIdaAdmin ? "bottom-10" : "bottom-0"
      } left-0 w-full bg-white p-4 z-50 gap-2`}
    >
      <Button
        className="w-full py-6 bg-green-800 hover:bg-green-900"
        onClick={onClick}
      >
        {isLastStep ? "Passer commande" : "Continuer"}
        {isLastStep && <ArrowRight className="w-4 h-4" />}
      </Button>
      {!isLastStep && (
        <Button
          variant="outline"
          className="w-full py-6"
          onClick={closeCheckinModal}
        >
          <p>Passer commande</p>
          <ArrowRight className="w-4 h-4" />
        </Button>
      )}
    </div>
  )
}
