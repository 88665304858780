"use client"

import { useState, useMemo, useEffect } from "react"
import {
  matchPath,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom"
import { useSelector } from "react-redux"
import { add } from "date-fns"
import { DateRange } from "react-day-picker"

import { StateType } from "@/types"
import { Store, useGetStoresQuery } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"
import { Button } from "@/components/ui/button"
import {
  BarChart3,
  ChartBar,
  ChartNetwork,
  ChevronDown,
  ChevronRight,
  EuroIcon,
  LineChart,
  PackageOpenIcon,
  PackageSearch,
  PanelRightClose,
  PanelRightOpen,
  Send,
  Star,
  TrendingDownIcon,
} from "lucide-react"
import { twJoin } from "tailwind-merge"

import { StoreSelector } from "./components/StoreSelector"
import { DateSelector } from "./components/DateSelector"
import { useIsMobile } from "@/hooks/use-mobile"
import { usePermissions } from "@/hooks/usePermissions"

export interface PerformancesPageContext {
  storeId: string | null
  companyId: string | undefined
  franchise: string | undefined
  fromDate: Date
  toDate: Date
  selectedStoreId: string | null
  stores: Store[] | undefined
}

type SidebarNavItem = {
  id: string
  title: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  permission?: "isCompanyAdmin" | "isIdaAdmin"
} & (
  | {
      children: SidebarNavItem[]
      filters?: undefined
    }
  | {
      children?: undefined
      filters: {
        store: boolean
        dates: boolean
      }
    }
)

const sidebarNavItems: SidebarNavItem[] = [
  {
    id: "taux-de-modification",
    title: "Suivi des commandes",
    icon: Send,
    filters: {
      store: true,
      dates: true,
    },
  },
  {
    permission: "isIdaAdmin",
    id: "global",
    title: "Performance par magasin",
    icon: BarChart3,
    filters: {
      store: false,
      dates: true,
    },
  },
  {
    permission: "isIdaAdmin",
    id: "prediction",
    title: "Suivi des prédictions",
    icon: LineChart,
    filters: {
      store: false,
      dates: true,
    },
  },
  {
    permission: "isCompanyAdmin",
    id: "inventory",
    title: "Suivi des stocks",
    icon: PackageSearch,
    children: [
      {
        permission: "isCompanyAdmin",
        id: "level-stock",
        title: "Niveaux",
        icon: ChartBar,
        filters: {
          store: true,
          dates: true,
        },
      },
      {
        id: "by-store",
        title: "Modifications",
        icon: PackageOpenIcon,
        filters: {
          store: true,
          dates: true,
        },
      },
    ],
  },
  {
    permission: "isCompanyAdmin",
    id: "rupture-global",
    title: "Suivi des ruptures",
    icon: Star,
    children: [
      {
        permission: "isCompanyAdmin",
        id: "potentially",
        title: "Ruptures potentielles",
        icon: TrendingDownIcon,
        filters: {
          store: true,
          dates: true,
        },
      },
      {
        permission: "isCompanyAdmin",
        id: "by-store",
        title: "Top références",
        icon: ChartNetwork,
        filters: {
          store: true,
          dates: true,
        },
      },
    ],
  },
  {
    id: "predicted-ca-vs-sales",
    title: "Prédictions VS Ventes",
    icon: EuroIcon,
    filters: {
      store: true,
      dates: true,
    },
  },
]

export function PerformancesPage() {
  /* ------------------ 1) Hooks called at top-level in stable order  ------------------ */

  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const fromParam = searchParams.get("from") ?? undefined
  const toParam = searchParams.get("to") ?? undefined
  const companyId = params.companyId

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: fromParam ? new Date(fromParam) : defaultStartDate,
    to: toParam ? new Date(toParam) : defaultEndDate,
  })

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const isMobile = useIsMobile()
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile)

  const location = useLocation()
  const [expandedItem, setExpandedItem] = useState<string | null>(null)

  const { isCompanyAdmin, isIdaAdmin } = usePermissions()

  const {
    data: storesData,
    loading: storesLoading,
    error: storesError,
  } = useGetStoresQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise: franchiseParam,
        include_inactive: false,
      },
    },
    skip: !companyId,
  })

  const stores = useMemo(
    () => storesData?.getStores?.stores ?? [],
    [storesData],
  )

  const companyName = stores[0]?.company_name?.toLowerCase() || ""

  const filteredNavItems = useMemo(() => {
    return sidebarNavItems.filter((item) => {
      if (item.id === "predicted-ca-vs-sales") {
        if (isIdaAdmin) return true
        if (
          isCompanyAdmin &&
          companyName !== "naturalia" &&
          companyName !== "marceletfils"
        )
          return true

        return false
      }

      if (item.permission === "isCompanyAdmin" && !isCompanyAdmin) return false
      if (item.permission === "isIdaAdmin" && !isIdaAdmin) return false
      return true
    })
  }, [isCompanyAdmin, isIdaAdmin, companyName])

  useEffect(() => {
    if (typeof isMobile !== "undefined") {
      setIsSidebarOpen(!isMobile)
    }
  }, [isMobile])

  const context: PerformancesPageContext = {
    storeId,
    companyId,
    franchise: franchiseParam,
    fromDate,
    toDate,
    selectedStoreId,
    stores,
  }

  const handleToggle = (itemId: string) => {
    setExpandedItem((prev) => (prev === itemId ? null : itemId))
  }

  const selectedPage = useMemo(() => {
    for (const sidebarItem of sidebarNavItems) {
      if (sidebarItem.children !== undefined) {
        for (const child of sidebarItem.children) {
          if (
            matchPath(
              `/admin/companies/:companyId/performances/${sidebarItem.id}/${child.id}`,
              location.pathname,
            ) !== null
          ) {
            return child
          }
        }
      }
      if (
        matchPath(
          `/admin/companies/:companyId/performances/${sidebarItem.id}`,
          location.pathname,
        ) !== null
      ) {
        return sidebarItem
      }
    }
  }, [location.pathname])

  if (storesLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (storesError) {
    return <div className="text-red-500">Error fetching stores.</div>
  }

  return (
    <div className="flex w-full h-full">
      <div
        className={twJoin(
          "border-r p-2 transition-all flex flex-col text-gray-800",
          isSidebarOpen ? "min-w-64 w-64" : "min-w-14 w-14",
        )}
      >
        <div className="flex items-center mb-4 border-b pb-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsSidebarOpen((prev) => !prev)}
            className="mr-2"
          >
            {isSidebarOpen ? (
              <PanelRightOpen className="h-5 w-5" />
            ) : (
              <PanelRightClose className="h-5 w-5" />
            )}
          </Button>
          {isSidebarOpen && (
            <h2 className="text-lg font-semibold truncate">Performances</h2>
          )}
        </div>

        <nav className="flex-1 overflow-y-auto">
          {filteredNavItems.map((item) => {
            // If on mobile and the item has children => skip for simplicity
            if (isMobile && "children" in item) {
              return null
            }

            const Icon = item.icon
            // Check active route
            const isMainActive =
              matchPath(
                `/admin/companies/:companyId/performances/${item.id}/*`,
                location.pathname,
              ) !== null

            // If item has NO children
            if (!("children" in item)) {
              return (
                <div key={item.id} className="mb-2">
                  <Link
                    to={`${item.id}${
                      franchiseParam !== undefined
                        ? `?franchise=${franchiseParam}`
                        : ""
                    }`}
                  >
                    <button
                      className={twJoin(
                        "w-full flex items-center gap-2 px-2 py-1 rounded",
                        isMainActive
                          ? "bg-black text-white"
                          : "hover:bg-gray-100",
                      )}
                    >
                      {Icon && <Icon className="size-5" />}
                      {isSidebarOpen && <span>{item.title}</span>}
                    </button>
                  </Link>
                </div>
              )
            }

            // Else item has children
            const isExpanded = expandedItem === item.id
            const anyChildActive = item.children?.some((child) =>
              matchPath(
                `/admin/companies/:companyId/performances/${item.id}/${child.id}`,
                location.pathname,
              ),
            )

            return (
              <div key={item.id} className="mb-2">
                {isSidebarOpen && (
                  <button
                    onClick={() => handleToggle(item.id)}
                    className={twJoin(
                      "w-full flex items-center justify-between gap-2 px-2 py-1 rounded",
                      anyChildActive && !isExpanded
                        ? "bg-black text-white"
                        : "hover:bg-gray-100",
                    )}
                  >
                    <div className="flex items-center justify-start text-start gap-2">
                      {Icon && <Icon className="size-5" />}
                      {item.title}
                    </div>
                    {isExpanded ? (
                      <ChevronDown className="size-5" />
                    ) : (
                      <ChevronRight className="size-5" />
                    )}
                  </button>
                )}

                {(isExpanded || !isSidebarOpen) && (
                  <div
                    className={twJoin(
                      "mt-1 flex flex-col gap-1",
                      isSidebarOpen && "ml-6",
                    )}
                  >
                    {item.children?.map((child) => {
                      if (
                        child.permission === "isCompanyAdmin" &&
                        !isCompanyAdmin
                      ) {
                        return null
                      }
                      if (child.permission === "isIdaAdmin" && !isIdaAdmin) {
                        return null
                      }

                      const childActive =
                        matchPath(
                          `/admin/companies/:companyId/performances/${item.id}/${child.id}`,
                          location.pathname,
                        ) !== null
                      const ChildIcon = child.icon

                      return (
                        <Link
                          key={child.id}
                          to={`${item.id}/${child.id}${
                            franchiseParam !== undefined
                              ? `?franchise=${franchiseParam}`
                              : ""
                          }`}
                          className={twJoin(
                            "w-full text-left px-2 py-1 rounded text-sm flex items-center gap-2",
                            childActive
                              ? "bg-black text-white"
                              : "hover:bg-gray-100",
                          )}
                        >
                          {ChildIcon && <ChildIcon className="size-5" />}
                          {isSidebarOpen && <span>{child.title}</span>}
                        </Link>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </nav>
      </div>

      <div className="flex-1 flex flex-col w-[calc(100%-256px)]">
        <div className="flex gap-2 px-2 py-3 w-fit">
          <StoreSelector
            stores={stores}
            selectedStoreId={selectedStoreId}
            onSelect={(storeId) => setSelectedStoreId(storeId)}
            disabled={selectedPage?.filters?.store !== true}
          />
          <DateSelector
            fromDate={context.fromDate}
            toDate={context.toDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchParams={setSearchParams}
            franchiseParam={franchiseParam}
            disabled={selectedPage?.filters?.dates !== true}
          />
        </div>

        {/* Provide context to child routes */}
        <Outlet context={context} />
      </div>
    </div>
  )
}
