import React from "react"
import { LegendProps } from "recharts"

interface CustomLegendProps extends LegendProps {
  activeKeys: string[]
}

const CustomLegend: React.FC<CustomLegendProps> = (props) => {
  const { payload } = props

  return (
    <div className="flex justify-center space-x-4">
      {payload?.map((entry, index) => (
        <div key={`item-${index}`} className="flex items-center space-x-1">
          <span
            className="inline-block w-4 h-4 rounded-full"
            style={{ backgroundColor: entry.color }}
          ></span>
          <span className="text-sm py-10 text-gray-700">{entry.value}</span>
        </div>
      ))}
    </div>
  )
}

export default CustomLegend
