import { useOutletContext } from "react-router-dom"
import { PerformancesByStore } from "../components/performance/PerformancesByStore"
import { PerformancesPageContext } from "../PerformancesPage"

export function GlobalPerformancesPage() {
  const { storeId, companyId, franchise, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  return (
    <div className="flex-1 overflow-y-auto">
      <PerformancesByStore
        storeId={storeId!}
        companyId={companyId!}
        franchise={franchise}
        fromDate={fromDate}
        toDate={toDate}
      />
    </div>
  )
}
