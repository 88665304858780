export const Checker = ({
  isCurrentStep,
  isPreviousStep,
  index,
}: {
  isCurrentStep: boolean
  isPreviousStep: boolean
  index: number
}) => {
  return (
    <div
      className={`flex min-w-5 h-5 md:min-w-7 md:h-7 items-center justify-center rounded-full transition-all duration-300 ${
        isPreviousStep
          ? "bg-black"
          : isCurrentStep
            ? "border-2 border-black"
            : "border-2 border-gray-400"
      }`}
    >
      {isPreviousStep ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-4 h-4 md:w-6 md:h-6 text-white"
        >
          <path
            d="M5 13l4 4L19 7"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="animate-draw-check"
          />
        </svg>
      ) : (
        <p
          className={`text-sm md:text-lg transition-colors duration-300 ${
            isCurrentStep ? "text-black font-bold" : "text-gray-400"
          }`}
        >
          {index}
        </p>
      )}
    </div>
  )
}
