// components/EditOrdersReferenceDialog.tsx

"use client"

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { useState, useEffect, FC, Dispatch, SetStateAction } from "react"
import {
  useCreateRegroupingOrdersIdaMutation,
  CreateRegroupingOrdersIdaMutationVariables,
} from "@/utils/__generated__/graphql"
import { ReferenceWithRegroupingOrdersIdaRecords } from "@/utils/__generated__/graphql"
import { toast } from "sonner"

interface EditOrdersReferenceDialogProps {
  isOpen: boolean
  onClose: () => void
  record: ReferenceWithRegroupingOrdersIdaRecords
  refetch: () => void
  companyId: string
  franchiseParam: string
  storeId: string
  setReferences: Dispatch<
    SetStateAction<ReferenceWithRegroupingOrdersIdaRecords[]>
  >
}

const EditOrdersReferenceDialog: FC<EditOrdersReferenceDialogProps> = ({
  isOpen,
  onClose,
  record,
  refetch,
  companyId,
  franchiseParam,
  storeId,
  setReferences,
}) => {
  const [unit, setUnit] = useState<string>(record.unit ?? "piece")
  const [colisage, setColisage] = useState<string>(
    record.colisage !== null && record.colisage !== undefined
      ? record.colisage.toString()
      : "",
  )

  const [createRegroupingOrdersIda, { loading }] =
    useCreateRegroupingOrdersIdaMutation()

  useEffect(() => {
    if (!isOpen) {
      // Reset fields when dialog is closed
      setUnit(record.unit ?? "piece")
      setColisage(
        record.colisage !== null && record.colisage !== undefined
          ? record.colisage.toString()
          : "",
      )
    }
  }, [isOpen, record])

  const handleSubmit = async () => {
    // Client-side validation
    if (!["kilo", "piece"].includes(unit)) {
      toast.error("Unité invalide. Veuillez sélectionner 'kilo' ou 'pièce'.")
      return
    }

    if (colisage) {
      const colisageValue = parseFloat(colisage)
      if (isNaN(colisageValue) || colisageValue < 0) {
        toast.error("Le colisage doit être un nombre positif.")
        return
      }
    }

    // Prepare input for the mutation
    const input: CreateRegroupingOrdersIdaMutationVariables["input"] = {
      order_code: record.order_code,
      supplier_name: record.supplier_name,
      company_id: companyId,
      franchise_name: franchiseParam,
      sale_name_ida_base: record.sale_name_ida_base,
      libelle_base: record.libelle_base,
      libelle_cible: record.libelle_cible ?? "",
      sale_name_ida_cible: record.sale_name_ida_cible ?? "",
      order_name: record.order_name || "",
      unit: unit,
      store_id: storeId,
      colisage: colisage ? parseFloat(colisage) : null,
    }

    try {
      const response = await createRegroupingOrdersIda({
        variables: { input },
      })

      if (
        response.data?.createRegroupingOrdersIda.error ??
        !response.data?.createRegroupingOrdersIda.records
      ) {
        const errorMessage =
          response.data?.createRegroupingOrdersIda.error?.message ??
          "Échec de la mise à jour."
        toast.error(errorMessage)
      } else {
        toast.success("Mise à jour réussie.")
        setReferences((prev) =>
          prev.map((r) =>
            r.sale_name_ida_base === record.sale_name_ida_base &&
            r.order_code === record.order_code &&
            r.supplier_name === record.supplier_name
              ? {
                  ...r,
                  unit: unit,
                  colisage: parseFloat(colisage),
                }
              : r,
          ),
        )
        refetch() // Refresh data
        onClose() // Close the dialog
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de la mise à jour.")
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Modifier Référence</DialogTitle>
          <DialogDescription>
            Mettez à jour l&apos;unité et le colisage de la référence
            sélectionnée.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Unité
            </label>
            <Select value={unit} onValueChange={setUnit}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionnez une unité" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="kilo">Kilo</SelectItem>
                <SelectItem value="piece">Pièce</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {/* Colisage Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Colisage
            </label>
            <Input
              type="number"
              value={colisage}
              onChange={(e) => setColisage(e.target.value)}
              placeholder="Entrez le colisage"
              min="0"
              step="0.1"
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={loading}>
            Annuler
          </Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? "Enregistrement..." : "Enregistrer"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditOrdersReferenceDialog
