import { PropsWithChildren, ReactNode } from "react"
import { Card, CardContent } from "../ui/card"

export const IventorySupplierRowLayout = ({
  mercurialeNameElement,
  supplierElement,
  packagingElement,
  children,
  referencesElement,
  promotionElement,
  boxElement,
  localToggleElement,
}: PropsWithChildren<{
  mercurialeNameElement: ReactNode
  supplierElement: ReactNode
  packagingElement: ReactNode
  referencesElement: ReactNode
  boxElement: ReactNode
  promotionElement: ReactNode
  localToggleElement: ReactNode
}>) => (
  <Card className="border-none rounded-lg shadow-none bg-slate-50">
    <CardContent className="p-1 md:p-2">
      <div className="flex justify-between">
        <div className="flex flex-col justify-between gap-1 mb-2">
          <div>
            {supplierElement && (
              <div className="capitalize text-base font-bold">
                {supplierElement}
              </div>
            )}
            <div className="flex gap-1 items-center">
              {mercurialeNameElement}
              {packagingElement}
            </div>
          </div>
          {referencesElement}
          {(promotionElement !== false || boxElement !== false) && (
            <div className="flex items-center gap-2 md:gap-4 font-medium">
              {boxElement}
              {promotionElement}
            </div>
          )}
        </div>
        {localToggleElement}
      </div>
      <div>{children}</div>
    </CardContent>
  </Card>
)
