import { twMerge } from "tailwind-merge"

export type SelectOptions<T extends string | number | boolean> = {
  label: string
  value: T
}[]

interface SelectProps<T extends string | number | boolean> {
  options: SelectOptions<T>
  value: T
  onChange: (value: T) => void
  className?: string
}

export function Select<T extends string | number | boolean>({
  options,
  value,
  onChange,
  className,
}: SelectProps<T>): JSX.Element {
  const mergedClassName = twMerge(
    "border-2 border-green-600 flex rounded-md overflow-x-hidden md:w-fit gap-1",
    className,
  )

  return (
    <div className={mergedClassName}>
      {options.map((option, i) => (
        <button
          key={i}
          type="button"
          className={`w-fit transition px-7 py-2 cursor-pointer text-xs md:text-sm ${value === option.value ? "bg-green-600 text-white font-bold" : "bg-white hover:bg-green-20"}`}
          onClick={() => {
            onChange(option.value)
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}
