"use client"

import { useOutletContext, Outlet } from "react-router-dom"
import { PerformancesPageContext } from "../PerformancesPage"

export function InventoryPerformancesPage() {
  const parentContext = useOutletContext<PerformancesPageContext>()

  return <Outlet context={parentContext} />
}
