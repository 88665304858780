"use client"

import { useMemo, useState } from "react"
import {
  useGetCaPredictionsQuery,
  useGetAllReferencesQuery,
} from "@/utils/__generated__/graphql"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { Combobox } from "@headlessui/react"
import { FiSearch } from "react-icons/fi"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { PerformancesPageContext } from "../PerformancesPage"
import { PredictedCaVsSalesChart } from "../components/predictedCaVsSales/PredictedCaVsSalesChart"

export function PredictedCaVsSalesPerformancesPage() {
  const { selectedStoreId, stores, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  const [selectedReferenceId, setSelectedReferenceId] = useState<
    string | undefined
  >(undefined)
  const [selectedDayNumber, setSelectedDayNumber] = useState<number>(0) // horizon day

  // For searching references
  const [searchTerm, setSearchTerm] = useState<string>("")

  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  // Get store name or fallback
  const StoreName = useMemo(() => {
    const store = stores?.find((store) => store.id === selectedStoreId)
    return store?.store_name ?? "Aucun magasin sélectionné"
  }, [selectedStoreId, stores])

  // Fetch CA predictions
  const { data, loading: caLoading } = useGetCaPredictionsQuery({
    variables: {
      input: {
        selected_store_id: selectedStoreId!,
        store_id: storeId!,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        sale_id: selectedReferenceId ?? undefined,
        day_number: selectedDayNumber,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !selectedStoreId, // optionally skip if store isn't selected
  })

  // Fetch all references
  const { data: referencesData, loading: referencesLoading } =
    useGetAllReferencesQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: storeId!,
          selected_store_id: selectedStoreId!,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
        },
      },
      fetchPolicy: "cache-and-network",
      skip: !selectedStoreId, // optionally skip if store isn't selected
    })

  // Filter references by search term
  const filteredReferences = useMemo(() => {
    if (!referencesData?.getAllReferences.references) return []
    const uniqueRefs = Array.from(
      new Set(referencesData.getAllReferences.references.map((ref) => ref.id)),
    )
      .map((id) =>
        referencesData.getAllReferences.references.find((ref) => ref.id === id),
      )
      .filter((ref): ref is NonNullable<typeof ref> => ref !== undefined)
    return uniqueRefs.filter((ref) =>
      ref.name.toLowerCase().includes(searchTerm.toLowerCase().trim()),
    )
  }, [referencesData, searchTerm])

  // CA Predictions data mapped to chart format
  const chartData = useMemo(() => {
    if (!data?.getCaPredictions.data) return []
    const processedData = data.getCaPredictions.data.filter(
      (item) =>
        typeof item.sales_actual === "number" &&
        item.predicted_ca > 0 &&
        typeof item.predicted_ca === "number",
    )

    const sortedCaPredictions = [...processedData].sort(
      (a, b) =>
        new Date(a.prediction_date).getTime() -
        new Date(b.prediction_date).getTime(),
    )

    return sortedCaPredictions.map((item) => ({
      date: item.prediction_date,
      predicted_ca: item.predicted_ca,
      sales_actual: item.sales_actual,
    }))
  }, [data?.getCaPredictions.data])

  // API Errors
  const apiError = data?.getCaPredictions.error
  const referencesErrorMessage = referencesData?.getAllReferences.error

  return (
    <div className="px-2">
      <Card>
        <CardHeader>
          <CardTitle>{StoreName}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row md:items-end md:space-x-6 mb-6">
            {/* 
              --------------------------------------------------------------
              Only show the reference selector if selectedStoreId is defined
              --------------------------------------------------------------
            */}
            <div className="flex-1">
              {selectedStoreId ? (
                referencesLoading ? (
                  <Skeleton className="w-full h-10 mb-4" />
                ) : referencesErrorMessage ? (
                  <p className="text-red-500">
                    Erreur: {referencesErrorMessage.message}
                  </p>
                ) : (
                  <Combobox
                    value={selectedReferenceId}
                    onChange={(value) =>
                      setSelectedReferenceId(
                        value === "all" ? undefined : (value ?? undefined),
                      )
                    }
                  >
                    <Label
                      htmlFor="reference-select"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Sélectionner une référence:
                    </Label>
                    <div className="relative">
                      <Combobox.Input
                        className="block w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                        onChange={(event) => setSearchTerm(event.target.value)}
                        displayValue={(selected: string | undefined) =>
                          filteredReferences.find((ref) => ref.id === selected)
                            ?.name ?? "Toutes les références"
                        }
                      />
                      <Combobox.Button className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FiSearch
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Option
                          value="all"
                          className={({ active }) => `
                            relative cursor-pointer select-none py-2 pl-10 pr-4 
                            ${active ? "bg-teal-600 text-white" : "text-gray-900"}
                          `}
                        >
                          {({ active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selectedReferenceId === undefined
                                    ? "font-medium"
                                    : "font-normal"
                                }`}
                              >
                                Toutes les références
                              </span>
                              {selectedReferenceId === undefined && (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-teal-600"
                                  }`}
                                />
                              )}
                            </>
                          )}
                        </Combobox.Option>
                        {filteredReferences.map((ref) => (
                          <Combobox.Option
                            key={ref.id}
                            value={ref.id}
                            className={({ active }) => `
                              relative cursor-pointer select-none py-2 pl-10 pr-4 
                              ${
                                active
                                  ? "bg-teal-600 text-white"
                                  : "text-gray-900"
                              }
                            `}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {ref.name}
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? "text-white" : "text-teal-600"
                                    }`}
                                  />
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </div>
                  </Combobox>
                )
              ) : null}
            </div>

            {/* Duration Selector (J0-J6) */}
            <div className="flex-1">
              {selectedStoreId && (
                <>
                  <Label htmlFor="duration-select" className="block mb-1">
                    Sélectionner l&apos;horizon de prédiction:
                  </Label>

                  {caLoading ? (
                    <Skeleton className="w-44 h-10" />
                  ) : (
                    <Select
                      onValueChange={(value) =>
                        setSelectedDayNumber(parseInt(value, 10))
                      }
                      value={selectedDayNumber.toString()}
                    >
                      <SelectTrigger className="w-44">
                        <SelectValue placeholder="J0" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="0"> à J1</SelectItem>
                        <SelectItem value="1"> à J2</SelectItem>
                        <SelectItem value="2"> à J3</SelectItem>
                        <SelectItem value="3"> à J4</SelectItem>
                        <SelectItem value="4"> à J5</SelectItem>
                        <SelectItem value="5"> à J6</SelectItem>
                        <SelectItem value="6"> à J7</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                </>
              )}
            </div>
          </div>

          {caLoading && (
            <div className="w-full aspect-video">
              <Skeleton className="w-full h-full" />
            </div>
          )}

          {!caLoading && (
            <>
              {apiError && (
                <p className="text-red-500">Erreur: {apiError.message}</p>
              )}
              {!apiError && chartData.length === 0 && (
                <p className="text-muted-foreground">
                  Veuillez sélectionner un magasin pour afficher les prédictions
                  de chiffres d&apos;affaires vs les ventes.
                </p>
              )}
              {!apiError && chartData.length > 0 && (
                <PredictedCaVsSalesChart data={chartData} />
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
