import { Category } from "@/utils/__generated__/graphql"
import { compact } from "lodash"
import { SortSectionOption } from "@/components/inventory/nav/ScrollToSectionDropdown"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { Predicate, reduceInSections } from "@/utils/array"
import { computeHistoricalRevenue } from "@/utils/revenue"

export type SortSectionMapper = (params: {
  inventory: Partial<AllMercurialInfo>[]
  unfilteredInventory: Partial<AllMercurialInfo>[]
  bestSellers: Partial<AllMercurialInfo>[]
  hasPromoAndNewReferencesFirst?: boolean
  categoriesOrder?: Category[] | null
}) => SortSectionOption[]

const findIndexAndCount = <T>(array: T[], predicate: (item: T) => boolean) => ({
  index: array.findIndex(predicate),
  count: array.filter(predicate).length,
})

export const generateCategorySections: SortSectionMapper = ({
  categoriesOrder,
  inventory,
}) =>
  compact(
    categoriesOrder?.map(({ name }) => {
      const { index, count } = findIndexAndCount(
        inventory,
        ({ family_name }) => (family_name ?? "Autres") === name,
      )
      const category = inventory.find(
        ({ family_name }) => (family_name ?? "Autres") === name,
      )

      return (
        category && {
          index,
          count,
          label: name,
        }
      )
    }),
  )

export const generateAlphabeticalSections: SortSectionMapper = ({
  inventory,
}) =>
  [..."#abcdefghijklmnopqrstuvwxyz"].map((letter) => {
    if (letter === "#") {
      const hasSpecialCharacters = inventory.some(
        (item) => !/[a-z]/.test((item.mercuriale_name ?? "")[0].toLowerCase()),
      )
      return {
        index: hasSpecialCharacters ? 0 : -1,
        label: "#",
      }
    }

    return {
      // no count here to not clutter UI
      index: inventory.findIndex(
        (item) => (item.mercuriale_name ?? "")[0].toLowerCase() === letter,
      ),
      label: letter.toUpperCase(),
    }
  })

export const generateBreakageSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(
      inventory,
      ({ breakage_percentage }) => (breakage_percentage ?? 0) > 10,
    ),
    label: "Pires démarques",
  },
  {
    ...findIndexAndCount(
      inventory,
      ({ breakage_percentage }) => (breakage_percentage ?? 0) <= 10,
    ),
    label: "Sans tri",
  },
]

export const generateNoBreakageSections: SortSectionMapper = ({
  inventory,
}) => [
  {
    ...findIndexAndCount(inventory, (item) => item.pas_de_demarque === true),
    label: "Attention à la démarque",
  },
]

export const generateNoRuptureSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(inventory, (item) => item.pas_de_rupture === true),
    label: "Attention à la rupture",
  },
]

export const generatePromotionSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(inventory, ({ promotion }) => !!promotion),
    label: "Promotion",
  },
  {
    ...findIndexAndCount(inventory, ({ promotion }) => !promotion),
    label: "Sans promotion",
  },
]

const extractInventoryItemId = (item?: Partial<AllMercurialInfo>) =>
  item?.mercuriale_id ?? undefined

type WithCumulativePercentage = Partial<AllMercurialInfo> & {
  cumulativeRevenuePercentage?: number
  revenue?: number
}

const addCumulativeRevenuePercentage = (
  inventory: Partial<AllMercurialInfo>[],
) => {
  let cumulativeRevenuePercentage = 0

  const inventoryWithRevenue = inventory.map((item) => ({
    ...item,
    revenue: computeHistoricalRevenue(item),
  }))

  const totalRevenue = inventoryWithRevenue.reduce(
    (total, item) => total + item.revenue,
    0,
  )

  return inventoryWithRevenue.map((item) => {
    const revenuePercentage = (item.revenue / totalRevenue) * 100
    cumulativeRevenuePercentage += revenuePercentage

    return {
      ...item,
      cumulativeRevenuePercentage,
    }
  })
}

export const generateBestSellerSections: SortSectionMapper = ({
  unfilteredInventory,
  inventory,
  hasPromoAndNewReferencesFirst,
  bestSellers,
}) => {
  const unfilteredInventoryWithRevenue =
    addCumulativeRevenuePercentage(unfilteredInventory)

  // reconcile filtered items with their cumulative revenue on the whole store
  const inventoryWithRevenue = inventory.map((item) => {
    const storeItem = unfilteredInventoryWithRevenue.find(
      ({ mercuriale_id }) => mercuriale_id === item.mercuriale_id,
    )
    return {
      ...item,
      revenue: storeItem?.revenue,
      cumulativeRevenuePercentage: storeItem?.cumulativeRevenuePercentage,
    }
  })

  const promoAndReferencesPredicates: Predicate<WithCumulativePercentage>[] = [
    {
      predicate: (item) => !!item.promotion,
      label: "Promotions",
    },
    {
      predicate: (item) => !!item.new_reference,
      label: "Nouveaux produits",
    },
  ]

  const predicates: Predicate<WithCumulativePercentage>[] = [
    {
      predicate: (item) =>
        bestSellers.some(
          ({ mercuriale_id }) => mercuriale_id === item.mercuriale_id,
        ),
      label: "Meilleures ventes",
    },
    {
      predicate: (item: WithCumulativePercentage) =>
        !!item.revenue &&
        !!item.cumulativeRevenuePercentage &&
        item.cumulativeRevenuePercentage < 50,
      label: "20 → 50% du CA",
    },
    {
      predicate: (item: WithCumulativePercentage) =>
        !!item.revenue &&
        !!item.cumulativeRevenuePercentage &&
        item.cumulativeRevenuePercentage < 75,
      label: "50 → 75% du CA",
    },
    {
      predicate: (item: WithCumulativePercentage) =>
        (!!item.revenue &&
          !!item.cumulativeRevenuePercentage &&
          item.cumulativeRevenuePercentage <= 100) ||
        computeHistoricalRevenue(item) > 0,
      label: "75 → 100% du CA",
    },
    {
      predicate: (item: WithCumulativePercentage) => {
        const quantityReceptions =
          item.delivery_historical_quantities?.reduce(
            (acc, curr) => acc + (curr.quantity ?? 0) / (item.colisage ?? 1),
            0,
          ) ?? 0

        return quantityReceptions > 0
      },
      label: "Livrés, non vendus",
    },
    {
      predicate: () => true,
      label: "Reste",
    },
  ]

  return reduceInSections(
    inventoryWithRevenue,
    hasPromoAndNewReferencesFirst
      ? [...promoAndReferencesPredicates, ...predicates]
      : predicates,
    extractInventoryItemId,
  )
}
