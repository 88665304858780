import { useState } from "react"
import { RefreshCw } from "lucide-react"
import { Button } from "@/components/ui/button"
import { toast } from "sonner"
import {
  useUpdateOrderStatusMutation,
  MercurialAdditionalInfoDocument,
} from "@/utils/__generated__/graphql"
import { FC } from "react"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useOutletContext } from "react-router-dom"
import { InventoryContext } from "@/components/inventory/InventoryRoot"

const UpdateOrderStatusButton: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const todayDate = new Date().toISOString().split("T")[0]

  const [updateOrderStatus] = useUpdateOrderStatusMutation()

  // Accéder au contexte fourni par InventoryRoot
  const context = useOutletContext<InventoryContext>()

  if (!context) {
    // Gérer le cas où le contexte n'est pas disponible
    console.error("InventoryContext is not available")
    return null // Ou afficher un message d'erreur approprié
  }

  const [, getLatestInfos] = context

  const handleToggle = async () => {
    setIsLoading(true)

    try {
      const response = await updateOrderStatus({
        variables: {
          input: {
            store_id: storeId!,
            order_request_date: todayDate,
          },
        },
        refetchQueries: [
          {
            query: MercurialAdditionalInfoDocument,
            variables: { input: { store_id: storeId! } },
          },
        ],
        awaitRefetchQueries: true,
      })

      if (response.data?.updateOrderStatus.error) {
        const error = response.data.updateOrderStatus.error
        let errorMessage = "Une erreur est survenue."

        switch (error.__typename) {
          case "InternalSendOrderServiceError":
            errorMessage = `Erreur interne : ${error.message}`
            break
          case "ExternalSendOrderServiceError":
            errorMessage = `Erreur externe : ${error.message}`
            break
          default:
            errorMessage = "Erreur inconnue."
        }

        toast.error(errorMessage)
      } else {
        toast.success("Statut des commandes mis à jour avec succès !")
        toast.info("Pensez à le remettre dans l'état où vous l'avez trouvé.")

        await getLatestInfos()
      }
    } catch (err) {
      console.error(err)
      toast.error("Une erreur inattendue est survenue.")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      variant="default"
      size="sm"
      onClick={handleToggle}
      disabled={isLoading}
      className="flex items-center gap-2"
      title="Mettre à jour le statut des commandes"
      aria-label="Mettre à jour le statut des commandes"
    >
      {isLoading ? (
        "Chargement..."
      ) : (
        <>
          <RefreshCw className="h-4 w-4" />
          <p className="hidden sm:block">Modifier le statut de la commande</p>
        </>
      )}
    </Button>
  )
}

export default UpdateOrderStatusButton
