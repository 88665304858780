import { StateType } from "@/types"
import { InventoryType } from "@/utils/__generated__/graphql"
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react"
import { useSelector } from "react-redux"

interface InventoryContextType {
  lastInputSelected: {
    type?: InventoryType
    saleId?: string
    mercurialeId?: string
    index?: number
    saleIdIndex?: number
  }
  setLastInputSelected: (value: {
    type?: InventoryType
    saleId?: string
    mercurialeId?: string
    index?: number
    saleIdIndex?: number
  }) => void
}

const InventoryContext = createContext<InventoryContextType | undefined>(
  undefined,
)

export const InventoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const [lastInputSelected, setLastInputSelected] = useState<
    InventoryContextType["lastInputSelected"]
  >({
    type: selectedInventory?.type ?? InventoryType.Floor,
    saleId: "",
    mercurialeId: selectedInventory?.mercurialeId ?? "",
    index: 0,
    saleIdIndex: 0,
  })

  const inventoryValues = {
    lastInputSelected,
    setLastInputSelected,
  }

  useEffect(() => {
    if (selectedInventory !== undefined) return
    setLastInputSelected({
      type: undefined,
      saleId: undefined,
      mercurialeId: undefined,
      index: undefined,
      saleIdIndex: undefined,
    })
  }, [selectedInventory])

  return (
    <InventoryContext.Provider value={inventoryValues}>
      {children}
    </InventoryContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useInventoryContext = (): InventoryContextType => {
  const context = useContext(InventoryContext)
  if (!context) {
    throw new Error(
      "useInventoryContext must be used within an InventoryProvider",
    )
  }
  return context
}
