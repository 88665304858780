// PotentiallyRuptures.tsx

import { Skeleton } from "@/components/ui/skeleton"
import { InventoryRow } from "@/components/inventory/InventoryRow"
import { CheckinSideBar } from "@/pages/checkinPage/components/checkinSideBar"
import { Clock, TrendingDownIcon } from "lucide-react"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useMemo } from "react"

interface PotentiallyRupturesProps {
  gotToNextStep: () => void
  currentStep: number
  totalSteps: number
  closeCheckinModal: () => void
  updateInventory: (value: string) => Promise<void>
  potentialRupturesData: AllMercurialInfo[]
  allReferences?: AllMercurialInfo[] // optional
}

function PotentiallyRuptures({
  gotToNextStep,
  currentStep,
  totalSteps,
  closeCheckinModal,
  updateInventory,
  potentialRupturesData,
  allReferences = [],
}: PotentiallyRupturesProps) {
  const {
    storeId,
    storeFranchise,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
    companyName,
  } = useSelector((state: StateType) => state.storeReducer)

  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.inventoryPage.displayShelfFloorSize,
  )

  const referencesToDisplay = useMemo(() => {
    const data =
      potentialRupturesData.length > 0 ? potentialRupturesData : allReferences
    return [...data].sort(
      (a, b) => (b.rupture_loss_amount ?? 0) - (a.rupture_loss_amount ?? 0),
    )
  }, [potentialRupturesData, allReferences]).slice(0, 5)

  const totalLoss = referencesToDisplay.reduce((acc, ref) => {
    return acc + (ref.rupture_loss_amount ?? 0)
  }, 0)

  const isLoading = referencesToDisplay.length === 0

  return (
    <div className="flex flex-row gap-6 mb-20">
      <div className="flex flex-col gap-2 px-1 w-full">
        {isLoading && (
          <div className="text-center p-4 font-bold text-gray-600">
            Chargement...
          </div>
        )}

        <div className="p-4 border-2 border-gray-200 rounded-lg bg-white">
          <div className="px-4 py-5 rounded-lg bg-red-600">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <TrendingDownIcon className="w-6 h-6 text-white" />
                <p className="font-bold text-lg text-white">Perte de revenus</p>
              </div>
              <div className="text-3xl font-bold text-white">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(totalLoss)}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full mt-6">
          <h2 className="font-bold text-lg text-gray-800">
            {potentialRupturesData.length > 0
              ? "Liste des potentielles ruptures"
              : "Aucune rupture détectée, voici la liste complète"}
          </h2>

          {isLoading ? (
            <div className="flex flex-col gap-2">
              {[...Array(3)].map((_, idx) => (
                <Skeleton key={idx} className="w-full h-20" />
              ))}
            </div>
          ) : (
            referencesToDisplay.map((reference, index) => {
              let formattedLastSaleTime = "—"
              if (reference.last_sale_time) {
                try {
                  formattedLastSaleTime = new Intl.DateTimeFormat("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(new Date(reference.last_sale_time))
                } catch (err) {
                  formattedLastSaleTime = "—"
                }
              }

              const referenceLoss = reference.rupture_loss_amount ?? 0
              const formattedReferenceLoss = new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(referenceLoss)

              return (
                <div
                  key={reference.mercuriale_id ?? index}
                  className="border border-gray-200 rounded-lg p-4 bg-white"
                >
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex flex-col">
                      <span className="flex items-center text-xs font-medium text-gray-500 uppercase">
                        Heure dernière vente
                      </span>
                      <span className="flex items-center text-xl font-bold text-red-600 underline">
                        <Clock className="w-4 h-4 mr-1" />
                        {formattedLastSaleTime}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 bg-red-600 text-white font-bold px-3 py-1 rounded-full">
                      <TrendingDownIcon className="w-4 h-4" />
                      <span>Perte: {formattedReferenceLoss}</span>
                    </div>
                  </div>

                  <div id={`${reference.mercuriale_id}`}>
                    <InventoryRow
                      index={index}
                      storeId={storeId}
                      bestSellers={[]}
                      selectedInventory={selectedInventory}
                      isOnline={isOnline}
                      storeSettings={storeSettings}
                      updateInventory={updateInventory}
                      displayShelfFloorSize={displayShelfFloorSize}
                      storeSuppliers={storeSuppliers}
                      storeCurrency={storeCurrency}
                      companyId={companyId}
                      references={[reference]}
                      companyName={companyName}
                      franchise={storeFranchise}
                      isInCheckinPage={true}
                      className=""
                    />
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>

      {/* Right column: the sidebar */}
      <CheckinSideBar
        gotToNextStep={gotToNextStep}
        currentStep={currentStep}
        totalSteps={totalSteps}
        closeCheckinModal={closeCheckinModal}
        updateInventory={updateInventory}
        mercurialeInfos={referencesToDisplay}
      />
    </div>
  )
}

export default PotentiallyRuptures
