"use client"

import { useMemo } from "react"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { add } from "date-fns"
import { useSelector } from "react-redux"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
  LabelList,
} from "recharts"

import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { StateType } from "@/types"
import { useGetInventoriesInfoQuery } from "@/utils/__generated__/graphql"
import Skeleton from "react-loading-skeleton"

import { PerformancesPageContext } from "../../PerformancesPage"

interface InventoryRecord {
  sale_id?: string
  sale_name?: string
  sale_name_ida?: string
  store_name?: string
  store_id?: string
  inventory_date?: string
  floor_inventory_qty?: number | null
  back_inventory_qty?: number | null
  total_inventory_qty?: number | null
}

// ------------------------------------------
// Color Mapping
// ------------------------------------------
const storeColorMap: Record<string, string> = {}
const colorPalette = [
  "#3b82f6",
  "#ef4444",
  "#f97316",
  "#10b981",
  "#8b5cf6",
  "#ec4899",
  "#eab308",
  "#14b8a6",
]
function getStoreColor(storeName: string) {
  if (!storeColorMap[storeName]) {
    const idx = Object.keys(storeColorMap).length % colorPalette.length
    storeColorMap[storeName] = colorPalette[idx]
  }
  return storeColorMap[storeName]
}

// ------------------------------------------
// Main Component
// ------------------------------------------
export default function InventoriesAdmin() {
  const { selectedStoreId, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  // Default date range if none is provided
  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined

  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  // ------------------------------------------
  // GraphQL Query
  // ------------------------------------------
  const { data, loading, error } = useGetInventoriesInfoQuery({
    variables: {
      input: {
        company_id: companyId ?? "",
        franchise_name: franchiseParam,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formatDateToYYYYMMDD(fromDate ?? defaultStartDate),
        end_date: formatDateToYYYYMMDD(toDate ?? defaultEndDate),
        store_id: storeId!,
      },
    },
    fetchPolicy: "cache-and-network",
  })

  // ------------------------------------------
  // Data Transform
  // ------------------------------------------
  const rawRecords: InventoryRecord[] = useMemo(() => {
    return data?.getInventoriesInfo?.inventory_info ?? []
  }, [data?.getInventoriesInfo?.inventory_info])

  // Round inventory values
  const inventoryRecords = useMemo<InventoryRecord[]>(() => {
    return rawRecords.map((rec) => ({
      ...rec,
      floor_inventory_qty: rec.floor_inventory_qty
        ? parseFloat(rec.floor_inventory_qty.toFixed(1))
        : 0,
      back_inventory_qty: rec.back_inventory_qty
        ? parseFloat(rec.back_inventory_qty.toFixed(1))
        : 0,
      total_inventory_qty: rec.total_inventory_qty
        ? parseFloat(rec.total_inventory_qty.toFixed(1))
        : 0,
    }))
  }, [rawRecords])

  // Group by day + store
  const groupedData = useMemo(() => {
    const map: Record<string, Record<string, number>> = {}
    for (const rec of inventoryRecords) {
      if (!rec.inventory_date) continue
      const dayStr = new Date(rec.inventory_date).toISOString().slice(0, 10)
      const store = rec.store_name ?? "—"

      if (!map[dayStr]) map[dayStr] = {}
      if (!map[dayStr][store]) map[dayStr][store] = 0

      map[dayStr][store] += rec.total_inventory_qty ?? 0
    }
    return map
  }, [inventoryRecords])

  // Collect all store names
  const allStores = useMemo(() => {
    const s = new Set<string>()
    for (const day in groupedData) {
      Object.keys(groupedData[day]).forEach((store) => s.add(store))
    }
    return Array.from(s)
  }, [groupedData])

  // Prepare data for Recharts
  const chartData = useMemo(() => {
    const daysSorted = Object.keys(groupedData).sort()
    return daysSorted.map((day) => {
      const row: Record<string, string | number> = { day }
      let sum = 0
      allStores.forEach((store) => {
        const val = groupedData[day][store] || 0
        const roundedVal = parseFloat(val.toFixed(1))
        row[store] = roundedVal
        sum += roundedVal
      })
      row.sum = parseFloat(sum.toFixed(1))
      return row
    })
  }, [groupedData, allStores])

  // Average stock per day
  const avgStockPerDate = useMemo(() => {
    if (!inventoryRecords.length) return 0
    const totalStock = inventoryRecords.reduce(
      (acc, rec) => acc + (rec.total_inventory_qty ?? 0),
      0,
    )
    const uniqueDates = new Set(
      inventoryRecords.map((rec) => rec.inventory_date),
    )
    if (uniqueDates.size === 0) return 0
    const rawAvg = totalStock / uniqueDates.size
    return parseFloat(rawAvg.toFixed(1))
  }, [inventoryRecords])

  // ------------------------------------------
  // Render
  // ------------------------------------------
  const isEmpty = chartData.length === 0

  if (loading) {
    return (
      <div className="p-4 w-full max-w-[1200px] mx-auto space-y-4">
        <Card>
          <CardHeader className="flex items-center justify-between">
            <Skeleton className="h-4 w-44" />
            <Skeleton className="h-4 w-32" />
          </CardHeader>
          <CardContent>
            <Skeleton className="h-[350px] w-full" />
          </CardContent>
        </Card>
      </div>
    )
  }
  if (error) {
    return <div className="text-red-500 p-4">Erreur lors du chargement.</div>
  }

  return (
    <div className="p-4 w-full max-w-[1200px] mx-auto space-y-4">
      {/* If not empty, show chart */}
      {!isEmpty && (
        <Card>
          <CardHeader className="flex items-center justify-between">
            {/* Badge */}
            <span className="inline-block bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-bold">
              Stock moyen par jour en pv:{" "}
              {avgStockPerDate.toLocaleString("fr-FR", {
                minimumFractionDigits: 1,
              })}
            </span>
            <CardTitle className="text-md">
              Inventaires par jour / magasin
            </CardTitle>
          </CardHeader>

          <CardContent>
            <div className="w-full" style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={chartData}
                  margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  <XAxis dataKey="day" />
                  <YAxis />

                  {/* Tooltip with € */}
                  <Tooltip
                    formatter={(value: number | string, name: string) => [
                      `${value} €`,
                      name,
                    ]}
                  />

                  <Legend />

                  {/* Stacked Bars */}
                  {allStores.map((store) => (
                    <Bar
                      key={store}
                      dataKey={store}
                      stackId="inventory"
                      fill={getStoreColor(store)}
                      name={store}
                    >
                      {allStores.length == 1 && (
                        <LabelList
                          dataKey={store}
                          position="top"
                          offset={10} // Adjust as needed
                          formatter={(val: number | string) => `${val} €`}
                          style={{
                            fill: "#000",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        />
                      )}
                    </Bar>
                  ))}
                  <Line
                    type="monotone"
                    dataKey="sum"
                    stroke="#111"
                    strokeWidth={3}
                    dot={{ r: 3 }}
                    activeDot={{ r: 5 }}
                    name="Total jour"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
