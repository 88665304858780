import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Store } from "@/utils/__generated__/graphql"

interface StoreSelectorProps {
  stores: Store[]
  selectedStoreId: string | null
  onSelect: (storeId: string | null) => void
  disabled?: boolean
}

export function StoreSelector({
  stores,
  selectedStoreId,
  onSelect,
  disabled = false,
}: StoreSelectorProps) {
  const handleValueChange = (value: string) => {
    if (value === selectedStoreId) {
      // Unselect the store if the same one is clicked
      onSelect(null)
    } else if (value === "all") {
      // Unselect the store when "All Stores" is selected
      onSelect(null)
    } else {
      onSelect(value)
    }
  }

  return (
    <Select
      value={selectedStoreId ?? "all"}
      onValueChange={handleValueChange}
      disabled={disabled}
    >
      <SelectTrigger className="w-44">
        <SelectValue placeholder="Sélectionner un magasin" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem key="all" value="all">
          Tous les magasins
        </SelectItem>
        {stores.map((store) => (
          <SelectItem key={store.id} value={store.id}>
            {store.store_name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
