import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import MinimalLogo from "../../assets/MinimalLogo"
import { WifiIcon } from "@heroicons/react/24/outline"
import {
  removeDuplicates,
  removeDuplicatesValues,
} from "../../utils/removeDuplicates"
import { type DispatchActionType, type StateType } from "../../types"
import { Link } from "react-router-dom"
import { Spinner } from "../../ui/Spinner"
import { type PageName } from "../../reducers/userInterfaceReducer"
import { filteredMercurialeReducerSelector } from "../../selectors/mercurialeSelectors"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { usePermissions } from "../../hooks/usePermissions"
import { CategoryBreadcrumb } from "@/components/inventory/CategoryBreadcrumb"
import { InventoryNavbarLayout } from "@/components/inventory/nav/InventoryNavbarLayout"
import { MercurialeDateSelection } from "@/components/inventory/nav/MercurialeDateSelection"
import { Badge } from "@/components/ui/badge"
import { upperFirst } from "lodash"
import { SupplierFrancoAmount } from "@/components/inventory/nav/SupplierFrancoAmount"
import UpdateOrderStatusButton from "./cancelOrder/UpdateOrderStatusButton"
import { useFetchOrderStatus } from "./useFetchOrderStatus"

interface InventoryNavProps {
  onCategoryChange: () => void
  isLoading: boolean
  isMercurialeLoading: boolean
  page: PageName
  onRefreshButtonClick: () => void
  setSaveDataModalState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      backToHome: boolean
    }>
  >
}

const InventoryNav = ({
  onCategoryChange,
  isLoading,
  isMercurialeLoading,
  page,
  setSaveDataModalState,
}: InventoryNavProps): JSX.Element => {
  const dispatch = useDispatch<DispatchActionType>()

  const {
    mercurialAndStoreInventories,
    selectedDimMercurialeId,
    dimMercuriales,
  } = useSelector(filteredMercurialeReducerSelector)
  const { enable: isTestMode } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const { isReadOnly, isIdaAdmin } = usePermissions()

  const {
    storeSettings,
    storeSuppliers,
    companyId,
    storeStoreSuppliers,
    storeCurrency,
    storeFranchise,
    storeId,
  } = useSelector((state: StateType) => state.storeReducer)
  const { suppliersIds, subFamilyName, familyName, filteredReferences } =
    useSelector((state: StateType) => state.userInterfaceReducer.inventoryPage)
  const { online, dataSynchronizationStatus } = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const weekday = new Intl.DateTimeFormat("fr-FR", { weekday: "long" }).format(
    new Date(),
  )

  const suppliers = useMemo(() => {
    if (storeSuppliers === null) return []
    const deduplicateSuppliers = removeDuplicatesValues(storeSuppliers, "id")
    const mercurialeInfos = mercurialAndStoreInventories.filter(
      (mercurialInfo) =>
        filteredReferences === undefined ||
        filteredReferences.length === 0 ||
        filteredReferences.includes(mercurialInfo.mercuriale_id ?? ""),
    )

    const existingInInventoriesSuppliers = deduplicateSuppliers.filter(
      (supplier) =>
        mercurialeInfos.some((item) => item.supplier_id === supplier.id),
    )

    return existingInInventoriesSuppliers.map((supplier) => {
      const minimalOrderValue =
        storeStoreSuppliers?.find(
          (storeStoreSupplier) =>
            storeStoreSupplier.supplier_id === supplier.id &&
            storeStoreSupplier.order_day === weekday,
        )?.minimal_order_value ?? undefined

      return {
        label: supplier.supplier_name,
        value: supplier.id,
        extra: (
          <SupplierFrancoAmount
            storeCurrency={storeCurrency}
            storeSupplierId={supplier.id}
            minimalOrderValue={minimalOrderValue}
          />
        ),
      }
    })
  }, [
    filteredReferences,
    mercurialAndStoreInventories,
    storeCurrency,
    storeStoreSuppliers,
    storeSuppliers,
    weekday,
  ])

  const categories = useMemo(() => {
    return (
      mercurialAndStoreInventories?.reduce<Record<string, string[]>>(
        (categories, mercurialInfo) => {
          if (
            filteredReferences !== undefined &&
            filteredReferences.length > 0 &&
            !filteredReferences.includes(mercurialInfo.mercuriale_id ?? "")
          ) {
            return categories
          }
          const familyName =
            mercurialInfo?.family_name?.toLowerCase().trim() ?? "autres"
          const subFamilyName = mercurialInfo?.sub_family_name
            ?.toLowerCase()
            .trim()
          if (categories[familyName] === undefined) {
            categories[familyName] =
              typeof subFamilyName === "string" ? [subFamilyName] : []
            return categories
          }
          if (
            typeof subFamilyName === "string" &&
            !categories[familyName].includes(subFamilyName)
          ) {
            categories[familyName] = [...categories[familyName], subFamilyName]
          }
          return categories
        },
        {},
      ) ?? {}
    )
  }, [filteredReferences, mercurialAndStoreInventories])

  useEffect(() => {
    if (
      suppliers.length === 0 ||
      suppliersIds === undefined ||
      suppliers.some((supplier) => suppliersIds.includes(supplier.value))
    )
      return
    dispatch({
      type: "setFilters",
      payload: { page: "inventoryPage", supplierId: undefined },
    })
  }, [suppliersIds, suppliers, dispatch, page])

  useEffect(() => {
    if (
      Object.keys(categories).length === 0 ||
      familyName === undefined ||
      Object.keys(categories).includes(familyName)
    )
      return
    dispatch({
      type: "setFilters",
      payload: { page: "inventoryPage", familyName: undefined },
    })
  }, [familyName, categories, dispatch, page])

  useEffect(() => {
    if (
      familyName === undefined ||
      subFamilyName === undefined ||
      categories[familyName] === undefined ||
      Object.values(categories[familyName]) === undefined ||
      Object.values(categories[familyName]).length === 0 ||
      Object.values(categories[familyName]).includes(subFamilyName)
    ) {
      return
    }
    dispatch({
      type: "setFilters",
      payload: { page: "inventoryPage", subFamilyName: undefined },
    })
  }, [familyName, categories, dispatch, subFamilyName, page])

  useEffect(() => {
    if (Object.keys(categories).length !== 1 || familyName !== undefined) return
    dispatch({
      type: "setFilters",
      payload: {
        page: "inventoryPage",
        familyName: Object.keys(categories)[0],
      },
    })
  }, [familyName, categories, dispatch, page])

  const sortedCategories = useMemo(() => {
    const categoriesKeys = Object.keys(categories)
    const categoriesOrders =
      storeSettings?.categories_orders
        ?.map((category) => category.name)
        .filter(
          (category): category is string =>
            category !== undefined &&
            category !== null &&
            categoriesKeys.includes(category.toLowerCase()),
        ) ?? []
    return removeDuplicates([...categoriesOrders, ...categoriesKeys]).map(
      (category) => ({ value: category.toLowerCase() }),
    )
  }, [categories, storeSettings?.categories_orders])

  const sortedSubCategories = useMemo(() => {
    const subCategories = categories?.[familyName ?? ""] ?? []
    const orderedSubCategories =
      storeSettings?.categories_orders
        ?.find((category) => category.name.toLowerCase() === familyName)
        ?.children.filter((subCategory) =>
          subCategories.includes(subCategory.toLowerCase()),
        ) ?? []
    return removeDuplicates([...orderedSubCategories, ...subCategories]).map(
      (subCategory) => ({ value: subCategory.toLowerCase() }),
    )
  }, [categories, familyName, storeSettings?.categories_orders])

  const mercurialeDate = mercurialAndStoreInventories[0]?.date_integration

  const { statuses } = useFetchOrderStatus({
    dimMercuriales,
    storeId: storeId!,
  })

  const status = selectedDimMercurialeId
    ? statuses[selectedDimMercurialeId]
    : undefined

  const receptionDate = status?.expectedReceptionDate

  const handleSelectMercurialeDate = (mercurialeId: string) => {
    dispatch({
      type: "resetPageFilters",
      payload: { page },
    })
    dispatch({
      type: "setSelectedDimMercurialeId",
      payload: mercurialeId,
    })
  }

  const handleSelectCategory = (familyName?: string) => {
    dispatch({
      type: "setFilters",
      payload: {
        page: "inventoryPage",
        familyName,
        subFamilyName: undefined,
      },
    })
    onCategoryChange()
  }

  const handleSelectSubcategory = (subFamilyName?: string) => {
    dispatch({
      type: "setFilters",
      payload: {
        page: "inventoryPage",
        subFamilyName,
      },
    })
    onCategoryChange()
  }

  return (
    <InventoryNavbarLayout
      suppliersElement={
        storeSettings?.show_suppliers && (
          <div className="hidden sm:flex gap-1 items-center">
            {suppliers
              .filter(
                (supplier) =>
                  !suppliersIds?.length ||
                  suppliersIds?.includes(supplier.value),
              )
              .map((supplier) => (
                <Badge
                  variant="secondary"
                  key={supplier.value}
                  className="font-medium text-xs whitespace-nowrap"
                >
                  {upperFirst(supplier.label.toLowerCase())}
                  {supplier.extra}
                </Badge>
              ))}
          </div>
        )
      }
      dateElement={
        <MercurialeDateSelection
          dimMercuriales={dimMercuriales}
          selectedDimMercurialeId={selectedDimMercurialeId}
          onSelect={handleSelectMercurialeDate}
          mercurialeReceptionDate={receptionDate}
          mercurialeDate={mercurialeDate}
          isMercurialeLoading={isMercurialeLoading}
        />
      }
      testModeElement={
        isTestMode && (
          <span className="mr-3 inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-bold bg-amber-200 text-amber-800">
            <span className="size-1.5 inline-block rounded-full bg-amber-700"></span>
            Mode {isReadOnly ? "visualisation" : "test"}
          </span>
        )
      }
      logoElement={
        <Link
          to="/home"
          onClick={(e) => {
            if (!online || isLoading) {
              e.preventDefault()
            }
            if (
              dataSynchronizationStatus === DataSynchronizationStatus.FAILURE
            ) {
              e.preventDefault()
              setSaveDataModalState({ isOpen: true, backToHome: true })
            }
          }}
          className="px-3 text-gray-500 flex items-center justify-center"
        >
          {isLoading ? (
            <Spinner invertColors className="w-5 lg:w-6" />
          ) : (
            <MinimalLogo
              companyId={companyId}
              testMode={isTestMode}
              franchise={storeFranchise}
            />
          )}
        </Link>
      }
      onlineStatusElement={
        <WifiIcon
          className={`w-5 h-5 lg:w-6 lg:h-6 ${online ? "text-green-500" : "text-red-500"}`}
        />
      }
      breadcrumbElement={
        storeSettings?.show_categories && (
          <CategoryBreadcrumb
            subCategories={sortedSubCategories.map(({ value }) => value)}
            categories={sortedCategories.map(({ value }) => value)}
            activeCategory={familyName}
            activeSubcategory={subFamilyName}
            onSelectCategory={handleSelectCategory}
            onSelectSubcategory={handleSelectSubcategory}
          />
        )
      }
      adminButtonElement={isIdaAdmin && <UpdateOrderStatusButton />}
    />
  )
}

export default InventoryNav
