import { Skeleton } from "@/components/ui/skeleton"
import { LastPerformance } from "@/pages/HomePage/components/lastPerformanceHome"
import { StateType } from "@/types"
import {
  LastBreakagesResult,
  LastRevenueResult,
} from "@/utils/__generated__/graphql"
import { Trash, TrendingUp, TriangleAlert } from "lucide-react"
import { useSelector } from "react-redux"
import { CheckinSideBar } from "./components/checkinSideBar"
import { InventoryRow } from "@/components/inventory/InventoryRow"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"

export const LastPerformances = ({
  gotToNextStep,
  currentStep,
  totalSteps,
  closeCheckinModal,
  lastWeeksPerformancesData,
  lastWeeksPerformancesLoading,
  updateInventory,
  top5HighestBreakage,
}: {
  gotToNextStep: () => void
  currentStep?: number
  totalSteps?: number
  closeCheckinModal: () => void
  updateInventory: (value: string) => Promise<void>
  lastWeeksPerformancesData:
    | {
        lastBreakages: LastBreakagesResult
        lastRevenue: LastRevenueResult
      }
    | undefined
  lastWeeksPerformancesLoading: boolean
  top5HighestBreakage: AllMercurialInfo[]
}) => {
  const {
    companyName,
    storeFranchise,
    storeId,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
  } = useSelector((state: StateType) => state.storeReducer)
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.inventoryPage.displayShelfFloorSize,
  )

  const isValidNumber = (value: number) => !isNaN(value) && value > 0

  const displayBreakages =
    isValidNumber(lastWeeksPerformancesData?.lastBreakages.one_week_ago ?? 0) &&
    isValidNumber(lastWeeksPerformancesData?.lastBreakages.two_weeks_ago ?? 0)

  const displayRevenue =
    isValidNumber(lastWeeksPerformancesData?.lastRevenue.one_week_ago ?? 0) &&
    isValidNumber(lastWeeksPerformancesData?.lastRevenue.two_weeks_ago ?? 0)

  return (
    <div className="flex flex-row gap-6 mb-20">
      <div className="flex flex-col px-1 gap-2 w-full">
        <div className="flex w-full flex-col   gap-6 px-8 py-4 border-solid border-2 border-gray-300 rounded-lg bg-white">
          {lastWeeksPerformancesLoading ? (
            <div className="flex flex-col gap-6">
              <Skeleton className="w-full h-20" />
              <Skeleton className="w-full h-20" />
            </div>
          ) : (
            <>
              {displayBreakages && (
                <LastPerformance
                  title="Démarque"
                  description="La démarque"
                  oneWeekAgo={
                    lastWeeksPerformancesData?.lastBreakages.one_week_ago ?? 0
                  }
                  twoWeeksAgo={
                    lastWeeksPerformancesData?.lastBreakages.two_weeks_ago ?? 0
                  }
                  oneYearAgo={
                    lastWeeksPerformancesData?.lastBreakages.one_year_ago ?? 0
                  }
                  currency={storeCurrency ?? "EUR"}
                  icon={<Trash className="size-4" />}
                />
              )}
              {displayRevenue && (
                <LastPerformance
                  title="Chiffre d'affaires"
                  description="Le chiffre d'affaires"
                  oneWeekAgo={
                    lastWeeksPerformancesData?.lastRevenue.one_week_ago ?? 0
                  }
                  twoWeeksAgo={
                    lastWeeksPerformancesData?.lastRevenue.two_weeks_ago ?? 0
                  }
                  oneYearAgo={
                    lastWeeksPerformancesData?.lastRevenue.one_year_ago ?? 0
                  }
                  currency={storeCurrency ?? "EUR"}
                  icon={<TrendingUp className="size-4" />}
                  invertColors
                />
              )}
            </>
          )}
        </div>
        <div className="flex flex-col gap-2 w-full">
          {!lastWeeksPerformancesLoading ? (
            top5HighestBreakage.map((reference, index) => {
              const breakagePercent =
                typeof reference?.breakage_percentage === "number"
                  ? Math.round(reference.breakage_percentage)
                  : 0

              const breakageAmount = Math.round(reference?.breakage_amount ?? 0)
              return (
                <div key={reference.mercuriale_id!}>
                  <div className="bg-red-600 p-2 rounded-t-xl flex items-center justify-between text-white">
                    <div className="flex items-center gap-2">
                      <TriangleAlert className="size-5" />
                      <p className="font-bold text-sm">
                        Démarque {breakagePercent}%
                      </p>
                    </div>

                    <p className="font-bold text-sm">
                      {new Intl.NumberFormat("fr-FR", {
                        notation: "compact",
                        style: "currency",
                        currency: storeCurrency ?? "EUR",
                        maximumFractionDigits:
                          (breakageAmount ?? 0) > 1000 ? 2 : 0,
                      }).format(breakageAmount ?? 0)}
                    </p>
                  </div>
                  <div id={`${reference.mercuriale_id}`}>
                    <InventoryRow
                      index={index}
                      storeId={storeId}
                      bestSellers={[]}
                      selectedInventory={selectedInventory}
                      isOnline={isOnline}
                      storeSettings={storeSettings}
                      updateInventory={updateInventory}
                      displayShelfFloorSize={displayShelfFloorSize}
                      storeSuppliers={storeSuppliers}
                      storeCurrency={storeCurrency}
                      companyId={companyId}
                      references={[reference]}
                      companyName={companyName}
                      franchise={storeFranchise}
                      isInCheckinPage={true}
                      className="rounded-t-none"
                    />
                  </div>
                </div>
              )
            })
          ) : (
            <div className="flex flex-col gap-2">
              {[...Array(4)].map((_, index) => (
                <Skeleton key={index} className="w-full h-20" />
              ))}
            </div>
          )}
        </div>
      </div>
      <CheckinSideBar
        gotToNextStep={gotToNextStep}
        currentStep={currentStep}
        totalSteps={totalSteps}
        closeCheckinModal={closeCheckinModal}
        updateInventory={updateInventory}
        mercurialeInfos={top5HighestBreakage}
      />
    </div>
  )
}
