import { CalendarIcon, ChevronDownIcon } from "lucide-react"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useFetchOrderStatus } from "../useFetchOrderStatus"

const MercurialeDate = ({
  receptionDate,
  mercurialeDate,
  isMercurialeLoading,
}: {
  receptionDate?: string | null
  mercurialeDate?: string | null
  isMercurialeLoading: boolean
}) => {
  const isValidReceptionDate = !isNaN(new Date(receptionDate ?? "").getTime())
  const isValidMercurialeDate = !isNaN(new Date(mercurialeDate ?? "").getTime())
  return !isMercurialeLoading ? (
    <div className="whitespace-nowrap font-bold flex items-center gap-1">
      <CalendarIcon className="w-4 h-4 text-muted-foreground mr-0.5" />
      <span className="text-muted-foreground font-medium">
        {isValidReceptionDate ? "Reception" : "Commande"} du{" "}
      </span>
      {isValidReceptionDate ? (
        <span>
          {new Intl.DateTimeFormat("fr-FR", {
            weekday: "long",
            day: "2-digit",
          }).format(new Date(receptionDate ?? ""))}{" "}
        </span>
      ) : isValidMercurialeDate ? (
        <span>
          {new Intl.DateTimeFormat("fr-FR", {
            weekday: "long",
            day: "2-digit",
          }).format(new Date(mercurialeDate ?? ""))}
        </span>
      ) : (
        <div className="my-1 w-14 h-2 bg-gray-300 rounded-full animate-pulse" />
      )}
    </div>
  ) : (
    <div className="my-1 w-14 h-2 bg-gray-300 rounded-full animate-pulse" />
  )
}

export const MercurialeDateSelection = ({
  isMercurialeLoading,
  dimMercuriales = [],
  selectedDimMercurialeId,
  mercurialeReceptionDate,
  mercurialeDate,
  onSelect,
}: {
  isMercurialeLoading: boolean
  onSelect: (id: string) => void
  mercurialeReceptionDate?: string | null
  mercurialeDate?: string | null
  selectedDimMercurialeId?: string
  dimMercuriales: MercurialReducerState["dimMercuriales"]
}) => {
  const { companyName, storeId } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const { statuses } = useFetchOrderStatus({
    dimMercuriales,
    storeId: storeId!,
  })

  const mercuriales = dimMercuriales.filter(
    (mercuriale) => !!mercuriale.dimMercurialeId,
  )
  const isDisabled = !mercuriales || mercuriales.length <= 1

  if (isDisabled) {
    return (
      <MercurialeDate
        receptionDate={mercurialeReceptionDate}
        mercurialeDate={mercurialeDate}
        isMercurialeLoading={isMercurialeLoading}
      />
    )
  }

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <MercurialeDate
              receptionDate={mercurialeReceptionDate}
              mercurialeDate={mercurialeDate}
              isMercurialeLoading={isMercurialeLoading}
            />
            <ChevronDownIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="min-w-48 max-h-96 overflow-auto"
          align="center"
        >
          <DropdownMenuLabel>Vos commandes du jour</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {mercuriales.map(({ dimMercurialeId }) => {
            const status = statuses[dimMercurialeId]
            const displayText =
              companyName === "auchan"
                ? status?.expectedReceptionDate
                  ? `Livraison le ${new Intl.DateTimeFormat("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "short",
                    }).format(new Date(status.expectedReceptionDate))}`
                  : "Livraison le N/A"
                : status?.orderPickupTime
                  ? `Heure limite : ${status.orderPickupTime.slice(0, -3).replace(":", "h")}`
                  : "Heure limite : N/A"

            return (
              <DropdownMenuCheckboxItem
                key={dimMercurialeId}
                checked={selectedDimMercurialeId === dimMercurialeId}
                onSelect={() => onSelect(dimMercurialeId)}
                className="text-sm font-medium"
              >
                {displayText}
              </DropdownMenuCheckboxItem>
            )
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
