import { CheckIcon } from "@heroicons/react/24/outline"
import { DeleteIcon } from "./icons/DeleteIcon"
import { HalfIcon } from "./icons/HalfIcon"
import { OneQuarterIcon } from "./icons/OneQuarterIcon"
import { ThreeQuartersIcon } from "./icons/ThreeQuartersIcon"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { ArrowDownFromLine } from "lucide-react"

const keypads = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "blur", "0", "-1"]
const quarters = ["1/4", "1/2", "3/4"]

interface NumericPadProps {
  disableNumbers?: boolean
  disableDot?: boolean
  disableDelete?: boolean
  disableBlur?: boolean
  onClick: (value: string) => void
}

export function NumericPad({
  disableNumbers = false,
  disableDot = false,
  disableDelete = false,
  disableBlur = false,
  onClick,
}: NumericPadProps): JSX.Element {
  const storeSettings = useSelector(
    (state: StateType) => state.storeReducer.storeSettings,
  )

  function isButtonDisabled(keypadValue: string): boolean {
    if (keypadValue === "1/4" || keypadValue === "1/2" || keypadValue === "3/4")
      return disableDot
    if (keypadValue === "-1") return disableDelete
    if (keypadValue === "blur") return disableBlur
    return disableNumbers
  }

  function getQuarterIcon(quarter: string): JSX.Element {
    switch (quarter) {
      case "1/4":
        return <OneQuarterIcon />
      case "1/2":
        return <HalfIcon />
      case "3/4":
        return <ThreeQuartersIcon />
      default:
        return <></>
    }
  }

  function getKeypadIcon(keypad: string): JSX.Element {
    switch (keypad) {
      case "-1":
        return <DeleteIcon />
      case "blur":
        return !storeSettings?.auto_scroll_inventory ? (
          <CheckIcon className="w-6 h-6" />
        ) : (
          <ArrowDownFromLine className="w-6 h-6" />
        )
      default:
        return <>{keypad}</>
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="bg-white w-full shadow rounded-lg">
        <div className="grid grid-cols-3 bg-gray-100 gap-[1px] rounded-lg overflow-hidden">
          {quarters.map((quarter, i) => (
            <button
              key={i}
              disabled={isButtonDisabled(quarter)}
              onClick={() => {
                onClick(quarter)
              }}
              className="w-full py-4 flex justify-center items-center bg-white enabled:hover:bg-gray-50 disabled:text-gray-300 transition-all text-xl font-black"
            >
              {getQuarterIcon(quarter)}
            </button>
          ))}
        </div>
      </div>
      <div className="bg-white w-full shadow rounded-lg">
        <div className="grid grid-cols-3 bg-gray-100 gap-[1px] rounded-lg overflow-hidden">
          {keypads.map((keypad, i) =>
            keypad !== undefined ? (
              <button
                key={i}
                disabled={isButtonDisabled(keypad)}
                onClick={() => {
                  onClick(keypad)
                }}
                className="w-full py-4 flex justify-center items-center bg-white enabled:hover:bg-gray-50 disabled:text-gray-300 transition-all text-xl font-black"
              >
                {getKeypadIcon(keypad)}
              </button>
            ) : (
              <div className="bg-white" key={i} />
            ),
          )}
        </div>
      </div>
    </div>
  )
}
