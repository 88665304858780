export interface NavigationItem {
  condition: boolean
  icon: React.ReactNode
  title: string
  onClick: () => void
  isActive: boolean
  component?: React.ReactNode
}

export const storeDisplayRules: Record<
  string,
  {
    showOrder: boolean
    showEngagement: boolean
    showReferentielProduit: boolean
    showLinks: boolean
    showCheckin: boolean
  }
> = {
  carrefour: {
    showOrder: true,
    showEngagement: false,
    showReferentielProduit: false,
    showLinks: false,
    showCheckin: true,
  },
  naturalia: {
    showOrder: true,
    showEngagement: false,
    showReferentielProduit: false,
    showLinks: false,
    showCheckin: true,
  },
  biomonde: {
    showOrder: true,
    showEngagement: false,
    showReferentielProduit: true,
    showLinks: false,
    showCheckin: true,
  },
  systemeu: {
    showOrder: true,
    showEngagement: false,
    showReferentielProduit: false,
    showLinks: false,
    showCheckin: true,
  },
  intermarche: {
    showOrder: true,
    showEngagement: true,
    showReferentielProduit: false,
    showLinks: false,
    showCheckin: true,
  },
  marceletfils: {
    showOrder: true,
    showEngagement: false,
    showReferentielProduit: false,
    showLinks: true,
    showCheckin: true,
  },
  default: {
    showOrder: true,
    showEngagement: false,
    showReferentielProduit: false,
    showLinks: false,
    showCheckin: true,
  },
}
