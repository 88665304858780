// components/OrdersRegroupingTable.tsx

"use client"
import { Checkbox } from "@/components/ui/checkbox"
import { Icon, Link2, PackageOpen, Ruler, Tag } from "lucide-react"
import { targetArrow } from "@lucide/lab"

import { useState, useEffect, useMemo, useCallback, FC } from "react"
import {
  ReferenceWithRegroupingOrdersIdaRecords,
  useGetReferenceWithRegroupingOrdersIdaQuery,
} from "@/utils/__generated__/graphql"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import { toast } from "sonner"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useParams, useSearchParams } from "react-router-dom"
import { ArrowUpDown, Trash, Pencil } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { OrdersRegroupingDataTable } from "./OrdersRegroupingDataTable"
import BatchDeleteOrdersReferenceDialog from "./BatchDeleteOrdersReferenceDialog"
import BatchMatchOrdersReferenceDialog from "./BatchMatchOrdersReferenceDialog"
import EditOrdersReferenceDialog from "./EditOrdersReferenceDialog" // New import
import Tooltip from "../components/tooltip" // Import Tooltip

const OrdersRegroupingTable: FC = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { data, loading, error } = useGetReferenceWithRegroupingOrdersIdaQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise_name: franchiseParam,
        store_id: storeId!,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !storeId,
  })

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des références.")
    }
  }, [error])

  const [isBatchMatchDialogOpen, setIsBatchMatchDialogOpen] = useState(false)
  const [isBatchDeleteDialogOpen, setIsBatchDeleteDialogOpen] = useState(false)

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false) // New state
  const [selectedEditReference, setSelectedEditReference] =
    useState<ReferenceWithRegroupingOrdersIdaRecords | null>(null) // Separate state for editing

  const [selectedRecords, setSelectedRecords] = useState<
    ReferenceWithRegroupingOrdersIdaRecords[]
  >([])

  const [references, setReferences] = useState<
    ReferenceWithRegroupingOrdersIdaRecords[]
  >(data?.getReferenceWithRegroupingOrdersIda.records ?? [])

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setReferences(data?.getReferenceWithRegroupingOrdersIda.records ?? [])
  }, [data?.getReferenceWithRegroupingOrdersIda.records])

  const selected = useMemo(() => {
    return references.filter((record) => rowSelection[record.order_id])
  }, [rowSelection, references])

  const allSelectedMatched = useMemo(() => {
    return selected.length > 0 && selected.every((record) => record.is_matched)
  }, [selected])

  const allSelectedUnmatched = useMemo(() => {
    return selected.length > 0 && selected.every((record) => !record.is_matched)
  }, [selected])

  const handleBatchMatch = useCallback(() => {
    if (!allSelectedUnmatched) {
      toast.error("Toutes les références sélectionnées doivent être non liées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchMatchDialogOpen(true)
  }, [allSelectedUnmatched, selected])

  const handleBatchDelete = useCallback(() => {
    if (!allSelectedMatched) {
      toast.error("Toutes les références sélectionnées doivent être matchées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchDeleteDialogOpen(true)
  }, [allSelectedMatched, selected])

  const [hasShownMixedSelectionToast, setHasShownMixedSelectionToast] =
    useState(false)

  useEffect(() => {
    if (selected.length > 0) {
      const hasMatched = selected.some((record) => record.is_matched)
      const hasUnmatched = selected.some((record) => !record.is_matched)
      if (hasMatched && hasUnmatched) {
        if (!hasShownMixedSelectionToast) {
          toast.warning(
            "Vous avez sélectionné à la fois des références liées et non liées.",
          )
          setHasShownMixedSelectionToast(true)
        }
      } else {
        setHasShownMixedSelectionToast(false)
      }
    }
  }, [selected, hasShownMixedSelectionToast])

  const columns: ColumnDef<ReferenceWithRegroupingOrdersIdaRecords>[] = useMemo(
    () => [
      {
        accessorKey: "selecteur",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onCheckedChange={(checked) =>
              table.toggleAllRowsSelected(!!checked)
            }
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(checked) => row.toggleSelected(!!checked)}
          />
        ),
      },
      {
        accessorKey: "supplier_name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Fournisseur
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "order_name",
        cell: (info) => (
          <div className="text-left px-1 max-w-32">
            {info.getValue() as string}
          </div>
        ),
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Libellé achat
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "unit",
        header: () => (
          <div className="text-left flex gap-2">
            <Ruler className="h-4 w-4" />
            Unité
          </div>
        ),
        cell: ({ row, getValue }) => {
          const unit = getValue<string>()
          const is_matched_unit = row.original.is_matched_unit

          return (
            <div className="flex items-center">
              {is_matched_unit ? (
                <Tooltip content="Unité mise à jour">
                  <span className="text-blue-800">{unit || "-"}</span>
                </Tooltip>
              ) : (
                <span>{unit || "-"}</span>
              )}
            </div>
          )
        },
      },
      {
        accessorKey: "colisage",
        header: () => (
          <div className="text-left flex gap-2">
            <PackageOpen className="h-4 w-4" />
            Colisage
          </div>
        ),
        cell: ({ row, getValue }) => {
          const colisage = getValue<string>()
          const is_matched_colisage = row.original.is_matched_colisage

          return (
            <div className="flex items-center">
              {is_matched_colisage ? (
                <Tooltip content="Colisage mis à jour">
                  <span className="text-blue-800">{colisage || "-"}</span>
                </Tooltip>
              ) : (
                <span>{colisage || "-"}</span>
              )}
            </div>
          )
        },
      },
      {
        accessorKey: "order_code",
        header: "Code achat",
      },
      {
        accessorKey: "sale_name_ida_base",
        header: ({ column }) => (
          <Button
            className="text-left"
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Tag className="h-4 w-4" />
            Code base
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-left">{info.getValue() as string}</div>
        ),
      },
      {
        accessorKey: "sale_name_ida_cible",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Icon iconNode={targetArrow} />
            Code cible
          </Button>
        ),
        cell: (info) => (
          <div className="text-center">{info.getValue() as string}</div>
        ),
      },
      {
        accessorKey: "libelle_base",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Tag className="h-4 w-4" />
            Libellé base
          </Button>
        ),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "libelle_cible",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Icon iconNode={targetArrow} />
            Libellé cible
          </Button>
        ),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "is_matched",
        header: "Matché",
        cell: ({ getValue }) =>
          getValue<boolean>() ? (
            <Badge variant="default">Oui</Badge>
          ) : (
            <Badge variant="outline">Non</Badge>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                setSelectedEditReference(row.original)
                setIsEditDialogOpen(true)
              }}
              className="flex items-center gap-2"
            >
              <Pencil className="h-4 w-4" />
              Modifier
            </Button>
          </div>
        ),
      },
    ],
    [setSelectedEditReference, setIsEditDialogOpen],
  )

  return (
    <div className="flex flex-col flex-1 overflow-y-hidden pt-2">
      <div className="flex justify-start px-2 gap-2">
        <Button
          onClick={handleBatchMatch}
          disabled={!allSelectedUnmatched}
          className="flex items-center space-x-2"
        >
          <Link2 className="h-4 w-4" />
          <span>Lier</span>
        </Button>
        <Button
          variant="destructive"
          onClick={handleBatchDelete}
          disabled={!allSelectedMatched}
          className="flex items-center space-x-2"
        >
          <Trash className="h-4 w-4" />
          <span>Supprimer</span>
        </Button>
      </div>
      <OrdersRegroupingDataTable
        columns={columns}
        data={references}
        loading={loading}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      {selectedEditReference && (
        <EditOrdersReferenceDialog
          isOpen={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          record={selectedEditReference}
          refetch={() => {
            setRowSelection({})
          }}
          companyId={companyId!}
          franchiseParam={franchiseParam!}
          storeId={storeId!}
          setReferences={setReferences}
        />
      )}

      {selectedRecords.length > 0 && isBatchMatchDialogOpen && (
        <BatchMatchOrdersReferenceDialog
          isOpen={isBatchMatchDialogOpen}
          onClose={() => setIsBatchMatchDialogOpen(false)}
          selectedReferences={selectedRecords}
          refetch={() => {
            setRowSelection({})
          }}
          allReferences={references}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
          setReferences={setReferences}
        />
      )}

      {selectedRecords.length > 0 && isBatchDeleteDialogOpen && (
        <BatchDeleteOrdersReferenceDialog
          isOpen={isBatchDeleteDialogOpen}
          onClose={() => setIsBatchDeleteDialogOpen(false)}
          selectedReferences={selectedRecords}
          refetch={() => {
            setRowSelection({})
          }}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
          setReferences={setReferences}
        />
      )}
    </div>
  )
}

export default OrdersRegroupingTable
