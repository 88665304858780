import { useDispatch, useSelector } from "react-redux"
import { Button } from "@/components/ui/button"
import {
  GetMatchingReferencesQuery,
  useGetMatchingReferencesLazyQuery,
  useUpdateDimStoreArticleMutation,
} from "../utils/__generated__/graphql"
import { DispatchActionType, StateType } from "../types"
import { Virtuoso } from "react-virtuoso"
import { Fragment, useEffect, useMemo, useState } from "react"
import { Input } from "@/components/ui/input"
import { Input as SearchInput } from "../ui/Input"
import { SearchIcon } from "../ui/icons/SearchIcon"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { removeDuplicates } from "../utils/removeDuplicates"
import { Combobox, Transition } from "@headlessui/react"
import { captureException } from "@sentry/react"
import { getTimeAgo } from "../utils/getTimeAgo"
import { Spinner } from "../ui/Spinner"
import { toast } from "sonner"
import MultipleSelector, { Option } from "@/components/ui/multi-select"
import Header from "@/components/header"

export function MatchingPage() {
  const dispatch = useDispatch<DispatchActionType>()

  const { storeId, storeSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const [searchValue, setSearchValue] = useState("")
  const [selectedReference, setSelectedReference] = useState<{
    orderId: string
    value?: string
  }>()
  const [saleNameQueryValue, setSaleNameQueryValue] = useState("")
  const [matchingReferenceResult, setMatchingReferenceResult] =
    useState<GetMatchingReferencesQuery["getMatchingReferences"]>()
  const [activeView, setActiveView] = useState<"articles" | "categories">(
    "articles",
  )
  const [selectedSuppliers, setSelectedSuppliers] = useState<Option[]>([])

  const [getMatchingReferences, { loading: matchingReferencesLoading }] =
    useGetMatchingReferencesLazyQuery({
      variables: {
        input: {
          store_id: storeId ?? "",
        },
      },
    })
  const [UpdateDimStoreArticleMutation] = useUpdateDimStoreArticleMutation()

  const handleArticleUpdate = async (
    reference: Exclude<typeof filteredReferences, undefined>[number],
    updates: Partial<{
      article_name: string
      article_pv: number
      family_name: string
      sub_family_name: string
      sale_name_ida: string
    }>,
  ) => {
    if (isTestMode) return

    try {
      const result = await UpdateDimStoreArticleMutation({
        variables: {
          input: {
            order_id: reference.order_id,
            store_id: storeId!,
            ...updates,
          },
        },
      })

      if (result.data?.updateDimStoreArticle.error !== null) {
        throw new Error(result.data?.updateDimStoreArticle.error?.message)
      }

      setMatchingReferenceResult((_matchingReferenceResult) => {
        const updatedReferences = [
          ...(_matchingReferenceResult?.matching_references ?? []),
        ]
        const referenceIndex = updatedReferences.findIndex(
          (_reference) => _reference.order_id === reference.order_id,
        )
        updatedReferences[referenceIndex] = {
          ...updatedReferences[referenceIndex],
          ...updates,
        }

        return {
          ..._matchingReferenceResult,
          last_update: new Date().toISOString(),
          matching_references: updatedReferences,
        }
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Données non sauvegardées")
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getMatchingReferences()
        if (result.data?.getMatchingReferences.error !== null) {
          throw new Error(result.data?.getMatchingReferences.error?.message)
        }

        setMatchingReferenceResult(result?.data.getMatchingReferences)
      } catch (error) {
        console.error(error)
        captureException(error)
        toast.error("Aucune donnée")
      }
    }
    fetchData()
  }, [dispatch, getMatchingReferences])

  const filteredReferences = useMemo(() => {
    return matchingReferenceResult?.matching_references
      ?.filter((_reference) => {
        if (selectedSuppliers.length === 0) return true
        return selectedSuppliers.some(
          (selectedSupplier) => selectedSupplier.id === _reference.supplier_id,
        )
      })
      ?.filter(
        (_reference) =>
          _reference.article_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          _reference.order_code?.includes(searchValue),
      )
      .sort((a, b) => {
        return a.article_name.localeCompare(b.article_name)
      })
  }, [
    matchingReferenceResult?.matching_references,
    searchValue,
    selectedSuppliers,
  ])

  const saleNames = useMemo(() => {
    const _saleNames = matchingReferenceResult?.matching_references?.map(
      (_reference) => _reference.sale_name_ida,
    )
    if (saleNameQueryValue === "") return []
    return removeDuplicates(
      _saleNames?.filter((saleName) => saleName.includes(saleNameQueryValue)) ??
        [],
    )
  }, [matchingReferenceResult?.matching_references, saleNameQueryValue])

  const suppliersOptions = useMemo<Option[]>(() => {
    return (
      storeSuppliers?.map((supplier) => ({
        label: supplier.supplier_name,
        value: supplier.supplier_name,
        id: supplier.id,
      })) ?? []
    )
  }, [storeSuppliers])

  return (
    <div className="h-screen flex flex-col gap-2 text-zinc-800">
      <Header />
      <div className="h-full flex flex-col gap-2 text-zinc-800">
        <div className="px-6">
          <div className="flex items-center gap-4 mb-4">
            <p className="text-2xl text-black font-bold">
              Personnalisation des articles
            </p>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-2">
              <Button
                onClick={() => setActiveView("articles")}
                variant={activeView === "articles" ? "default" : "outline"}
                className="bg-green-500 text-white hover:bg-green-600"
              >
                Articles
              </Button>
              <Button
                onClick={() => setActiveView("categories")}
                variant={activeView === "categories" ? "default" : "outline"}
                className="bg-green-500 text-white hover:bg-green-600"
              >
                Catégories
              </Button>
            </div>
          </div>
          <SearchInput
            name="search"
            type="text"
            placeholder="Rechercher"
            value={searchValue}
            icon={<SearchIcon className="w-4 h-4 lg:w-6 lg:h-6" />}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {typeof matchingReferenceResult?.last_update === "string" && (
            <p className="text-zinc-500 text-sm">
              Dernière modification :{" "}
              {getTimeAgo(new Date(matchingReferenceResult.last_update))}
            </p>
          )}
        </div>
        <div>
          <MultipleSelector
            defaultOptions={suppliersOptions}
            placeholder="Fournisseurs"
            emptyIndicator={
              <p className="text-center text-gray-600 dark:text-gray-400">
                Ce fournisseur n&apos;existe pas
              </p>
            }
            onChange={setSelectedSuppliers}
            className="bg-white"
          />
        </div>
        {activeView === "articles" && (
          <>
            <div className="grid grid-cols-7 bg-zinc-100 p-2 rounded font-bold text-sm">
              <p>Nom</p>
              <p>Code de commande</p>
              <p>Fournisseur</p>
              <p>Famille</p>
              <p>Sous-famille</p>
              <p>PV</p>
              <p>Libellé regroupement</p>
            </div>
            {matchingReferencesLoading && (
              <div className="flex justify-center">
                <Spinner invertColors className="w-6" />
              </div>
            )}
            <Virtuoso
              className="h-full"
              data={filteredReferences}
              itemContent={(_, reference) => (
                <div>
                  <div className="grid grid-cols-7 items-center gap-4 py-2 px-4">
                    <Input
                      name="article_name"
                      type="text"
                      value={reference.article_name}
                      onChange={(e) => {
                        const newValue = e.target.value
                        setMatchingReferenceResult(
                          (_matchingReferenceResult) => ({
                            ..._matchingReferenceResult,
                            matching_references:
                              _matchingReferenceResult?.matching_references?.map(
                                (ref) =>
                                  ref.order_id === reference.order_id
                                    ? { ...ref, article_name: newValue }
                                    : ref,
                              ),
                          }),
                        )
                        handleArticleUpdate(reference, {
                          article_name: newValue,
                        })
                      }}
                      className="w-full p-1 text-sm"
                    />
                    <p>{reference.order_code}</p>
                    <p>{reference.supplier_name}</p>
                    <Input
                      name="family_name"
                      type="text"
                      value={reference.family_name ?? ""}
                      onChange={(e) => {
                        const newValue = e.target.value

                        setMatchingReferenceResult(
                          (_matchingReferenceResult) => ({
                            ..._matchingReferenceResult,
                            matching_references:
                              _matchingReferenceResult?.matching_references?.map(
                                (ref) =>
                                  ref.order_id === reference.order_id
                                    ? { ...ref, family_name: newValue }
                                    : ref,
                              ),
                          }),
                        )
                        handleArticleUpdate(reference, {
                          family_name: newValue,
                        })
                      }}
                      className="w-full p-1 text-sm"
                    />
                    <Input
                      name="sub_family_name"
                      type="text"
                      value={reference.sub_family_name ?? ""}
                      onChange={(e) => {
                        const newValue = e.target.value
                        setMatchingReferenceResult(
                          (_matchingReferenceResult) => ({
                            ..._matchingReferenceResult,
                            matching_references:
                              _matchingReferenceResult?.matching_references?.map(
                                (ref) =>
                                  ref.order_id === reference.order_id
                                    ? { ...ref, sub_family_name: newValue }
                                    : ref,
                              ),
                          }),
                        )
                        handleArticleUpdate(reference, {
                          sub_family_name: newValue,
                        })
                      }}
                      className="w-full p-1 text-sm"
                    />
                    <Input
                      name="pv_matching"
                      type="number"
                      step="0.01"
                      value={reference.article_pv ?? 0}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value)
                        setMatchingReferenceResult(
                          (_matchingReferenceResult) => ({
                            ..._matchingReferenceResult,
                            matching_references:
                              _matchingReferenceResult?.matching_references?.map(
                                (ref) =>
                                  ref.order_id === reference.order_id
                                    ? { ...ref, article_pv: newValue }
                                    : ref,
                              ),
                          }),
                        )
                        handleArticleUpdate(reference, { article_pv: newValue })
                      }}
                      className="w-full p-1 text-sm"
                    />
                    <Combobox
                      value={
                        selectedReference?.orderId === reference.order_id
                          ? selectedReference.value
                          : reference.sale_name_ida
                      }
                      onChange={async (value) => {
                        if (value === reference.sale_name_ida) return
                        setSelectedReference({
                          orderId: reference.order_id,
                          value: value ?? "",
                        })
                        setSaleNameQueryValue("")

                        if (isTestMode) return
                        await handleArticleUpdate(reference, {
                          sale_name_ida: value ?? "",
                        })
                      }}
                    >
                      <div className="relative">
                        <div className="relative w-full cursor-default overflow-hidden rounded text-left shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                          <Combobox.Input
                            className="w-full bg-transparent rounded p-2 pr-10 border-2 border-zinc-400 focus:border-green-800 text-gray-900"
                            displayValue={(saleName) => saleName as string}
                            onChange={(event) =>
                              setSaleNameQueryValue(event.target.value)
                            }
                            placeholder="Aucun code de vente"
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setSaleNameQueryValue("")}
                        >
                          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white p-4 shadow focus:outline-none">
                            <p className="text-zinc-500 font-medium px-2">
                              Sélectionnez un code
                            </p>
                            {saleNames.length === 0 &&
                            saleNameQueryValue !== "" ? (
                              <p className="relative cursor-default select-none px-2 py-1 text-gray-700">
                                Aucun résultat
                              </p>
                            ) : (
                              <>
                                <Combobox.Option
                                  className={({ active }) =>
                                    `relative cursor-pointer select-none rounded px-2 py-1 ${active ? "bg-gray-100" : ""}`
                                  }
                                  value={reference.sale_name_ida}
                                >
                                  <span className="block truncate w-fit border border-neutral-200 bg-zinc-100 px-2 rounded font-medium">
                                    {reference.sale_name_ida}
                                  </span>
                                </Combobox.Option>
                                {saleNames
                                  .filter(
                                    (saleName) =>
                                      saleName !== reference.sale_name_ida,
                                  )
                                  .slice(0, 4)
                                  .map((saleName, i) => (
                                    <Combobox.Option
                                      key={i}
                                      className={({ active }) =>
                                        `relative cursor-pointer select-none rounded px-2 py-1 ${active ? "bg-gray-100" : ""}`
                                      }
                                      value={saleName}
                                    >
                                      <span className="block truncate w-fit font-medium">
                                        {saleName}
                                      </span>
                                    </Combobox.Option>
                                  ))}
                              </>
                            )}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    </Combobox>
                  </div>
                  <hr />
                </div>
              )}
            />
          </>
        )}
      </div>
    </div>
  )
}
