"use client"

import { useState, useEffect, useMemo } from "react"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { add, differenceInCalendarDays } from "date-fns"
import { toast } from "sonner"
import { Button } from "@/components/ui/button"

import { Spinner } from "@/ui/Spinner"
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu"

import {
  Send,
  X,
  BadgePercent,
  Timer,
  ArrowUp,
  ArrowDown,
  TrophyIcon,
  TrendingUp,
  TrendingDown,
  Filter,
  TrashIcon,
  Edit3,
  LandPlot,
  Package,
  Truck,
} from "lucide-react"

import { useGetPredictionsModifByStoreAndReferenceQuery } from "@/utils/__generated__/graphql"
import { PerformancesPageContext } from "../PerformancesPage"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { Paysan } from "@/ui/icons/Paysan"
import { Badge } from "@/components/ui/badge"
import { usePermissions } from "@/hooks/usePermissions" // <-- Ensure you have a custom hook that provides { isIdaAdmin, isCompanyAdmin }
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"

// Single column sort config
type SortDirection = "none" | "asc" | "desc"
interface SortConfig {
  key: string
  direction: SortDirection
}

export default function OrderDashPage() {
  // 1) Grab from context
  const { selectedStoreId, stores, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId

  // Redux store ID if any:
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  // Identify store name
  const storeName =
    stores?.find((store) => store.id === selectedStoreId)?.store_name ?? ""

  // (CHANGED) Use useMemo so these dates aren't reconstructed on every render
  const defaultStartDate = useMemo(() => add(new Date(), { days: -7 }), [])
  const defaultEndDate = useMemo(() => new Date(), [])

  // Retrieve user permissions
  const { isCompanyAdmin, isIdaAdmin } = usePermissions()

  // Decide whether to show the “Préd. avt stock” column
  // => If "isCompanyAdmin && !isIdaAdmin" => hide
  // => Otherwise => show
  const showPredAvantStock = !(isCompanyAdmin && !isIdaAdmin)

  // ------------------------------------------------
  // Local States
  // ------------------------------------------------
  const [referenceFilter, setReferenceFilter] = useState("")

  // Additional flags: showPromo, showTop, showStock, showLocal, showBreakage
  const [showPromo, setShowPromo] = useState(false)
  const [showTop, setShowTop] = useState(false)
  const [showStock, setShowStock] = useState(false)
  const [showLocal, setShowLocal] = useState(false)
  const [showBreakage, setShowBreakage] = useState(false)

  // “modifFilter” => none|all|up|down
  const [modifFilter, setModifFilter] = useState<
    "none" | "all" | "up" | "down"
  >("none")

  // Sort config for all columns
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "date", // default sort by date
    direction: "desc",
  })

  // Simple pagination
  const [page, setPage] = useState(1)
  const pageSize = 350

  // GraphQL data
  const { data, loading, error } =
    useGetPredictionsModifByStoreAndReferenceQuery({
      variables: {
        input: {
          company_id: companyId!,
          store_id: storeId!, // or "" if none
          selected_store_id: selectedStoreId ?? undefined,
          franchise_name: franchiseParam,
          // (CHANGED) Using stable defaultStartDate / defaultEndDate from useMemo
          start_date: formatDateToYYYYMMDD(fromDate ?? defaultStartDate),
          end_date: formatDateToYYYYMMDD(toDate ?? defaultEndDate),
        },
      },
      fetchPolicy: "cache-and-network",
    })

  // All records
  const allRecords = useMemo(
    () =>
      data?.getPredictionsModifByStoreAndReference?.performances_daily ?? [],
    [data],
  )

  const totalOrderQuantity = useMemo(() => {
    return Math.round(
      allRecords.reduce((acc, curr) => {
        return acc + (curr.quantity_ordered_in_colis ?? 0)
      }, 0) / (getDiffBetweenDates(fromDate, toDate) || 1),
    )
  }, [allRecords, fromDate, toDate])

  const totalReferencesOrdered = useMemo(() => {
    return Math.round(
      allRecords.reduce((acc, curr) => {
        if ((curr.quantity_ordered_in_colis ?? 0) > 0) {
          return acc + 1
        }
        return acc
      }, 0) / (getDiffBetweenDates(fromDate, toDate) || 1),
    )
  }, [allRecords, fromDate, toDate])

  // Top 15 by (quantity_sold_in_colis * colisage)
  const topSaleIds = useMemo(() => {
    if (!allRecords.length) return new Set<string>()
    const sortedDesc = [...allRecords].sort(
      (a, b) =>
        (b.quantity_sold_in_colis ?? 0) * (b.colisage ?? 1) -
        (a.quantity_sold_in_colis ?? 0) * (a.colisage ?? 1),
    )
    const top15 = sortedDesc.slice(0, 15).map((rec) => rec.sale_id)
    return new Set(top15)
  }, [allRecords])

  // ------------------------------------------------
  // Sorting: Helpers
  // ------------------------------------------------
  function handleSort(columnKey: string) {
    // If we click the same column, toggle direction
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === "asc") {
        setSortConfig({ key: columnKey, direction: "desc" })
      } else if (sortConfig.direction === "desc") {
        setSortConfig({ key: columnKey, direction: "none" })
      } else {
        setSortConfig({ key: columnKey, direction: "asc" })
      }
    } else {
      // new column
      setSortConfig({ key: columnKey, direction: "asc" })
    }
    setPage(1)
  }

  function renderSortIndicator(columnKey: string) {
    if (sortConfig.key !== columnKey) return "—"
    if (sortConfig.direction === "asc") return "↑"
    if (sortConfig.direction === "desc") return "↓"
    return "—"
  }

  // ------------------------------------------------
  // Filter & Sort
  // ------------------------------------------------
  const filteredRecords = useMemo(() => {
    // Step 1: filtering
    const arr = allRecords.filter((rec) => {
      // Text filter
      if (referenceFilter) {
        const searchLower = referenceFilter.toLowerCase()
        const refLower = rec.sale_name?.toLowerCase() ?? ""
        const storeLower = rec.store_name?.toLowerCase() ?? ""
        const dateStr = rec.order_request_date?.slice(0, 10) ?? ""
        if (
          !(
            refLower.includes(searchLower) ||
            storeLower.includes(searchLower) ||
            dateStr.includes(searchLower)
          )
        ) {
          return false
        }
      }
      // Badge filters
      if (showPromo || showTop || showStock || showLocal || showBreakage) {
        const isPromo = showPromo && rec.promotion
        const isTop = showTop && topSaleIds.has(rec.sale_id)
        const isStock = showStock && rec.stock_a_ecouler
        const isLocal = showLocal && rec.local_flag
        const isBreakage = showBreakage && rec.high_breakage_flag
        if (!(isPromo || isTop || isStock || isLocal || isBreakage)) {
          return false
        }
      }

      // modifFilter
      switch (modifFilter) {
        case "all": {
          if ((rec.updated || 0) <= 0) return false
          break
        }
        case "up": {
          const updated = rec.updated || 0
          const qtyOrdered = rec.quantity_ordered_in_colis ?? 0
          const qtyFinalPred = rec.final_quantity_predicted_in_colis ?? 0
          if (!(updated > 0 && qtyOrdered > qtyFinalPred)) {
            return false
          }
          break
        }
        case "down": {
          const updated = rec.updated || 0
          const qtyOrdered = rec.quantity_ordered_in_colis ?? 0
          const qtyFinalPred = rec.final_quantity_predicted_in_colis ?? 0
          if (!(updated > 0 && qtyOrdered < qtyFinalPred)) {
            return false
          }
          break
        }
        case "none":
        default:
          break
      }
      return true
    })

    // Step 2: sorting if direction != none
    if (sortConfig.direction === "none") {
      return arr
    }

    const newArr = [...arr]
    newArr.sort((a, b) => {
      let valA: string | number = ""
      let valB: string | number = ""

      switch (sortConfig.key) {
        case "store":
          valA = (a.store_name ?? "").toLowerCase()
          valB = (b.store_name ?? "").toLowerCase()
          break
        case "date":
          valA = a.order_request_date ?? ""
          valB = b.order_request_date ?? ""
          break
        case "sale_name_ida":
          valA = (a.sale_name_ida ?? "").toLowerCase()
          valB = (b.sale_name_ida ?? "").toLowerCase()
          break
        case "reference":
          valA = (a.sale_name ?? "").toLowerCase()
          valB = (b.sale_name ?? "").toLowerCase()
          break
        case "colisage":
          valA = a.colisage ?? 0
          valB = b.colisage ?? 0
          break
        case "ventes":
          valA = a.quantity_sold_in_colis ?? 0
          valB = b.quantity_sold_in_colis ?? 0
          break
        case "predAvt":
          valA = a.quantity_predicted_in_colis ?? 0
          valB = b.quantity_predicted_in_colis ?? 0
          break
        case "predPost":
          valA = a.final_quantity_predicted_in_colis ?? 0
          valB = b.final_quantity_predicted_in_colis ?? 0
          break
        case "cmds":
          valA = a.quantity_ordered_in_colis ?? 0
          valB = b.quantity_ordered_in_colis ?? 0
          break
        case "diff": {
          const diffA =
            (a.quantity_ordered_in_colis ?? 0) -
            (a.final_quantity_predicted_in_colis ?? 0)
          const diffB =
            (b.quantity_ordered_in_colis ?? 0) -
            (b.final_quantity_predicted_in_colis ?? 0)
          valA = diffA
          valB = diffB
          break
        }
        default:
          return 0
      }

      // numeric vs string compare
      let compareResult = 0
      if (typeof valA === "number" && typeof valB === "number") {
        compareResult = valA - valB
      } else {
        compareResult = (valA as string).localeCompare(valB as string)
      }

      return sortConfig.direction === "asc" ? compareResult : -compareResult
    })
    return newArr
  }, [
    allRecords,
    referenceFilter,
    showPromo,
    showTop,
    showStock,
    showLocal,
    showBreakage,
    modifFilter,
    sortConfig,
    topSaleIds,
  ])

  // ------------------------------------------------
  // Stats & Derived
  // ------------------------------------------------
  const totalCount = filteredRecords.length

  // Up/down counts
  const countUp = useMemo(
    () =>
      filteredRecords.filter(
        (rec) =>
          (rec.updated || 0) > 0 &&
          (rec.quantity_ordered_in_colis ?? 0) >
            (rec.final_quantity_predicted_in_colis ?? 0),
      ).length,
    [filteredRecords],
  )

  const countDown = useMemo(
    () =>
      filteredRecords.filter(
        (rec) =>
          (rec.updated || 0) > 0 &&
          (rec.quantity_ordered_in_colis ?? 0) <
            (rec.final_quantity_predicted_in_colis ?? 0),
      ).length,
    [filteredRecords],
  )

  const toPercent = (val: number) => {
    if (!totalCount) return 0
    return Math.round((val / totalCount) * 100)
  }

  // date -> stats map
  const dateMap = useMemo(() => {
    const map = new Map<string, { total: number; modif: number }>()
    for (const rec of filteredRecords) {
      const d = rec.order_request_date?.slice(0, 10) ?? ""
      if (!d) continue
      if (!map.has(d)) {
        map.set(d, { total: 0, modif: 0 })
      }
      const info = map.get(d)!
      info.total++
      if ((rec.updated || 0) > 0) {
        info.modif++
      }
    }
    return map
  }, [filteredRecords])

  // build ratio array
  const dateRatios = useMemo(() => {
    const arr: { date: string; ratio: number }[] = []
    for (const [dateStr, info] of dateMap.entries()) {
      const ratio = info.total ? info.modif / info.total : 0
      arr.push({ date: dateStr, ratio })
    }
    arr.sort((a, b) => a.date.localeCompare(b.date))
    return arr
  }, [dateMap])

  // trend
  const dateTrend = useMemo(() => {
    if (dateRatios.length < 2) return 0
    let sumDiff = 0
    for (let i = 1; i < dateRatios.length; i++) {
      sumDiff += dateRatios[i].ratio - dateRatios[i - 1].ratio
    }
    return Math.round(sumDiff * 100)
  }, [dateRatios])

  // Mean Distance Stats
  const daysCount = useMemo(() => {
    return (
      differenceInCalendarDays(
        toDate ?? defaultEndDate,
        fromDate ?? defaultStartDate,
      ) + 1
    )
  }, [fromDate, toDate, defaultStartDate, defaultEndDate])

  const updatedRecords = useMemo(
    () => filteredRecords.filter((rec) => (rec.updated || 0) > 0),
    [filteredRecords],
  )

  const modificationsCount = updatedRecords.length
  const totalModDistance = useMemo(() => {
    return updatedRecords.reduce((acc, rec) => {
      const diff =
        (rec.quantity_ordered_in_colis ?? 0) -
        (rec.final_quantity_predicted_in_colis ?? 0)
      return acc + Math.abs(diff)
    }, 0)
  }, [updatedRecords])

  const meanModDistance = modificationsCount
    ? totalModDistance / modificationsCount
    : 0

  const distancePerDay = daysCount > 0 ? totalModDistance / daysCount : 0

  // ------------------------------------------------
  // Pagination
  // ------------------------------------------------
  const totalRows = filteredRecords.length
  const totalPages = Math.ceil(totalRows / pageSize)
  useEffect(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages)
    }
  }, [page, totalPages])

  const paginatedRecords = useMemo(() => {
    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize
    return filteredRecords.slice(startIndex, endIndex)
  }, [filteredRecords, page, pageSize])

  // Handle errors
  useEffect(() => {
    if (error) {
      toast.error("Erreur lors du chargement des données.")
    }
  }, [error])

  // Helper: modifFilter button
  const getModifFilterButton = () => {
    let icon = <X className="w-4 h-4" />
    let label = "Aucun filtre"
    let colorClasses = "text-gray-700"

    switch (modifFilter) {
      case "all":
        icon = <Edit3 className="w-4 h-4 text-blue-600" />
        label = "Toutes les modifs"
        colorClasses = "text-blue-600"
        break
      case "up":
        icon = <ArrowUp className="w-4 h-4 text-red-600" />
        label = "Modif à la hausse"
        colorClasses = "text-red-600"
        break
      case "down":
        icon = <ArrowDown className="w-4 h-4 text-orange-600" />
        label = "Modif à la baisse"
        colorClasses = "text-orange-600"
        break
      case "none":
      default:
        icon = <X className="w-4 h-4 text-gray-700" />
        label = "Aucun filtre"
        colorClasses = "text-gray-700"
        break
    }

    return (
      <Button
        variant="outline"
        className={`text-xs gap-2 hidden md:flex ${colorClasses}`}
      >
        {icon}
        {label}
      </Button>
    )
  }

  // ------------------------------------------------
  // Render
  // ------------------------------------------------
  return (
    <div className="flex flex-col p-2 gap-2 h-full overflow-hidden">
      <div className="flex-1 overflow-auto">
        <Card className="h-full flex flex-col">
          {/* Header */}
          <CardHeader>
            <CardTitle className="flex items-center gap-2 whitespace-nowrap">
              <Send className="text-gray-500" />
              {storeName || "Tous les magasins"}
            </CardTitle>

            {/* Filters / Sorting */}
            <div className="flex flex-wrap items-center gap-4 mt-4 md:mt-2">
              <Input
                placeholder="(date, magasin, référence...)"
                value={referenceFilter}
                onChange={(e) => {
                  setReferenceFilter(e.target.value)
                  setPage(1)
                }}
                className="w-60"
              />

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="text-xs gap-2">
                    <Filter className="w-4 h-4" />
                    Badges
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-48">
                  <DropdownMenuLabel className="text-sm text-gray-500">
                    Filtrer par badge
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuCheckboxItem
                    checked={showPromo}
                    onCheckedChange={(checked) => {
                      setShowPromo(checked)
                      setPage(1)
                    }}
                  >
                    Promo
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={showTop}
                    onCheckedChange={(checked) => {
                      setShowTop(checked)
                      setPage(1)
                    }}
                  >
                    Top vente en qté
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={showLocal}
                    onCheckedChange={(checked) => {
                      setShowLocal(checked)
                      setPage(1)
                    }}
                  >
                    Local
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={showStock}
                    onCheckedChange={(checked) => {
                      setShowStock(checked)
                      setPage(1)
                    }}
                  >
                    Stock à écouler
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem
                    checked={showBreakage}
                    onCheckedChange={(checked) => {
                      setShowBreakage(checked)
                      setPage(1)
                    }}
                  >
                    Démarque élevée
                  </DropdownMenuCheckboxItem>
                </DropdownMenuContent>
              </DropdownMenu>

              {/* Modif filter */}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  {getModifFilterButton()}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-48">
                  <DropdownMenuLabel className="text-sm text-gray-500">
                    Filtres de modifications
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      setModifFilter("none")
                      setPage(1)
                    }}
                  >
                    <span className="flex items-center gap-2">
                      <X className="w-3 h-3 text-gray-700" />
                      Aucun filtre
                    </span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      setModifFilter("all")
                      setPage(1)
                    }}
                  >
                    <span className="flex items-center gap-2">
                      <Edit3 className="w-3 h-3 text-blue-600" />
                      Toutes
                    </span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      setModifFilter("up")
                      setPage(1)
                    }}
                  >
                    <span className="flex items-center gap-2">
                      <ArrowUp className="w-3 h-3 text-red-600" />
                      hausse
                    </span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      setModifFilter("down")
                      setPage(1)
                    }}
                  >
                    <span className="flex items-center gap-2">
                      <ArrowDown className="w-3 h-3 text-orange-600" />
                      baisse
                    </span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>

          <CardContent className="flex-1 overflow-auto">
            {loading ? (
              <div className="flex items-center justify-center min-h-[200px]">
                <Spinner />
              </div>
            ) : paginatedRecords.length === 0 ? (
              <div className="text-gray-500 p-4">
                Aucune donnée pour ces filtres.
              </div>
            ) : (
              <>
                {/* Compact stats row (sticky) */}
                <div className="sticky top-0 z-10 bg-white border-b p-2">
                  {totalCount > 0 && (
                    <div className="flex-wrap items-center gap-3 text-xs md:text-sm text-gray-700 hidden sm:flex">
                      <Badge className="flex items-center gap-1">
                        <Edit3 className="w-3 h-3" /> Stats Modifs:
                      </Badge>
                      <div className="flex items-center gap-1">
                        <ArrowUp className="w-3 h-3 text-red-600" />
                        <span>Hausse:</span>
                        <strong>{countUp}</strong> / {totalCount} refs{" "}
                        <small>({toPercent(countUp)}%)</small>
                      </div>
                      <div className="flex items-center gap-1">
                        <ArrowDown className="w-3 h-3 text-orange-600" />
                        <span>Baisse:</span>
                        <strong>{countDown}</strong> / {totalCount} refs{" "}
                        <small>({toPercent(countDown)}%)</small>
                      </div>
                      {dateRatios.length > 1 && (
                        <div className="flex items-center gap-1">
                          <span>Tendance:</span>
                          {dateTrend >= 0 ? (
                            <>
                              <TrendingUp className="w-4 h-4 text-red-700" />
                              <strong>+{dateTrend}%</strong>
                            </>
                          ) : (
                            <>
                              <TrendingDown className="w-4 h-4 text-green-700" />
                              <strong>{dateTrend}%</strong>
                            </>
                          )}
                        </div>
                      )}
                      <div className="flex items-center gap-1">
                        <LandPlot className="w-3 h-3 text-gray-700" />
                        <span>Dist moy:</span>
                        <strong>{meanModDistance.toFixed(1)} cs/ref</strong>
                      </div>
                      <div className="flex items-center gap-1">
                        <LandPlot className="w-3 h-3 text-gray-700" />
                        <span>Dist total /j:</span>
                        <strong>{distancePerDay.toFixed(1)} cs</strong>
                      </div>
                      <div className="flex items-center gap-1">
                        <Package className="w-3 h-3 text-gray-700" />
                        <span>CS commandés /j:</span>
                        <strong>{totalOrderQuantity} cs</strong>
                      </div>
                      <div className="flex items-center gap-1">
                        <Truck className="w-3 h-3 text-gray-700" />
                        <span>Réfs commandées /j:</span>
                        <strong>{totalReferencesOrdered}</strong>
                      </div>
                    </div>
                  )}
                </div>

                {/* Scrollable container */}
                <div className="overflow-auto">
                  <table className="min-w-full text-sm border table-auto">
                    {/* Sticky header */}
                    <thead className="top-0 bg-gray-50 z-20 border-b text-gray-700">
                      <tr>
                        <th
                          // sticky first column
                          className="left-0 z-10 bg-gray-50 px-3 py-2 text-left whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("store")}
                        >
                          Magasin {renderSortIndicator("store")}
                        </th>
                        <th
                          className="px-3 py-2 text-left whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("date")}
                        >
                          Date {renderSortIndicator("date")}
                        </th>
                        <th
                          className="px-3 py-2 text-left whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("sale_name_ida")}
                        >
                          Code Vente {renderSortIndicator("sale_name_ida")}
                        </th>
                        <th
                          className="px-3 py-2 text-left whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("reference")}
                        >
                          Référence {renderSortIndicator("reference")}
                        </th>
                        <th
                          className="px-3 py-2 text-center hidden md:table-cell whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("colisage")}
                        >
                          Colisage {renderSortIndicator("colisage")}
                        </th>
                        <th
                          className="px-3 py-2 text-right hidden md:table-cell whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("ventes")}
                        >
                          Ventes {renderSortIndicator("ventes")}
                        </th>
                        {showPredAvantStock && (
                          <th
                            className="px-3 py-2 text-right hidden lg:table-cell whitespace-nowrap cursor-pointer"
                            onClick={() => handleSort("predAvt")}
                          >
                            Préd. avt stock {renderSortIndicator("predAvt")}
                          </th>
                        )}

                        <th
                          className="px-3 py-2 text-right whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("predPost")}
                        >
                          Préd. {renderSortIndicator("predPost")}
                        </th>
                        <th
                          className="px-3 py-2 text-right whitespace-nowrap cursor-pointer"
                          onClick={() => handleSort("cmds")}
                        >
                          Cmds. {renderSortIndicator("cmds")}
                        </th>
                        <th
                          className="px-3 py-2 text-right whitespace-nowrap hidden sm:table-cell cursor-pointer"
                          onClick={() => handleSort("diff")}
                        >
                          Diff (cs) {renderSortIndicator("diff")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedRecords.map((rec, idx) => {
                        const isTopSale = topSaleIds.has(rec.sale_id)
                        const breakagePct = rec.breakage_percentage ?? 0
                        const diffVal =
                          (rec.quantity_ordered_in_colis ?? 0) -
                          (rec.final_quantity_predicted_in_colis ?? 0)
                        const displayDiff =
                          diffVal > 0
                            ? `+${diffVal.toFixed(2)}`
                            : diffVal.toFixed(2)
                        const diffClass =
                          diffVal > 0
                            ? "text-red-600"
                            : diffVal < 0
                              ? "text-orange-600"
                              : "text-gray-600"

                        // If high_breakage_flag => highlight row
                        const rowClass = rec.high_breakage_flag
                          ? "border-2 border-red-500"
                          : "border-b last:border-0 hover:bg-gray-50"

                        return (
                          <tr key={idx} className={rowClass}>
                            {/* Sticky first column for each row */}
                            <td className="left-0 bg-white px-3 py-2 whitespace-nowrap">
                              {rec.store_name}
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap">
                              {rec.order_request_date?.slice(0, 10)}
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap">
                              {rec.sale_name_ida}
                            </td>
                            <td className="px-3 py-2">
                              <div className="flex items-center gap-1 flex-nowrap">
                                <span>{rec.sale_name}</span>
                                {/* Badges */}
                                {isTopSale && (
                                  <span
                                    className="inline-flex items-center gap-1 px-1 py-0.5 font-semibold bg-gradient-to-r from-amber-100 to-amber-200 text-amber-800 whitespace-nowrap"
                                    title="Top vente en qté"
                                  >
                                    <TrophyIcon className="w-3 h-3" />
                                  </span>
                                )}
                                {rec.promotion && (
                                  <span
                                    className="inline-flex items-center gap-1 px-1 py-0.5 rounded-full bg-blue-100 text-black whitespace-nowrap"
                                    title="Promotion"
                                  >
                                    <BadgePercent className="w-3 h-3" />
                                    Promo
                                  </span>
                                )}
                                {rec.stock_a_ecouler && (
                                  <span
                                    className="flex items-center text-xs gap-1 bg-orange-100 text-orange-700 px-1 py-0.5 rounded whitespace-nowrap"
                                    title="Stock à écouler"
                                  >
                                    <Timer className="w-3 h-3" />
                                    Stock
                                  </span>
                                )}
                                {rec.local_flag && (
                                  <span
                                    className="flex items-center text-xs gap-1 bg-green-700 px-1 py-0.5 rounded text-white whitespace-nowrap"
                                    title="Local flag"
                                  >
                                    <Paysan className="w-4 h-4" />
                                  </span>
                                )}
                                {rec.high_breakage_flag && (
                                  <span
                                    className="inline-flex items-center text-xs gap-1 border text-red-800 px-1 py-0.5 rounded whitespace-nowrap"
                                    title={`Démarque élevée : ${breakagePct.toFixed(
                                      1,
                                    )}%`}
                                  >
                                    <TrashIcon className="w-3 h-3" />
                                    {breakagePct.toFixed(1)}%
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-3 py-2 text-center hidden md:table-cell whitespace-nowrap">
                              {rec.colisage}
                            </td>
                            <td className="px-3 py-2 text-right hidden md:table-cell whitespace-nowrap">
                              {Math.round(
                                (rec.quantity_sold_in_colis || 0) * 100,
                              ) / 100}{" "}
                              cs
                            </td>

                            {/* Conditionally show predAvt */}
                            {showPredAvantStock ? (
                              <td className="px-3 py-2 text-right hidden lg:table-cell whitespace-nowrap">
                                {Math.round(
                                  (rec.quantity_predicted_in_colis || 0) * 100,
                                ) / 100}{" "}
                                cs
                              </td>
                            ) : null}

                            <td className="px-3 py-2 text-right whitespace-nowrap">
                              {Math.round(
                                (rec.final_quantity_predicted_in_colis || 0) *
                                  100,
                              ) / 100}{" "}
                              cs
                            </td>
                            <td className="px-3 py-2 text-right whitespace-nowrap">
                              {Math.round(
                                (rec.quantity_ordered_in_colis || 0) * 100,
                              ) / 100}{" "}
                              cs
                            </td>
                            <td className="px-3 py-2 text-right whitespace-nowrap hidden sm:table-cell">
                              <span className={diffClass}>{displayDiff}</span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Pagination Controls */}
                <div className="flex items-center justify-between mt-4">
                  <div className="text-sm text-gray-700">
                    Page {page} / {totalPages === 0 ? 1 : totalPages} —{" "}
                    {filteredRecords.length} résultats
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      disabled={page <= 1}
                      className="px-3 py-1 border rounded disabled:opacity-50"
                    >
                      Précédent
                    </button>
                    <button
                      onClick={() =>
                        setPage((prev) => (prev < totalPages ? prev + 1 : prev))
                      }
                      disabled={page >= totalPages}
                      className="px-3 py-1 border rounded disabled:opacity-50"
                    >
                      Suivant
                    </button>
                  </div>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
