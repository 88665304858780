import { Table } from "@/components/ui/table"
import { StateType } from "@/types"
import { Spinner } from "@/ui/Spinner"
import {
  BreakagesByStore,
  Performance,
  useGetBreakagesByStoresLazyQuery,
  useGetPerformancesQuery,
} from "@/utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { useSelector } from "react-redux"
import { PerformancesTableHeader } from "./PerformancesTableHeader"
import { PerformancesTableBody } from "./PerformancesTableBody"
import { PerformancesTableFooter } from "./PerformancesTableFooter"
import { useEffect, useMemo } from "react"
import { toast } from "sonner"

export type TableRowData = Omit<Performance & BreakagesByStore, "__typename">

interface PerformancesByStoreProps {
  storeId: string
  companyId: string
  franchise: string | undefined
  fromDate: Date
  toDate: Date
}

export function PerformancesByStore({
  storeId,
  companyId,
  franchise,
  fromDate,
  toDate,
}: PerformancesByStoreProps) {
  const storeCurrency = useSelector(
    (state: StateType) => state.storeReducer.storeCurrency,
  )

  const getPerformancesResult = useGetPerformancesQuery({
    variables: {
      input: {
        company_id: companyId,
        franchise_name: franchise,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        store_id: storeId!,
      },
    },
  })

  const [getBreakagesByStores, getBreakagesByStoresResult] =
    useGetBreakagesByStoresLazyQuery({
      variables: {
        input: {
          company_id: companyId,
          franchise_name: franchise,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          store_id: storeId!,
        },
      },
    })

  useEffect(() => {
    if (
      (getPerformancesResult.data?.getPerformances.performances ?? []).length >
      0
    ) {
      getBreakagesByStores()
    }
  }, [getBreakagesByStores, getPerformancesResult])

  useEffect(() => {
    if (getBreakagesByStoresResult.error !== undefined) {
      console.error(getBreakagesByStoresResult.error)
      toast.error("Impossible de récupérer la démarque.")
    }
  }, [getBreakagesByStoresResult.error])

  const performances = useMemo<TableRowData[]>(() => {
    return (
      getPerformancesResult.data?.getPerformances.performances?.map(
        (performance) => {
          const breakages =
            getBreakagesByStoresResult.data?.breakagesByStores.find(
              (breakage) => breakage.store_id === performance?.store_id,
            )

          return {
            ...performance,
            breakages_amount: breakages?.breakages_amount ?? 0,
          }
        },
      ) ?? []
    )
  }, [
    getBreakagesByStoresResult.data?.breakagesByStores,
    getPerformancesResult.data?.getPerformances.performances,
  ])

  if (getPerformancesResult.loading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (getPerformancesResult.error !== undefined) {
    return (
      <div className="text-red-500 flex justify-center items-center h-full">
        {getPerformancesResult.error.message}
      </div>
    )
  }

  return (
    <Table className="h-full overflow-y-auto">
      <PerformancesTableHeader />
      <PerformancesTableBody
        performances={performances}
        storeCurrency={storeCurrency}
        isBreakageLoading={getBreakagesByStoresResult.loading}
      />
      <PerformancesTableFooter
        performances={performances}
        storeCurrency={storeCurrency}
        isBreakageLoading={getBreakagesByStoresResult.loading}
      />
    </Table>
  )
}
