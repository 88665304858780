interface FlaggedRefGroupProps {
  header: React.ReactNode
  children: React.ReactNode
  backgroundColor?: string
}

export const FlaggedRefGroup = ({
  header,
  children,
  backgroundColor = "bg-gray-100",
}: FlaggedRefGroupProps) => {
  return (
    <div
      className={`${backgroundColor} flex flex-col gap-4 rounded text-left p-4`}
    >
      <div>{header}</div>
      <div className="flex flex-col gap-2 w-full">{children}</div>
    </div>
  )
}
