"use client"

import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  CSSProperties,
} from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  useGetReferenceWithRegroupingIdaQuery,
  ReferenceWithRegroupingIdaRecords,
  useCreateRegroupingIdaMutation,
  useDeleteRegroupingIdaMutation,
} from "@/utils/__generated__/graphql"

import { StateType } from "@/types"
import { toast } from "sonner"
import { Book, Link2, Square, Trash } from "lucide-react"

// For drag-and-drop
import { useDrag, useDrop, useDragLayer, DragLayerMonitor } from "react-dnd"

// Your existing components
import BatchMatchReferenceDialog from "./BatchMatchReferenceDialog"
import EditReferenceDialog from "./EditReferenceDialog"

// UI imports
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"
import { twJoin } from "tailwind-merge"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Virtuoso } from "react-virtuoso"
import { BatchDeleteReferenceDialog } from "./BatchDeleteReferenceDialog"
import { Spinner } from "@/ui/Spinner"

const DRAG_TYPE = "REFERENCE_ITEM"

// We want to show a "multi-drag" payload
interface DragPayload {
  items: ReferenceWithRegroupingIdaRecords[]
}

interface GroupedMaster {
  master: ReferenceWithRegroupingIdaRecords
  children: ReferenceWithRegroupingIdaRecords[]
}

// Hook to detect if anything is currently being dragged
function useAnyDragging() {
  const { isDragging } = useDragLayer((monitor: DragLayerMonitor) => ({
    isDragging: monitor.isDragging(),
  }))
  return isDragging
}

export default function MatchedReferencesTable() {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { data, loading, error, refetch } =
    useGetReferenceWithRegroupingIdaQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: storeId!,
        },
      },
      fetchPolicy: "cache-and-network",
    })

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des références.")
    }
  }, [error])

  // Convert GQL records -> local references
  const rawRecords = useMemo(
    () => data?.getReferenceWithRegroupingIda.records ?? [],
    [data],
  )
  const [localReferences, setLocalReferences] =
    useState<ReferenceWithRegroupingIdaRecords[]>(rawRecords)

  useEffect(() => {
    setLocalReferences(rawRecords)
  }, [rawRecords])

  // Mark isMaster if server doesn't
  const referencesWithMasterFlag = useMemo(() => {
    return localReferences.map((ref) => {
      const isMaster = localReferences.some(
        (other) =>
          other.sale_name_ida_cible === ref.sale_name_ida_base &&
          other.sale_name_ida_cible !== "-",
      )
      return { ...ref, isMaster }
    })
  }, [localReferences])

  // Group them
  const { groups, unmatched } = useMemo(
    () => groupByMaster(referencesWithMasterFlag),
    [referencesWithMasterFlag],
  )

  // === Batch logic ===
  const [selectedRecords, setSelectedRecords] = useState<
    ReferenceWithRegroupingIdaRecords[]
  >([])
  const [isBatchMatchDialogOpen, setIsBatchMatchDialogOpen] = useState(false)
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  function resetSelection() {
    setRowSelection({})
    setSelectedRecords([])
  }

  const selected = useMemo(() => {
    return referencesWithMasterFlag.filter(
      (record) => rowSelection[record.sale_name_ida_base],
    )
  }, [rowSelection, referencesWithMasterFlag])

  const allSelectedMatched =
    selected.length > 0 && selected.every((r) => r.is_matched)
  const allSelectedUnmatched =
    selected.length > 0 && selected.every((r) => !r.is_matched)

  const handleBatchMatch = useCallback(() => {
    if (!allSelectedUnmatched) {
      toast.error("Toutes les références sélectionnées doivent être non liées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchMatchDialogOpen(true)
  }, [allSelectedUnmatched, selected])

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  function handleBatchDelete() {
    if (!allSelectedMatched) {
      toast.error("Toutes les références sélectionnées doivent être liées.")
      return
    }
    setSelectedRecords(selected)
    setIsDeleteDialogOpen(true)
  }

  // Edit
  const [selectedEditReference, setSelectedEditReference] =
    useState<ReferenceWithRegroupingIdaRecords>()

  // Mixed selection
  const [hasShownMixedSelectionToast, setHasShownMixedSelectionToast] =
    useState(false)
  useEffect(() => {
    if (selected.length > 0) {
      const hasMatched = selected.some((r) => r.is_matched)
      const hasUnmatched = selected.some((r) => !r.is_matched)
      if (hasMatched && hasUnmatched && !hasShownMixedSelectionToast) {
        toast.warning(
          "Vous avez sélectionné à la fois des références liées et non liées.",
        )
        setHasShownMixedSelectionToast(true)
      }
    } else {
      setHasShownMixedSelectionToast(false)
    }
  }, [selected, hasShownMixedSelectionToast])

  // === MASTER Search Logic ===
  const [masterSearchTerm, setMasterSearchTerm] = useState("")
  const filteredGroups = useMemo(() => {
    if (!masterSearchTerm.trim()) {
      return groups
    }
    const lower = masterSearchTerm.toLowerCase()
    return groups.filter(({ master }) => {
      return (
        master.libelle_base.toLowerCase().includes(lower) ||
        master.sale_name_ida_base.toLowerCase().includes(lower)
      )
    })
  }, [groups, masterSearchTerm])

  // Drop zone for multi-drag => open batch match dialog
  const [{ isOver }, dropRef] = useDrop<DragPayload, void, { isOver: boolean }>(
    {
      accept: DRAG_TYPE,
      drop: (dragPayload, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) return
        setSelectedRecords(dragPayload.items)
        setIsBatchMatchDialogOpen(true)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
  )

  // Are we dragging at all?
  const anyIsDragging = useAnyDragging()

  if (loading) {
    return (
      <div className="w-full flex-1 flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="flex flex-col flex-1 overflow-y-hidden p-2 space-y-2">
      {/* Top bar: batch actions */}
      <div className="flex items-center gap-2">
        <Button onClick={handleBatchMatch} disabled={!allSelectedUnmatched}>
          <Link2 className="size-4" />
          Lier (Batch)
        </Button>
        <Button
          variant="destructive"
          onClick={handleBatchDelete}
          disabled={!allSelectedMatched}
        >
          <Trash className="size-4" />
          Supprimer (Batch)
        </Button>
        <Button
          variant="outline"
          onClick={resetSelection}
          disabled={selected.length === 0}
        >
          <Square className="size-4" />
          Désélectionner
        </Button>
      </div>

      <div className="flex-1 flex gap-2 overflow-auto">
        {/* Unmatched Column */}
        <UnmatchedReferencesColumn
          unmatched={unmatched}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          localReferences={localReferences}
          setLocalReferences={setLocalReferences}
          companyId={companyId!}
          storeId={storeId!}
          franchiseParam={franchiseParam}
          anyIsDragging={anyIsDragging}
        />

        {/* Master Cards Container */}
        <Card
          ref={dropRef}
          className={twJoin(
            "flex-1 overflow-y-hidden flex flex-col transition-colors",
            isOver && "border-blue-600 border-2",
          )}
        >
          <CardHeader className="p-2">
            <CardTitle className="text-base">Références liées</CardTitle>
            <Input
              type="text"
              placeholder="Rechercher un Master..."
              value={masterSearchTerm}
              onChange={(e) => setMasterSearchTerm(e.target.value)}
              className="w-full rounded border px-2 py-1 text-sm"
            />
          </CardHeader>
          <CardContent className="p-2 flex-1 overflow-y-auto">
            {filteredGroups.length === 0 ? (
              <p className="text-gray-600 italic">
                Aucun Master correspondant.
              </p>
            ) : (
              <Virtuoso
                data={filteredGroups}
                itemContent={(_, { master, children }) => (
                  <MasterCard
                    key={master.sale_name_ida_base}
                    master={master}
                    childrenRefs={children}
                    companyId={companyId!}
                    storeId={storeId!}
                    franchiseParam={franchiseParam}
                    onEditReference={(ref) => {
                      setSelectedEditReference(ref)
                    }}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    localReferences={localReferences}
                    setLocalReferences={setLocalReferences}
                    anyIsDragging={anyIsDragging}
                  />
                )}
              />
            )}
          </CardContent>
        </Card>
      </div>

      {/* Edit Dialog */}
      <EditReferenceDialog
        isOpen={selectedEditReference !== undefined}
        onClose={() => setSelectedEditReference(undefined)}
        record={selectedEditReference}
        companyId={companyId!}
        franchiseParam={franchiseParam!}
        storeId={storeId!}
        refetch={refetch}
      />

      {/* Batch Match */}
      <BatchMatchReferenceDialog
        isOpen={isBatchMatchDialogOpen}
        onClose={() => {
          setIsBatchMatchDialogOpen(false)
          resetSelection()
        }}
        selectedReferences={selectedRecords}
        allReferences={referencesWithMasterFlag}
        storeId={storeId!}
        companyId={companyId!}
        franchiseParam={franchiseParam}
        setLocalReferences={setLocalReferences}
      />

      {/* Delete Dialog */}
      <BatchDeleteReferenceDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false)
          resetSelection()
        }}
        selectedReferences={selectedRecords}
        companyId={companyId!}
        franchiseParam={franchiseParam!}
        storeId={storeId!}
        setLocalReferences={setLocalReferences}
      />

      {/* Custom Drag Layer for multi-drag visualization */}
      <CustomDragLayer />
    </div>
  )
}

// -------------------------------------------------------------------------
// MasterCard + children
// -------------------------------------------------------------------------
interface MasterCardProps {
  master: ReferenceWithRegroupingIdaRecords
  childrenRefs: ReferenceWithRegroupingIdaRecords[]
  companyId: string
  storeId: string
  franchiseParam?: string
  onEditReference: (ref: ReferenceWithRegroupingIdaRecords) => void
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  localReferences: ReferenceWithRegroupingIdaRecords[]
  setLocalReferences: React.Dispatch<
    React.SetStateAction<ReferenceWithRegroupingIdaRecords[]>
  >
  anyIsDragging: boolean
}

function MasterCard({
  master,
  childrenRefs,
  companyId,
  storeId,
  franchiseParam,
  onEditReference,
  rowSelection,
  setRowSelection,
  setLocalReferences,
  anyIsDragging,
}: MasterCardProps) {
  const [createRegroupingIda] = useCreateRegroupingIdaMutation()

  const [{ isOver }, dropRef] = useDrop<DragPayload, void, { isOver: boolean }>(
    {
      accept: DRAG_TYPE,
      drop: (dragPayload, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) return

        dragPayload.items.forEach((draggedRef) => {
          if (
            draggedRef.sale_name_ida_base === master.sale_name_ida_base ||
            draggedRef.sale_name_ida_cible === master.sale_name_ida_base
          ) {
            return
          }
          // Optimistic local update
          setLocalReferences((prev) =>
            prev.map((item) => {
              if (item.sale_name_ida_base === draggedRef.sale_name_ida_base) {
                return {
                  ...item,
                  is_matched: true,
                  sale_name_ida_cible: master.sale_name_ida_base,
                  libelle_cible: master.libelle_base,
                }
              }
              if (item.sale_name_ida_base === master.sale_name_ida_base) {
                return { ...item, isMaster: true }
              }
              return item
            }),
          )

          async function createMatching() {
            try {
              const { data } = await createRegroupingIda({
                variables: {
                  input: {
                    sale_name_ida_base: draggedRef.sale_name_ida_base,
                    sale_name_ida_cible: master.sale_name_ida_base,
                    libelle_base: draggedRef.libelle_base,
                    libelle_cible: master.libelle_base,
                    store_id: storeId,
                    company_id: companyId,
                    franchise_name: franchiseParam,
                    unit: draggedRef.unit,
                  },
                },
              })
              if (data?.createRegroupingIda?.records) {
                toast.success("Référence liée avec succès (optimiste).")
              } else {
                toast.error("Échec de la liaison.")
              }
            } catch (err) {
              console.error(err)
              toast.error("Erreur lors du matching (optimiste).")
            }
          }
          createMatching()
        })
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
  )

  // If we're dragging anything at all, and this item is selected, blur it
  const isSelectedMaster = rowSelection[master.sale_name_ida_base]
  const masterClass = twJoin(
    "mb-2 transition-colors",
    isOver && "border-green-600 border-2",
    anyIsDragging && isSelectedMaster && "blur-sm",
  )

  return (
    <Card ref={dropRef} className={masterClass}>
      <CardHeader className="p-2 flex-row justify-between items-center">
        <CardTitle className="text-lg w-fit flex items-center gap-1">
          <Book className="size-6" />
          {master.libelle_base}
          <span className="text-base font-normal">
            {master.sale_name_ida_base}
          </span>
        </CardTitle>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onEditReference(master)}
          className="w-fit"
        >
          Modifier
        </Button>
      </CardHeader>
      <CardContent className="p-2">
        {childrenRefs.length === 0 ? (
          <p className="text-sm text-gray-500 italic">Aucune référence liée.</p>
        ) : (
          <ul className="space-y-2">
            {childrenRefs.map((child) => (
              <ChildReferenceItem
                key={child.sale_name_ida_base}
                child={child}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                onEditReference={onEditReference}
                siblings={childrenRefs}
                anyIsDragging={anyIsDragging}
              />
            ))}
          </ul>
        )}
      </CardContent>
    </Card>
  )
}

// Child item in a master => draggable to Unmatched
interface ChildReferenceItemProps {
  child: ReferenceWithRegroupingIdaRecords
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  onEditReference: (ref: ReferenceWithRegroupingIdaRecords) => void
  siblings: ReferenceWithRegroupingIdaRecords[]
  anyIsDragging: boolean
}
function ChildReferenceItem({
  child,
  rowSelection,
  setRowSelection,
  onEditReference,
  siblings,
  anyIsDragging,
}: ChildReferenceItemProps) {
  const checked = !!rowSelection[child.sale_name_ida_base]
  const handleToggleSelected = useCallback(() => {
    setRowSelection((prev) => ({
      ...prev,
      [child.sale_name_ida_base]: !prev[child.sale_name_ida_base],
    }))
  }, [child.sale_name_ida_base, setRowSelection])

  // Gather multi-drag items if this child is selected
  const itemsToDrag = useMemo(() => {
    if (checked) {
      return siblings.filter((s) => rowSelection[s.sale_name_ida_base])
    }
    return [child]
  }, [checked, siblings, rowSelection, child])

  const [{ isDragging }, dragRef] = useDrag<
    DragPayload,
    void,
    { isDragging: boolean }
  >({
    type: DRAG_TYPE,
    item: { items: itemsToDrag },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  // Blur if selected and anything is being dragged
  const shouldBlur = anyIsDragging && rowSelection[child.sale_name_ida_base]

  return (
    <li
      ref={dragRef}
      className={twJoin(
        "flex items-center gap-2 p-2 rounded border cursor-move transition-colors",
        isDragging || shouldBlur
          ? "border-blue-400 border-dashed bg-blue-50 opacity-50"
          : "border-gray-300 bg-gray-50",
      )}
    >
      <Checkbox checked={checked} onCheckedChange={handleToggleSelected} />
      <div className="flex-1">
        <div className="font-medium text-sm">{child.libelle_base}</div>
        <div className="text-xs text-gray-600">{child.sale_name_ida_base}</div>
      </div>
      {child.is_matched ? (
        <Badge variant="default" className="bg-green-700">
          Matché
        </Badge>
      ) : (
        <Badge variant="outline">Non matché</Badge>
      )}
      <Button
        variant="outline"
        size="sm"
        onClick={() => onEditReference(child)}
      >
        Modifier
      </Button>
    </li>
  )
}

// -------------------------------------------------------------------------
// Unmatched column => drop zone for unmatching, plus sticky search bar
// -------------------------------------------------------------------------
interface UnmatchedColumnProps {
  unmatched: ReferenceWithRegroupingIdaRecords[]
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  localReferences: ReferenceWithRegroupingIdaRecords[]
  setLocalReferences: React.Dispatch<
    React.SetStateAction<ReferenceWithRegroupingIdaRecords[]>
  >
  companyId: string
  storeId: string
  franchiseParam?: string
  anyIsDragging: boolean
}

function UnmatchedReferencesColumn({
  unmatched,
  rowSelection,
  setRowSelection,
  setLocalReferences,
  companyId,
  storeId,
  franchiseParam,
  anyIsDragging,
}: UnmatchedColumnProps) {
  const [unmatchedSearchTerm, setUnmatchedSearchTerm] = useState("")
  const [deleteRegroupingIda] = useDeleteRegroupingIdaMutation()

  // Filter unmatched
  const filteredUnmatched = useMemo(() => {
    if (!unmatchedSearchTerm.trim()) return unmatched
    const lower = unmatchedSearchTerm.toLowerCase()
    return unmatched.filter(
      (u) =>
        u.libelle_base.toLowerCase().includes(lower) ||
        u.sale_name_ida_base.toLowerCase().includes(lower),
    )
  }, [unmatched, unmatchedSearchTerm])

  // Drop zone => un-match references
  const [{ isOver }, dropRef] = useDrop<DragPayload, void, { isOver: boolean }>(
    {
      accept: DRAG_TYPE,
      drop: (dragPayload, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) return

        dragPayload.items.forEach((draggedRef) => {
          if (!draggedRef.is_matched) return // already unmatched

          // optimistic update
          setLocalReferences((prev) =>
            prev.map((item) => {
              if (item.sale_name_ida_base === draggedRef.sale_name_ida_base) {
                return {
                  ...item,
                  is_matched: false,
                  sale_name_ida_cible: "-",
                }
              }
              return item
            }),
          )

          async function deleteMatching() {
            try {
              const { data } = await deleteRegroupingIda({
                variables: {
                  input: {
                    sale_name_ida_base: draggedRef.sale_name_ida_base,
                    company_id: companyId,
                    franchise_name: franchiseParam,
                    store_id: storeId,
                  },
                },
              })
              if (data?.deleteRegroupingIda?.success) {
                toast.success("Référence dé-liée avec succès (optimiste).")
              } else {
                toast.error("Échec du dé-liage.")
              }
            } catch (err) {
              console.error(err)
              toast.error("Erreur lors du dé-liage (optimiste).")
            }
          }
          deleteMatching()
        })
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
  )

  const handleToggleSelected = useCallback(
    (item: ReferenceWithRegroupingIdaRecords) => {
      setRowSelection((prev) => ({
        ...prev,
        [item.sale_name_ida_base]: !prev[item.sale_name_ida_base],
      }))
    },
    [setRowSelection],
  )

  return (
    <Card
      ref={dropRef}
      className={twJoin(
        "w-64 overflow-y-hidden flex flex-col transition-colors",
        isOver && "border-red-600 border-2",
      )}
    >
      <CardHeader className="p-2">
        <CardTitle className="text-base">Références non liées</CardTitle>
        <Input
          type="text"
          placeholder="Rechercher..."
          value={unmatchedSearchTerm}
          onChange={(e) => setUnmatchedSearchTerm(e.target.value)}
          className="mb-2 w-full rounded border px-2 py-1 text-sm"
        />
      </CardHeader>
      <CardContent className="p-2 flex-1 overflow-y-auto">
        {filteredUnmatched.length === 0 ? (
          <p className="text-sm italic">Aucune référence non matchée.</p>
        ) : (
          <Virtuoso
            data={filteredUnmatched}
            itemContent={(_, unmatchedItem) => (
              <UnmatchedReferenceItem
                key={unmatchedItem.sale_name_ida_base}
                refItem={unmatchedItem}
                checked={!!rowSelection[unmatchedItem.sale_name_ida_base]}
                onToggleSelected={() => handleToggleSelected(unmatchedItem)}
                rowSelection={rowSelection}
                unmatched={filteredUnmatched}
                anyIsDragging={anyIsDragging}
              />
            )}
          />
        )}
      </CardContent>
    </Card>
  )
}

// Draggable unmatched item
interface UnmatchedReferenceItemProps {
  refItem: ReferenceWithRegroupingIdaRecords
  checked: boolean
  onToggleSelected: () => void
  rowSelection: Record<string, boolean>
  unmatched: ReferenceWithRegroupingIdaRecords[]
  anyIsDragging: boolean
}

function UnmatchedReferenceItem({
  refItem,
  checked,
  onToggleSelected,
  rowSelection,
  unmatched,
  anyIsDragging,
}: UnmatchedReferenceItemProps) {
  // Gather multi-drag items if this is selected
  const itemsToDrag = useMemo(() => {
    if (checked) {
      return unmatched.filter((u) => rowSelection[u.sale_name_ida_base])
    }
    return [refItem]
  }, [checked, unmatched, rowSelection, refItem])

  const [{ isDragging }, dragRef] = useDrag<
    DragPayload,
    void,
    { isDragging: boolean }
  >({
    type: DRAG_TYPE,
    item: { items: itemsToDrag },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const shouldBlur = anyIsDragging && rowSelection[refItem.sale_name_ida_base]

  return (
    <div
      ref={dragRef}
      className={twJoin(
        "cursor-move mb-2 rounded border flex items-start gap-2 p-2 transition-colors",
        isDragging || shouldBlur
          ? "border-blue-400 border-dashed bg-blue-50 opacity-50"
          : "border-gray-300 bg-white opacity-100",
      )}
    >
      <Checkbox checked={checked} onCheckedChange={onToggleSelected} />
      <div className="flex-1">
        <p className="text-sm font-medium break-all">{refItem.libelle_base}</p>
        <p className="text-xs text-gray-600">{refItem.sale_name_ida_base}</p>
      </div>
    </div>
  )
}

// -------------------------------------------------------------------------
// Custom Drag Layer to visualize multi-drag
// -------------------------------------------------------------------------
function CustomDragLayer() {
  const { isDragging, item, offset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as DragPayload | undefined,
    offset: monitor.getSourceClientOffset(),
  }))

  if (!isDragging || !item || !offset) {
    return null
  }

  const style: CSSProperties = {
    top: offset.y,
    left: offset.x,
  }

  const count = item.items.length
  const text = count > 1 ? `${count} références` : "1 référence"

  return (
    <div
      className="pointer-events-none fixed px-2 py-1 text-sm rounded bg-black/80 text-white -translate-y-10"
      style={style}
    >
      {text}
    </div>
  )
}

// groupByMaster utility
function groupByMaster(
  refs: (ReferenceWithRegroupingIdaRecords & { isMaster: boolean })[],
): {
  groups: GroupedMaster[]
  unmatched: ReferenceWithRegroupingIdaRecords[]
} {
  const masters = refs.filter((r) => r.isMaster && r.sale_name_ida_base !== "-")

  const groups: GroupedMaster[] = masters.map((master) => {
    const children = refs.filter(
      (child) =>
        child.sale_name_ida_cible === master.sale_name_ida_base &&
        child.sale_name_ida_base !== master.sale_name_ida_base,
    )
    return { master, children }
  })

  const unmatched = refs.filter(
    (r) => (!r.is_matched || r.sale_name_ida_cible === "-") && !r.isMaster,
  )
  return { groups, unmatched }
}
