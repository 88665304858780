"use client"
import { Range } from "react-range"
import { Label } from "@/components/ui/label"

interface RankRangeSelectorProps {
  min: number
  max: number
  step: number
  // Exactly 2 elements:
  values: [number, number]
  onChange: (values: [number, number]) => void
  label?: string
}

export function RankRangeSelector({
  min,
  max,
  step,
  values,
  onChange,
  label,
}: RankRangeSelectorProps) {
  return (
    <div>
      {label && (
        <Label className="font-semibold">
          {label} {values[0]} - {values[1]}
        </Label>
      )}

      <Range
        min={min}
        max={max}
        step={step}
        // 'values' must be [number, number]
        values={values}
        // 'onChange' must receive [number, number]
        onChange={(vals) => onChange(vals as [number, number])}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="h-2 bg-gray-200 rounded relative mt-2 mb-4"
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className="h-4 w-4 bg-black rounded-full shadow" />
        )}
      />
    </div>
  )
}
