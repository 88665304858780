import { HomeCard } from "../components/homeCard"
import { ColumnDef } from "@tanstack/react-table"
import { HistoricalTable } from "../components/historicalTable"
import {
  OrderHistoricalItem,
  useGetOrderHistoricalQuery,
} from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useMemo, useState } from "react"
import { DateRange } from "react-day-picker"
import { useSearchParams } from "react-router-dom"
import { DateSelector } from "@/pages/admin/PerformancesPage/components/DateSelector"
import { add } from "date-fns"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { ArrowRight } from "lucide-react"

export const OrderHistorical = () => {
  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const [searchParams, setSearchParams] = useSearchParams()

  const fromParam = searchParams.get("from") ?? undefined
  const toParam = searchParams.get("to") ?? undefined

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()
  const franchiseParam = searchParams.get("franchise") ?? undefined

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: fromParam ? new Date(fromParam) : defaultStartDate,
    to: toParam ? new Date(toParam) : defaultEndDate,
  })

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to
    ? dateRange?.to
    : dateRange?.from
      ? dateRange?.from
      : defaultEndDate

  const { data, loading } = useGetOrderHistoricalQuery({
    variables: {
      input: {
        store_id: storeId!,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
      },
    },
  })

  const orderHistorical = data?.orderHistorical.order_historical ?? []

  const formattedOrderHistorical: OrderHistoricalItem[] = [...orderHistorical]
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .map((item: OrderHistoricalItem) => ({
      date: new Intl.DateTimeFormat("fr-FR", {
        weekday: "long",
        day: "2-digit",
        month: "long",
      }).format(new Date(item.date)),
      total_quantity: Math.trunc(item.total_quantity),
      total_pa: Math.round(item.total_pa * 100) / 100,
      dim_order_request_id: item.dim_order_request_id,
    }))

  const columns = useMemo<ColumnDef<OrderHistoricalItem>[]>(() => {
    return [
      {
        accessorKey: "date",
        header: "Date",
      },
      {
        accessorKey: "total_quantity",
        header: "Colis",
      },
      {
        accessorKey: "total_pa",
        header: "Coût (PA HT)",
        cell: ({ getValue }) => {
          const value = Number(getValue())
          const convertedValue = isNaN(value) ? 0 : value

          return Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: storeCurrency ?? "EUR",
            maximumFractionDigits: 0,
          }).format(convertedValue)
        },
      },
      {
        id: "action",
        header: "",
        cell: ({ row }) => {
          const id = row.original.dim_order_request_id
          return (
            <a
              className="flex items-center gap-2"
              href={`/recap?orderId=${id}`}
            >
              <ArrowRight className="w-4 h-4" />
            </a>
          )
        },
      },
    ]
  }, [storeCurrency])

  return (
    <HomeCard
      title="Historique des commandes"
      subtitle="Consulter les commandes passées"
      className="overflow-hidden w-full h-full"
      headerChildren={
        <DateSelector
          fromDate={fromDate}
          toDate={toDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setSearchParams={setSearchParams}
          franchiseParam={franchiseParam}
        />
      }
    >
      <div className="flex flex-col w-full h-full p-4 gap-2">
        <HistoricalTable
          columns={columns}
          data={formattedOrderHistorical}
          isLoading={loading}
        />
      </div>
    </HomeCard>
  )
}
