"use client"

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts"
import { Label } from "@/components/ui/label"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { BrainCircuit } from "lucide-react"
import { useMemo, useState, useRef, useEffect } from "react"

interface ChartDataItem {
  reference: string
  tauxDeModif: number
  predictedAmount: number
  orderedAmount: number
  salesAmount: number
}

interface GlobalReferencePredictionsChartProps {
  data: ChartDataItem[]
  loading: boolean
  /** Removed rangeValues & setRangeValues */
  selectedReference: string
  setSelectedReference: (reference: string) => void
  referenceOptions: string[]
}

export function GlobalReferencePredictionsChart({
  data,
  loading,
  selectedReference,
  setSelectedReference,
  referenceOptions,
}: GlobalReferencePredictionsChartProps) {
  const hasData = useMemo(() => data && data.length > 0, [data])

  // --------------------------------------------------
  // Horizontal Scroll Shadows (Blur) Logic
  // --------------------------------------------------
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [showLeftShadow, setShowLeftShadow] = useState(false)
  const [showRightShadow, setShowRightShadow] = useState(false)

  const handleScroll = () => {
    if (!containerRef.current) return
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
    setShowLeftShadow(scrollLeft > 0)
    setShowRightShadow(scrollLeft + clientWidth < scrollWidth - 1)
  }

  useEffect(() => {
    if (!containerRef.current) return
    handleScroll() // check shadows on initial render
    const el = containerRef.current
    el.addEventListener("scroll", handleScroll)
    return () => {
      el.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Card className="flex-1 h-fit w-full sticky top-0">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2">
            <BrainCircuit className="text-gray-500" />
            Tous les magasins
          </div>
        </CardTitle>

        {/* --------------------------------------------------
            Reference Filter
        -------------------------------------------------- */}
        <div className="flex items-center gap-2 mt-2">
          <Label htmlFor="referenceFilter" className="whitespace-nowrap">
            Référence:
          </Label>
          <select
            id="referenceFilter"
            value={selectedReference}
            onChange={(e) => setSelectedReference(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 w-56"
          >
            <option value="">Toutes</option>
            {referenceOptions.map((ref) => (
              <option key={ref} value={ref}>
                {ref}
              </option>
            ))}
          </select>
        </div>
      </CardHeader>

      <CardContent className="relative w-full">
        {loading ? (
          <div className="animate-pulse">
            <div className="h-64 bg-gray-200 rounded" />
          </div>
        ) : hasData ? (
          <>
            <div className="flex sticky left-0 mb-4">
              <div className="flex space-x-6">
                <LegendItem color="red" label="Ventes" />
                <LegendItem color="black" label="Prévus" />
                <LegendItem color="gray" label="Commandes" />
                <LegendItem
                  color="#2563eb"
                  label="Taux de modification"
                  isLine
                />
              </div>
            </div>

            <div className="relative">
              {showLeftShadow && (
                <div className="pointer-events-none absolute top-0 left-0 h-full w-8 bg-gradient-to-r from-white to-transparent z-10" />
              )}
              {showRightShadow && (
                <div className="pointer-events-none absolute top-0 right-0 h-full w-8 bg-gradient-to-l from-white to-transparent z-10" />
              )}
              <div
                ref={containerRef}
                className="overflow-x-auto w-full"
                style={{ scrollBehavior: "smooth" }}
              >
                <div style={{ width: Math.max(800, data.length * 100) }}>
                  <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                      data={data}
                      margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="reference"
                        angle={-45}
                        textAnchor="end"
                        interval={0}
                        height={100}
                        tick={{ fontSize: 12 }}
                        tickFormatter={(value) =>
                          value.length > 10 ? `${value.slice(0, 25)}...` : value
                        }
                      />
                      <YAxis
                        yAxisId="left"
                        label={{
                          value: "Montant en euro",
                          angle: -90,
                          position: "insideLeft",
                          offset: -10,
                          dy: 40,
                          fill: "black",
                        }}
                      />
                      <YAxis
                        yAxisId="right"
                        orientation="right"
                        label={{
                          value: "Taux de modification",
                          angle: -90,
                          position: "insideRight",
                          dy: -80,
                          dx: -10,
                          fill: "black",
                        }}
                      />
                      <Tooltip />

                      <Bar
                        yAxisId="left"
                        dataKey="salesAmount"
                        fill="red"
                        name="Ventes"
                      />
                      <Bar
                        yAxisId="left"
                        dataKey="predictedAmount"
                        fill="black"
                        name="Prévus"
                      />
                      <Bar
                        yAxisId="left"
                        dataKey="orderedAmount"
                        fill="gray"
                        name="Commandes"
                      />

                      <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="tauxDeModif"
                        stroke="#2563eb"
                        strokeWidth={2}
                        name="Taux de modification"
                        dot={{ r: 4 }}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4 text-gray-500">
            Aucune donnée disponible pour les références sélectionnées.
          </div>
        )}
      </CardContent>
    </Card>
  )
}

/**
 * A small component to represent legend items.
 */
function LegendItem({
  color,
  label,
  isLine = false,
}: {
  color: string
  label: string
  isLine?: boolean
}) {
  return (
    <div className="flex items-center">
      {isLine ? (
        <svg width="24" height="10" className="mr-2">
          <line x1="0" y1="5" x2="24" y2="5" stroke={color} strokeWidth="2" />
        </svg>
      ) : (
        <div className="w-4 h-4 mr-2" style={{ backgroundColor: color }} />
      )}
      <span className="text-sm">{label}</span>
    </div>
  )
}
