import { useAppContext } from "@/contexts/AppContext"
import { Button } from "@/components/ui/button"
import UpdateInventoryPad from "@/components/inventory/UpdateInventoryPad"
import { ArrowRight } from "lucide-react"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { useMemo } from "react"

export const CheckinSideBar = ({
  gotToNextStep,
  updateInventory,
  className,
  currentStep,
  totalSteps,
  closeCheckinModal,
  mercurialeInfos,
}: {
  gotToNextStep: () => void
  updateInventory?: (value: string) => Promise<void>
  className?: string
  currentStep?: number
  totalSteps?: number
  closeCheckinModal: () => void
  mercurialeInfos: AllMercurialInfo[]
}) => {
  const { headerHeight } = useAppContext()
  const isLastStep = currentStep === totalSteps

  const groupedMercurialeInfos = useMemo(() => {
    return mercurialeInfos.reduce((acc, item) => {
      acc.push([item])
      return acc
    }, [] as AllMercurialInfo[][])
  }, [mercurialeInfos])

  return (
    <div
      className={`hidden md:flex flex-col flex-end justify-end sticky md:w-2/5 lg:w-1/4 h-full gap-2 ${className}`}
      style={{ top: headerHeight + 15 }}
    >
      {updateInventory && (
        <UpdateInventoryPad
          isLoading={false}
          updateInventory={updateInventory}
          mercurialeInfos={mercurialeInfos}
          groupedMercurialeInfos={groupedMercurialeInfos}
        />
      )}
      <Button
        className="w-full py-7 text-base bg-green-800 hover:bg-green-900"
        onClick={gotToNextStep}
      >
        {isLastStep ? "Passer commande" : "Continuer"}
        {isLastStep && <ArrowRight className="w-4 h-4" />}
      </Button>
      {!isLastStep && (
        <Button variant="outline" className="py-7" onClick={closeCheckinModal}>
          <p className="text-lg">Passer commande</p>
          <ArrowRight className="w-4 h-4" />
        </Button>
      )}
    </div>
  )
}
