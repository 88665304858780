import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

interface HomeCardProps {
  title: string
  subtitle: string
  children: React.ReactNode
  className?: string
  headerChildren?: React.ReactNode
  style?: React.CSSProperties
}

export const HomeCard = ({
  title,
  subtitle,
  children,
  className,
  headerChildren,
  style,
}: HomeCardProps) => {
  return (
    <Card className={className} style={style}>
      <CardHeader className="flex flex-col md:flex-row justify-between pb-0">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{title}</CardTitle>
          <CardDescription>{subtitle}</CardDescription>
        </div>
        {headerChildren}
      </CardHeader>
      {children}
    </Card>
  )
}
