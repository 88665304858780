import { MercurialReducerState } from "@/reducers/mercurialReducer"
import { useGetOrderRequestStatusLazyQuery } from "@/utils/__generated__/graphql"
import { captureException } from "@sentry/react"
import { useEffect, useState } from "react"
import { toast } from "sonner"

type Status = Record<
  string,
  {
    status: string
    colis?: number
    expectedReceptionDate?: string
    orderPickupTime?: string
  }
>

interface UseFetchOrderStatusParams {
  isOpen?: boolean
  dimMercuriales: MercurialReducerState["dimMercuriales"]
  storeId: string
}

interface UseFetchOrderStatusReturn {
  statuses: Status
  loading: boolean
}

export function useFetchOrderStatus({
  isOpen = true,
  dimMercuriales,
  storeId,
}: UseFetchOrderStatusParams): UseFetchOrderStatusReturn {
  const [fetchOrderStatus, { loading }] = useGetOrderRequestStatusLazyQuery()

  const [statuses, setStatuses] = useState<Status>({})

  useEffect(() => {
    if (!isOpen || dimMercuriales === undefined || dimMercuriales.length === 0)
      return

    const fetchAllStatuses = async () => {
      try {
        const promises = dimMercuriales.map(async (dimMercuriale) => {
          const { data } = await fetchOrderStatus({
            variables: {
              input: {
                store_id: storeId,
                dim_mercuriale_id: dimMercuriale.dimMercurialeId,
              },
            },
            fetchPolicy: "no-cache",
          })

          const orderRequest = data?.getOrderRequestStatus?.order_request_object
          return {
            dimMercurialeId: dimMercuriale.dimMercurialeId,
            status: orderRequest?.status ?? "ONGOING",
            colis: orderRequest?.colis_nb ?? 0,
            expectedReceptionDate: orderRequest?.expected_reception_date ?? "",
            orderPickupTime: orderRequest?.order_pickup_time ?? "",
          }
        })

        const results = await Promise.all(promises)
        const statusMap = results.reduce<Status>((statusMap, result) => {
          statusMap[result.dimMercurialeId] = {
            status: result.status,
            colis: result.colis,
            expectedReceptionDate: result.expectedReceptionDate,
            orderPickupTime: result.orderPickupTime,
          }
          return statusMap
        }, {})
        setStatuses(statusMap)
      } catch (error) {
        console.error("Erreur lors de la récupération des statuts:", error)
        toast.error("Erreur lors de la récupération des statuts.")
        captureException(error)
      }
    }

    void fetchAllStatuses()
  }, [isOpen, dimMercuriales, storeId, fetchOrderStatus])

  return {
    statuses,
    loading,
  }
}
