import { CalendarClock } from "lucide-react"
import { Maybe } from "../../utils/__generated__/graphql"

interface OutstandingOrdersProps {
  displayedUnit?: string
  quantity: Maybe<number> | undefined
  isPromo: boolean
}

const OutstandingOrders = ({
  displayedUnit,
  quantity,
  isPromo,
}: OutstandingOrdersProps) => {
  return (
    <div
      className={`flex flex-col items-center gap-1 ${isPromo ? "text-custom-cyan uppercase" : "text-zinc-500"}`}
    >
      <div className="flex items-center gap-x-0.5">
        {!isPromo && <CalendarClock className="w-4 h-4" />}
        <span className="text-xs font-normal whitespace-nowrap">
          {isPromo ? "PROMO" : ""}
        </span>
      </div>
      <span className="text-xs font-black flex gap-x-0.5 justify-center lg:justify-start">
        <span>+</span>
        <span>
          {new Intl.NumberFormat("fr-FR", { maximumFractionDigits: 0 }).format(
            Math.round(quantity ?? 0),
          )}
        </span>
        <span className="lowercase inline">{displayedUnit}</span>
      </span>
    </div>
  )
}

export default OutstandingOrders
