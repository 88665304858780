"use client"

import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { add } from "date-fns"
import { useSelector } from "react-redux"
import { Clock } from "lucide-react"
import {
  useReactTable,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"

import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
} from "recharts"

import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { StateType } from "@/types"
import { PerformancesPageContext } from "../../PerformancesPage"
import { useGetSalesInfoQuery } from "@/utils/__generated__/graphql"

interface SalesRecord {
  store_name?: string
  sale_name?: string
  sale_id?: string
  sale_name_ida?: string
  last_sale_time?: string | null
  rupture_loss_amount?: number | null
  quantity_sold?: number | null
  sales_amount?: number | null
  time_rupture_flag?: boolean
}

const storeColorMap: Record<string, string> = {}
const colorPalette = [
  "#3b82f6",
  "#ef4444",
  "#f97316",
  "#10b981",
  "#8b5cf6",
  "#ec4899",
  "#eab308",
  "#14b8a6",
]
function getStoreColor(storeName: string) {
  if (!storeColorMap[storeName]) {
    const idx = Object.keys(storeColorMap).length % colorPalette.length
    storeColorMap[storeName] = colorPalette[idx]
  }
  return storeColorMap[storeName]
}

function splitDateTime(dateTime?: string) {
  if (!dateTime) return { date: "—", time: "—" }
  try {
    const d = new Date(dateTime)
    const date = d.toLocaleDateString("fr-FR")
    const time = d.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })
    return { date, time }
  } catch {
    return { date: "—", time: "—" }
  }
}

export default function PotentiallyRupturesAdmin() {
  const { selectedStoreId, fromDate, toDate, companyId, franchise } =
    useOutletContext<PerformancesPageContext>()

  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()
  const startDate = fromDate ?? defaultStartDate
  const endDate = toDate ?? defaultEndDate

  const { data, loading, error } = useGetSalesInfoQuery({
    variables: {
      input: {
        company_id: companyId ?? "",
        store_id: storeId!,
        selected_store_id: selectedStoreId ?? undefined,
        franchise_name: franchise,
        start_date: formatDateToYYYYMMDD(startDate),
        end_date: formatDateToYYYYMMDD(endDate),
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !companyId || !storeId,
  })

  const salesInfo: SalesRecord[] = useMemo(() => {
    return data?.getSalesInfo?.sales_info ?? []
  }, [data?.getSalesInfo?.sales_info])

  const potentialRupturesData = useMemo(
    () =>
      salesInfo.filter(
        (rec) =>
          rec.time_rupture_flag === true &&
          rec?.rupture_loss_amount &&
          rec?.rupture_loss_amount > 0,
      ),
    [salesInfo],
  )

  const columnHelper = createColumnHelper<SalesRecord>()
  const columns = useMemo<ColumnDef<SalesRecord, string>[]>(
    () => [
      columnHelper.accessor("store_name", {
        header: "Magasin",
        cell: (info) => info.getValue() || "—",
      }),
      columnHelper.accessor("sale_name", {
        header: "Référence",
        cell: (info) => info.getValue() || `Ref #${info.row.original.sale_id}`,
      }),
      columnHelper.accessor("sale_name_ida", {
        header: "CUG",
        cell: (info) => info.getValue() || "—",
      }),
      {
        header: "Date dernière vente",
        accessorKey: "last_sale_time",
        cell: ({ row }) => {
          const { date } = splitDateTime(
            row.original.last_sale_time ?? undefined,
          )
          return date
        },
      },
      {
        header: "Heure dernière vente",
        accessorKey: "last_sale_time_time",
        cell: ({ row }) => {
          const { time } = splitDateTime(
            row.original.last_sale_time ?? undefined,
          )
          return (
            <div className="flex items-center gap-1 text-gray-700">
              <Clock className="w-4 h-4 text-gray-500" />
              {time}
            </div>
          )
        },
      },
      {
        header: "Perte (rupture)",
        accessorKey: "rupture_loss_amount",
        cell: ({ row }) => {
          const loss = row.original.rupture_loss_amount ?? 0
          return (
            <span className="text-right font-bold text-red-600">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(loss)}
            </span>
          )
        },
        enableSorting: true,
      },
    ],
    [storeCurrency, columnHelper],
  )

  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data: potentialRupturesData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const totalLoss = useMemo(() => {
    return potentialRupturesData.reduce((acc, rec) => {
      return acc + (rec.rupture_loss_amount ?? 0)
    }, 0)
  }, [potentialRupturesData])

  const grouped = useMemo(() => {
    const map: Record<string, Record<string, number>> = {}
    for (const rec of potentialRupturesData) {
      if (!rec.last_sale_time) continue
      const dayStr = new Date(rec.last_sale_time).toISOString().slice(0, 10)
      const store = rec.store_name ?? "—"
      if (!map[dayStr]) map[dayStr] = {}
      if (!map[dayStr][store]) map[dayStr][store] = 0
      map[dayStr][store] += 1
    }
    return map
  }, [potentialRupturesData])

  const allStores = useMemo(() => {
    const s = new Set<string>()
    Object.keys(grouped).forEach((day) => {
      Object.keys(grouped[day]).forEach((store) => {
        s.add(store)
      })
    })
    return Array.from(s)
  }, [grouped])
  const chartData = useMemo(() => {
    const daysSorted = Object.keys(grouped).sort()
    return daysSorted.map((day) => {
      const row: Record<string, string | number> = { day }
      let sum = 0
      allStores.forEach((store) => {
        const val = grouped[day][store] || 0
        row[store] = val
        sum += val
      })
      row.sum = sum
      return row
    })
  }, [grouped, allStores])

  const isEmpty = chartData.length === 0

  if (loading) {
    return (
      <div className="p-4 w-full max-w-[1200px] mx-auto space-y-4">
        <Card>
          <CardHeader className="flex items-center justify-between">
            <Skeleton className="h-4 w-44" />
            <Skeleton className="h-4 w-32" />
          </CardHeader>
          <CardContent>
            <Skeleton className="h-[350px] w-full" />
          </CardContent>
        </Card>
      </div>
    )
  }
  if (error) {
    return <div className="text-red-500 p-4">Erreur lors du chargement.</div>
  }

  return (
    <div className="p-4 w-full max-w-[1200px] mx-auto space-y-4">
      {/* 1) Chart card (only if data is not empty) */}
      {!isEmpty && (
        <Card>
          <CardHeader className="flex items-center justify-between">
            {/* Left corner: badge */}
            <span className="inline-block bg-red-600 text-white px-3 py-1 rounded-full text-sm font-bold">
              Perte de revenus:{" "}
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(totalLoss)}
            </span>

            {/* Right corner: title */}
            <CardTitle className="text-md">
              Nombres de ruptures par jour / magasin
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full" style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Legend />

                  {/* For each store, create a <Bar/> */}
                  {allStores.map((store) => (
                    <Bar
                      key={store}
                      dataKey={store}
                      stackId="ruptures"
                      fill={getStoreColor(store)}
                      name={store}
                    />
                  ))}

                  {/* The line for total day sum */}
                  <Line
                    type="monotone"
                    dataKey="sum"
                    stroke="#111"
                    strokeWidth={3}
                    dot={{ r: 3 }}
                    activeDot={{ r: 5 }}
                    name="Somme"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      )}

      {/* 2) Table of ruptures */}
      <Card className="w-full">
        <CardContent>
          <h2 className="font-bold text-lg mb-2 py-2">
            {isEmpty
              ? "Aucune rupture détectée pour ce magasin et cette période."
              : "Liste des potentielles ruptures"}
          </h2>

          {isEmpty ? (
            <>
              {[...Array(3)].map((_, idx) => (
                <Skeleton key={idx} className="w-full h-20 mb-2" />
              ))}
            </>
          ) : (
            <div className="overflow-x-auto max-h-[400px] border border-gray-200 rounded-lg">
              <Table className="table-auto w-full border-collapse">
                <TableHeader className="sticky top-0 bg-gray-50 z-10">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const canSort = header.column.getCanSort()
                        const isSorted = header.column.getIsSorted()
                        return (
                          <TableHead
                            key={header.id}
                            className="whitespace-nowrap cursor-pointer select-none px-2 py-3 border-b"
                            onClick={() => {
                              if (!canSort) return
                              header.column.toggleSorting()
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                            {canSort && (
                              <span className="ml-1 text-xs">
                                {isSorted === "asc"
                                  ? "▲"
                                  : isSorted === "desc"
                                    ? "▼"
                                    : ""}
                              </span>
                            )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className="px-2 py-2 border-b whitespace-nowrap"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
