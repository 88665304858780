export const StepperProgressBar = ({
  currentStep,
  steps,
}: {
  currentStep: number
  steps: { title: string; descriptions?: string[] }[]
}) => {
  const progressPercentage = Math.min(
    ((currentStep - 1) / (steps.length - 1)) * 100,
    100,
  )

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row justify-between">
        <p className="text-sm text-black">Étapes</p>
        <p className="text-sm text-gray-500">
          {currentStep} / {steps.length}
        </p>
      </div>
      <div className="w-full h-2 bg-gray-200 rounded-full relative">
        <div
          className="absolute top-0 left-0 h-2 bg-black rounded-full transition-all duration-300"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  )
}
